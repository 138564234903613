import { useEffect, useMemo, useState } from 'react';

const baseSizes = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

type baseSizesTypes = typeof baseSizes;
type maxBaseSizesTypes = { [key in keyof (typeof baseSizes) as `max-${key}`]: string };

export type useMediaQueryScreenType = keyof baseSizesTypes | keyof maxBaseSizesTypes

export const useMediaQuery = (screen: useMediaQueryScreenType) => {
  const mediaQuery = useMemo(
    () => {
      const baseScreenSize = screen.replace(/(.*)-([\w]{2,3})/mg, '$2') as keyof baseSizesTypes

      return screen.includes('max')
        ? window.matchMedia(`not all and (min-width: ${baseSizes[baseScreenSize]})`)
        : window.matchMedia(`(min-width: ${baseSizes[baseScreenSize]})`)
    },
    [screen]
  );

  const [matches, setMatches] = useState(mediaQuery.matches);

  useEffect(() => {
    mediaQuery.addEventListener('change', mediaQueryListener)

    return () => mediaQuery.addEventListener('change', mediaQueryListener);
  }, [mediaQuery]);

  return matches;

  function mediaQueryListener({ matches: newMatches }: MediaQueryListEvent) {
    setMatches(newMatches)
  }
};
