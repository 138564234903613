import { Button, ExitIcon, ExitIconWithBorder } from '@kindlyhuman/component-library';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface TextSearchModalProps {
  onExit: () => void;
  search: (text: string) => void;
}

export const TextSearchModal = ({ onExit, search }: TextSearchModalProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  return (
    <>
      <div
        className="fixed inset-0 bg-modalBackdropColor z-30 h-screen w-screen"
        onClick={onExit}
      />
      <div
        className="
        flex flex-col justify-between fixed bottom-0 left-0 right-0 rounded-t-r-15 overflow-y-auto  bg-[#E6E6E6] h-5/6 z-40
        df:md:right-auto df:md:top-0 df:md:h-auto df:md:max-w-[540px] df:md:rounded-none df:md:bg-white df:md:min-w-113
        "
      >
        <Header className="mb-2 df:md:mb-0" onExit={onExit} />
        <div className="w-full h-full px-4 pt-8">
          <textarea
            ref={ref}
            className="block w-full px-4 h-4/6 pb-5 p-4 bg-gray-50
          border border-gray-300 rounded-lg
          font-manrope text-sm text-gray-900
          md:!text-base md:!bg-white
          focus:ring-4 focus:outline:none focus:ring-[#25008a]/10 focus:border-opacity-10 focus:border-[#25008a]/10"
          ></textarea>
        </div>
        <Footer onCancel={() => onExit()} search={() => search(ref.current!.value)} />
      </div>
    </>
  );
};

interface HeaderProps {
  onExit: () => void;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ onExit, className }) => (
  <div
    className={twMerge(
      'bg-white p-4 text-gray-800 text-xl font-bold relative min-h-[72px] flex items-center justify-center',
      'df:md:min-h-0 df:md:p-6 df:md:border-b df:md:border-[#DFE1E5] df:md:justify-start',
      className
    )}
  >
    <button className="absolute left-4 df:md:hidden" onClick={() => onExit()}>
      <ExitIconWithBorder />
    </button>
    <div>Search By Text</div>
    <button className="hidden absolute right-4 df:md:block" onClick={() => onExit()}>
      <ExitIcon color="#969BA5" />
    </button>
  </div>
);

const Footer: React.FC<{
  search: () => void;
  onCancel: () => void;
}> = ({ search, onCancel }) => (
  <div
    className="
        py-4 px-6 gap-x-6 bg-white border-t border-neutral-200 flex justify-between items-center
        df:md:shadow-[8px_0px_16px_0px_#191e270f] df:md:py-6
      "
  >
    <Button onClick={onCancel} variant="secondary" className="df:md:hidden w-full">
      Cancel
    </Button>
    <Button onClick={search} type="submit" variant="primary" className="df:md:block w-full">
      Search
    </Button>
  </div>
);
