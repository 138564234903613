import { useRef, useState } from 'react';

import { Button, LeftArrowIcon, SpinnerIcon } from '@kindlyhuman/component-library';

import { useUser } from '../../../../hooks/useUser';
import { usePaymentMethod } from '../../../../hooks/useStripe';

import { FormBlock, formBlockClassName } from '../../../common/form_block';
import { UserPlan } from '../../../common/user_plan';
import { AddPaymentForm } from '../../../common/payment/add_payment_form';
import { PoweredByStripe } from '../../../common/payment/powered_by_stripe';
import { PaymentMethod } from '../../../common/payment/payment_method';
import Toast from '../../../common/PopUpMessage';

import { TabHeader } from '../tab_header';

export const PaymentMethodTab = () => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const [showUpdateCardForm, setShowUpdateCardForm] = useState<boolean>(false);

  const { data: user, isLoading: isUserLoading } = useUser();
  const {
    data: { payment_method } = {},
    isLoading: isPaymentMethodLoading,
  } = usePaymentMethod(user?.id);

  if (isUserLoading || isPaymentMethodLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    )
  }

  return (
    <>
      <div ref={toastNotificationContainerRef} />
      {
        showUpdateCardForm && (
          <button
            onClick={() => setShowUpdateCardForm(false)}
            className="mb-6 flex gap-2 text-base font-medium"
          >
            <LeftArrowIcon />
            Back
          </button>
        )
      }
      <TabHeader
        header={
          showUpdateCardForm
            ? 'Update card details'
            : 'Payment'
        }
      />
      {!!user && (
        <div className="space-y-4 mt-6">
          {
            !showUpdateCardForm && (
              <FormBlock>
                <UserPlan user={user} />
              </FormBlock>
            )
          }
          {
            !payment_method?.card || showUpdateCardForm
              ? (
                <AddPaymentForm
                  className={formBlockClassName}
                  bottomActionButton={({ isSubmitting }) => (
                    <div className="flex items-center justify-between mt-4">
                      <PoweredByStripe />
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        Update details
                      </Button>
                    </div>
                  )}
                  onSuccessAddPayment={() => {
                    setShowUpdateCardForm(false);
                    Toast.success(
                      'You have successfully updated your payment details',
                      'Payment details updated!',
                      toastNotificationContainerRef?.current
                    );
                  }}
                  onErrorAddPayment={() => {
                    Toast.error(
                      'Your payment method has failed to process.  Confirm your payment info is correct and try again.',
                      'Payment Failed',
                      toastNotificationContainerRef?.current
                    );
                  }}
                />
              )
              : (
                <FormBlock
                  header="Payment"
                  subHeader="Add your payment details below in order to complete the purchase of your plan."
                >
                  <PaymentMethod
                    className="p-4 rounded-lg border border-neutral-200"
                    onUpdateButtonClick={() => setShowUpdateCardForm(true)}
                    onSuccessRemovePayment={() => {
                      Toast.success(
                        'You have successfully removed your payment details',
                        'Payment details updated!',
                        toastNotificationContainerRef?.current
                      );
                    }}
                  />
                </FormBlock>
              )
          }
        </div>
      )}
    </>
  );
};
