import { useFavoriteExperiences } from '../../hooks/useExperiences';

import { FavoriteExperiencesMobile } from './mobile';

const FavoriteExperiencesPage = () => {
  const { data: favoriteExperiences, isLoading } = useFavoriteExperiences();

  return (
    <FavoriteExperiencesMobile isLoading={isLoading} favoriteExperiences={favoriteExperiences} />
  );
};

export default FavoriteExperiencesPage;
