import '@kindlyhuman/component-library/dist/style.css';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { User, useUser } from '../../hooks/useUser';
import { LoginMobile } from './mobile';
import Toast from '../../components/common/PopUpMessage';
import { AxiosResponse } from 'axios';

const Login = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const [showResetSuccess, setShowResetSuccess] = useState<boolean>(false);
  const [showResetError, setShowResetError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const trustRef = useRef<HTMLInputElement>(null);
  const forgotPasswordEmailRef = useRef<HTMLInputElement>(null);
  const { data: user, authenticate, passwordReset, setAuthToken } = useUser();
  const history = useHistory();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    setShowLoginError(false);
    authenticate
      .mutateAsync({
        email_address: emailRef.current!.value,
        password: passwordRef.current!.value,
        trusted: Boolean(trustRef.current!.checked),
      })
      .then((res: AxiosResponse<User>) => {
        // Has a `listener_role` but no `caller_role`
        if (res.data.listener_role && !res.data.caller_role)
          return Toast.warning(
            <>
              You are a peer trying to login as a member. You can login as a peer&nbsp;
              <a
                href={process.env.REACT_APP_LISTENER_LINK!}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                here
              </a>
              .
            </>
          );
        res.data.authorization_token && setAuthToken(res.data.authorization_token);
      })
      .catch(() => Toast.error('Email and/or password is not correct.'))
      .finally(() => setDisabled(false));
  };

  const handlePasswordReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    setShowResetError(false);
    setShowResetSuccess(false);
    passwordReset
      .mutateAsync({
        email_address: forgotPasswordEmailRef.current!.value,
      })
      .then(() => setShowResetSuccess(true))
      .catch(() => setShowResetError(true))
      .finally(() => setDisabled(false));
  };

  useEffect(() => {
    if (user) history.push(ROUTE_PATH.HOME);
  }, [user, history]);

  return (
    <LoginMobile
      trustRef={trustRef}
      handleLogin={handleLogin}
      handlePasswordReset={handlePasswordReset}
      emailRef={emailRef}
      passwordRef={passwordRef}
      forgotPasswordEmailRef={forgotPasswordEmailRef}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      showLoginError={showLoginError}
      setShowLoginError={setShowLoginError}
      showResetError={showResetError}
      setShowResetError={setShowResetError}
      showResetSuccess={showResetSuccess}
      setShowResetSuccess={setShowResetSuccess}
      disabled={disabled}
    />
  );
};

export default Login;
