import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { FieldValues, FormState } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { FormItemName, SpinnerIcon } from '@kindlyhuman/component-library';

import { useUser } from '../../../hooks/useUser';
import { useStripeKeys, cardBrandType } from '../../../hooks/useStripe';
import { useMediaQueryWithDesktopFeature } from '../../../hooks/useMediaQueryWithDesktopFeature';

import { CardIcon } from './card_icon';

export interface AddPaymentFormProps extends HTMLAttributes<HTMLFormElement> {
  topActionButton?: (formState: Partial<FormState<FieldValues>>) => ReactNode;
  bottomActionButton?: (formState: Partial<FormState<FieldValues>>) => ReactNode;
  onSuccessAddPayment?: () => void
  onErrorAddPayment?: () => void
}

const AddPaymentFormWithStripe: React.FC<AddPaymentFormProps> = ({ className, ...props }) => {
  const { data: user, isLoading: isUserLoading } = useUser();

  const {
    data: { publishable_key } = {},
    isLoading: isStripeKeysLoading
  } = useStripeKeys(user?.caller_role_id);

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(
    () => {
      if (publishable_key) {
        setStripePromise(loadStripe(publishable_key));
      }
    },
    [publishable_key]
  )

  if (isUserLoading || !stripePromise || isStripeKeysLoading) {
    return (
      <div className={twMerge('w-full h-full flex items-center justify-center', className)}>
        <SpinnerIcon />
      </div>
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <AddPaymentElements className={className} {...props} />
    </Elements>
  );
}

const AddPaymentElements: React.FC<AddPaymentFormProps> = ({
  className,
  topActionButton,
  bottomActionButton,
  onSuccessAddPayment,
  onErrorAddPayment,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: user, updateUser } = useUser();

  const stripe = useStripe();
  const elements = useElements();

  const { data: { intent_client_secret } = {} } = useStripeKeys(user?.caller_role_id);

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const cardIconsList = useMemo<Record<'brandName', cardBrandType>[]>(() => [
    {
      brandName: 'visa',
    },
    {
      brandName: 'mastercard',
    },
    {
      brandName: 'amex',
    },
  ], []);
  const ELEMENT_OPTIONS = useMemo(() => ({
    classes: {
      base: 'p-4 bg-gray-50 border border-gray-300 rounded-lg df:md:max-w-none df:md:m-0 df:md:!p-3 df:md:!bg-white',
      focus: 'ring-4 outline:none ring-[#25008a]/10 border-opacity-10 border-[#25008a]/10',
      invalid: '!border-red',
    },
    style: {
      base: {
        fontFamily: 'Manrope, sans-serif',
        fontSize: dfMdMedia ? '16px' : '18px',
        color: 'rgb(17, 24, 39)',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }), [dfMdMedia]);
  /**
   * We have the following problem - a common form to change something + different buttons how do we submit this form
   * There are two ways to solve this problem:
   *  1. Pass the function to the form. The form calls this function with the form state parameters. The function returns JSX
   *  2. We will get the state of the form via ref. We send the form through the methods we added in ref
   * I chose the first option because it is more intuitive and consistent than the second
   */
  const { topActionButtonElement, bottomActionButtonElement } = useMemo(
    () => ({
      topActionButtonElement: topActionButton
        ? topActionButton({ isSubmitting })
        : null,
      bottomActionButtonElement: bottomActionButton
        ? bottomActionButton({ isSubmitting })
        : null,
    }),
    [topActionButton, bottomActionButton, isSubmitting]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    if (elements && stripe && intent_client_secret) {
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (cardNumberElement) {
        const cardSetupResponse = await stripe.confirmCardSetup(intent_client_secret, {
          payment_method: {
            card: cardNumberElement,
          },
        });

        if (typeof cardSetupResponse?.setupIntent?.payment_method === 'string') {
          // update the user record with the stripe payment method ID
          await updateUser.mutateAsync({
            caller_role: {
              ...user?.caller_role!,
              stripe_payment_method_id: cardSetupResponse.setupIntent.payment_method,
            },
          });

          if (onSuccessAddPayment) {
            onSuccessAddPayment()
          }
        }
        else if (cardSetupResponse?.error && onErrorAddPayment) {
          onErrorAddPayment();
        }
      }
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {topActionButtonElement}
      <div className={className}>
        <div className="flex items-start gap-2">
          {/* TODO these logos seem kinda old, Rob's got a ticket out to replace em */}
          {
            cardIconsList.map(({ brandName }) => (
              <CardIcon
                className="w-[120px] h-[72px] df:md:w-[53px] df:md:h-[32px]"
                key={brandName}
                brandName={brandName}
              />
            ))
          }
        </div>
        <div className="mt-10 df:md:mt-6">
          <FormItemName
            name="CARD NUMBER"
            isRequired
            className="text-sm font-medium mb-2 df:md:mb-[6px]"
          />
          <CardNumberElement
            id="cardNumber"
            options={ELEMENT_OPTIONS}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-10 df:md:mt-4">
          <div>
            <FormItemName
              name="CARD EXPIRATION"
              isRequired
              className="text-sm font-medium mb-2 df:md:mb-[6px]"
            />
            <CardExpiryElement
              id="expiry"
              options={ELEMENT_OPTIONS}
            />
          </div>
          <div>
            <FormItemName
              name="CVC"
              isRequired
              className="text-sm font-medium mb-2 df:md:mb-[6px]"
            />
            <CardCvcElement
              id="cvc"
              options={ELEMENT_OPTIONS}
            />
          </div>
        </div>
      </div>
      {bottomActionButtonElement}
    </form>
  );
};

export { AddPaymentFormWithStripe as AddPaymentForm }
