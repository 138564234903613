import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTE_PATH } from '../../routes/route-paths';

// should hooks get an index to simplify?
import { useUser } from '../../hooks/useUser';
import {
  usePeerDetail,
  useAddFavoritePeer,
  useRemoveFavoritePeer,
  peersQueryKeys,
} from '../../hooks/usePeers';
import { useAppSettings } from '../../hooks/useAppSettings';
import { useResources } from '../../hooks/useResources';

import { PeerDetailMobileView } from './mobile';

// Listeners Details
const PeerDetails: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const listenerId: number = Number(searchParams.get('listenerId'));
  const originalRequestId: number = Number(searchParams.get('originalRequestId'));

  const [isAddPayment, setIsAddPayment] = useState<boolean>(false);
  const [isAddPaymentCard, setIsAddPaymentCard] = useState<boolean>(false);
  const [audioPlayListenId, setAudioPlayListenId] = useState<any>();

  const { data: user, isLoading: userLoading } = useUser();
  const { data: peerDetails, isLoading: peerLoading } = usePeerDetail(listenerId);
  const { data: appSettings, isLoading: settingsLoading } = useAppSettings();
  const { data: resources } = useResources();
  const addFavoritePeer = useAddFavoritePeer(peersQueryKeys.peerDetail(listenerId));
  const removeFavoritePeer = useRemoveFavoritePeer(peersQueryKeys.peerDetail(listenerId));

  const dataLoading = userLoading || peerLoading || settingsLoading;

  const canTakeCalls = Boolean(peerDetails?.can_take_calls && peerDetails?.is_active);
  const callerRoleId = user?.caller_role_id;
  const needsPaymentMethod: boolean = Boolean(user?.caller_role.stripe_customer_id);
  const unlimitedUser = !!user?.onUnlimitedPlan;
  const freeAvailableMinutes = user?.caller_role.payment_data.available_minutes;

  // audio playback
  const handleAudioPause = (id: any) => {
    setAudioPlayListenId(id);
    const sounds = document.getElementsByTagName('audio');
    for (let i = 0; i < sounds.length; i++) {
      sounds[i].pause();
    }
  };

  // call scheduling
  const handleScheduleCall = (data: any) => {
    if (needsPaymentMethod && !unlimitedUser && !freeAvailableMinutes) {
      setIsAddPaymentCard(true);
      return true;
    }

    const selectedTime = JSON.stringify({
      date: data.available === 'now' ? 'now' : data.date,
      selectedTime: data.callSheduleTime,
    });
    localStorage.setItem('selectedTime', selectedTime);
    // eslint-disable-next-line
    if (originalRequestId) {
      history.push(
        // eslint-disable-next-line
        `${ROUTE_PATH.CALL_SCHEDULE}\?listenerId=${listenerId}&originalRequestId=${originalRequestId}`
      );
    } else {
      history.push(
        // eslint-disable-next-line
        `${ROUTE_PATH.CALL_SCHEDULE}\?listenerId=${listenerId}`
      );
    }
  };

  const handleAddPayment = () => {
    setIsAddPayment(!isAddPayment);
    setIsAddPaymentCard(true);
  };

  const handleFollowing = async () => {
    const favoriteId = peerDetails?.favorite_id ?? undefined;

    if (favoriteId) {
      await removeFavoritePeer.mutateAsync(listenerId);
    } else {
      await addFavoritePeer.mutateAsync(listenerId);
    }
  };

  return (
    <PeerDetailMobileView
      appSettings={appSettings}
      audioPlayListenId={audioPlayListenId}
      isAddPayment={isAddPayment}
      isAddPaymentCard={isAddPaymentCard}
      setIsAddPaymentCard={setIsAddPaymentCard}
      dataLoading={dataLoading}
      canTakeCalls={canTakeCalls}
      callerRoleId={callerRoleId}
      listenerId={listenerId}
      unLimitedUser={unlimitedUser}
      freeAvailableMinutes={freeAvailableMinutes!}
      needsPaymentMethod={needsPaymentMethod}
      handleAudioPause={handleAudioPause}
      handleScheduleCall={handleScheduleCall}
      handleAddPayment={handleAddPayment}
      peerDetails={peerDetails}
      user={user}
      handleFollowing={handleFollowing}
      isFollowing={Boolean(peerDetails?.favorite_id)}
      resources={resources}
    />
  );
};

export default PeerDetails;
