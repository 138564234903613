import React from 'react';
import { RadioList } from './RadioList';

type familyProps = {
  familyList: string[] | undefined;
};

const Family: React.FunctionComponent<familyProps> = ({ familyList }): JSX.Element => (
  <div className="space-y-6">
    <div className="space-y-2 df:md:text-center">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-3xl
        "
      >
        What is your
        {' '}
        <span className="font-bold">family status</span>
        ?
      </p>
      <p className="text-gray-900 text-base df:md:hidden">
        Enter your details below
      </p>
    </div>
    <RadioList
      name="family"
      options={familyList}
    />
  </div>
);

export default Family;
