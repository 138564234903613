import { twMerge } from 'tailwind-merge';

import {
  Button,
  Dialog,
  DialogProps,
} from '@kindlyhuman/component-library';

import { useUser } from '../../hooks/useUser';

interface LogoutModalProps extends DialogProps {
  dataTestId?: string
}

export const LogoutModal = ({ className, onClose, dataTestId, ...props }: LogoutModalProps) => {
  const { logout } = useUser();

  return (
    <Dialog
      data-testid={dataTestId}
      onClose={onClose}
      closeOnOutsideClick={true}
      className={twMerge('rounded-2xl backdrop:bg-modalBackdropColor', className)}

      {...props}
    >
      <div className="w-80 h-30 p-8 flex-col justify-center items-center gap-4 inline-flex">
        <div className="flex flex-col">
          <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope leading-loose">
            Log out
          </div>
          <div className="text-center text-stone-500 font-manrope">
            Are you sure you want to log out from Kindly Human?
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button variant="primary" onClick={logout}>
          Log out
        </Button>
      </div>
    </Dialog>
  );
};
