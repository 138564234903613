import React, { useState } from 'react';
import { Button } from '@kindlyhuman/component-library';
import FramesOfMindIcon from '../../../assets/images/frames-of-mind-icon';
import { useTagGroups } from '../../../hooks/useTagGroups';
import { useUser } from '../../../hooks/useUser';
import { FilterMenu, TagFilters } from '../../../components/filter/FomSelectionFilter';

const InterestsStep: React.FC<{
  onComplete: () => void;
}> = ({ onComplete }) => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  return (
    <>
      {step === 1 ? (
        <InterestsInfo onNext={handleNext} />
      ) : (
        <SelectInterests onComplete={onComplete} onBack={handlePrevious} />
      )}
    </>
  );
};

const InterestsInfo: React.FC<{
  onNext: () => void;
}> = ({ onNext }) => (
  <>
    <div className="hidden fixed top-0 left-0 w-screen h-screen bg-white z-1 df:md:block" />
    <div className="bg-white space-y-8 px-4 py-10 text-center relative z-10">
      <FramesOfMindIcon className="mx-auto" />
      <div className="space-y-2 text-center">
        <p className=" text-violet-950 text-2xl df:md:text-3xl">
          Now, we want to understand a little about your
          {' '}
          <span className="font-bold">interests in support</span>
          .
        </p>
        <p className="text-gray-800 text-base">
          Selecting areas of interest helps us focus on things that matter the most to you.
        </p>
      </div>
      <Button data-testid="continue-button" onClick={onNext} variant="primary">
        Continue
      </Button>
    </div>
  </>
);

const SelectInterests: React.FC<{
  onBack: () => void;
  onComplete: () => void;
}> = ({ onBack, onComplete }) => {
  const { updateTagPreferences } = useUser();
  const { data: fomOptions, getParentByParentId } = useTagGroups();
  const [currentlySelected, setCurrentlySelected] = useState<TagFilters>({
    tag_group_ids: [],
    tag_ids: [],
  });

  const handleGetMatched = () => {
    updateTagPreferences.mutate(currentlySelected);
    onComplete();
  };

  const toggleSelectedTagGroup = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_group_ids = tag_group_ids.includes(id)
        ? tag_group_ids
            // remove selected id
            .filter((currentId) => currentId !== id)
            // remove selected id's children
            .filter(
              (currentId) =>
                !getParentByParentId(id)
                  ?.children?.map((subject) => subject.id)
                  .includes(currentId)
            )
        : [...tag_group_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const toggleSelectedTag = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_ids = tag_ids.includes(id)
        ? tag_ids.filter((currentId) => currentId !== id)
        : [...tag_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const removeSubjects = () => {
    const challengeAreaIds = fomOptions?.map((challengeArea) => challengeArea.id);
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      return {
        tag_ids,
        tag_group_ids: tag_group_ids.filter((id) => challengeAreaIds?.includes(id)),
      };
    });
  };

  const removeTags = () => {
    setCurrentlySelected(({ tag_group_ids }) => {
      return {
        tag_ids: [],
        tag_group_ids,
      };
    });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2 df:md:text-center">
        <p
          className="
            text-gray-900 text-2xl font-manrope
            df:md:text-3xl
          "
        >
          Where can we
          {' '}
          <span className="font-bold">help you</span>
          ?
        </p>
        <p className="text-gray-900 text-base">
          Please select any areas where you are facing challenges right now
        </p>
      </div>
      <div className="
        w-screen -translate-x-1/2 relative left-1/2
        [&>div>div]:border-y
        df:md:w-full df:md:left-0 df:md:translate-x-0
        df:md:[&>div>div]:border-x df:md:[&>div>div]:rounded-lg
      ">
        <FilterMenu
          selected={currentlySelected}
          fomOptions={fomOptions}
          toggleSelectedTagGroup={toggleSelectedTagGroup}
          toggleSelectedTag={toggleSelectedTag}
          removeSubjects={removeSubjects}
          removeTags={removeTags}
          currentlySelected={currentlySelected}
        />
      </div>
      <div
        className="
          py-4 px-4 gap-x-4 flex justify-evenly fixed bottom-0 left-0 w-full bg-white
          df:md:relative df:md:bg-transparent df:md:justify-between df:md:pt-8 df:md:px-0
        "
      >
        <Button
          data-testid="back-button"
          className="w-full df:md:w-auto"
          onClick={onBack}
          variant="secondary"
        >
          Back
        </Button>
        <Button
          data-testid="get-matched-button"
          className="w-full df:md:w-auto"
          disabled={currentlySelected.tag_group_ids.length === 0}
          onClick={handleGetMatched}
          variant="primary"
        >
          Get matched
        </Button>
      </div>
    </div>
  );
};

export default InterestsStep;
