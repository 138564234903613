import { Dispatch, useCallback, useState, useRef, useEffect } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';

import {
  Avatar,
  Experience,
  Content,
  PeerCard,
  CallCard,
  TrashIcon,
  PlusWithCircle,
  Actionable,
  CommentsIcon,
  SupportBubbleIcon,
  AREA_LABEL_VARIANT,
  AreaChip,
  AvatarSkeleton,
  CircledArrowRightIcon,
} from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../routes/route-paths';

import { TelemedDetailPng, CounselingDetailPng, PaymentTile, ReflectionTile } from '../../assets';

import {
  useListenerAudioPlaybackMutation,
  useListenerAudioPlayMutation,
} from '../../hooks/useListenerAudio';
import { CallRequest, useActiveCalls } from '../../hooks/useCalls';
import { TagGroup, useTagGroups } from '../../hooks/useTagGroups';
import { Resource, ResourcesResponse } from '../../hooks/useResources';
import { PeerResponse } from '../../hooks/usePeers';
import { User, useUser } from '../../hooks/useUser';
import { RecommendedExperienceResponse } from '../../hooks/useExperiences';
import { ExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { usePostCalls, useUpdatePostCall } from '../../hooks/usePostCall';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';
import { BlockHorizontalScroll } from '../../components/common/block_horizontal_scroll';
import { FilterBar } from '../../components/filter/FilterBar';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import ReflectionsModal from '../../components/reflections-modal/reflections-modal';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { ActiveCallCard } from '../../components/common/CallCards/ActiveCallCard';
import { CallCardWithData } from '../../components/common/CallCards/CallCardWithData';

import IntroducesVideoCard from './introduces-video-card';
import { PostCallModal } from '../../components/PostCallModal';
import { PostCallFormRef } from '../../components/common/PostCallForm';

interface HomeMobileViewProps {
  showFilterMenu: boolean;
  setShowFilterMenu: Dispatch<React.SetStateAction<boolean>>;
  getTagGroupByName: (name: string) => TagGroup | undefined;
  handleFilterChange: ({ tag_group_ids, tag_ids }: TagFilters) => void;
  user?: User;
  favoritedPeers: UseQueryResult<PeerResponse, unknown>;
  audioStopAll: () => void;
  playerOpen?: number;
  setPlayerOpen: Dispatch<React.SetStateAction<number | undefined>>;
  resources: UseQueryResult<ResourcesResponse, unknown>;
  fomHierarchy?: TagGroup[];
  newPeers: UseQueryResult<PeerResponse, unknown>;
  recommendedExperiences: UseQueryResult<RecommendedExperienceResponse, unknown>;
  callRequests: CallRequest[] | undefined;
  providerPlan: ExternalProviderPlan | null;
  currentReflection: any;
  showReflectionTile: boolean;
  showReflectionModal: boolean;
  handleReflectionModalDismiss: () => void;
  handleReflection: () => void;
  handleReflectionDismiss: () => void;
}

const NORMAL_CALL_LENGTH = 20;

export const HomeMobileView: React.FC<HomeMobileViewProps> = ({
  showFilterMenu,
  setShowFilterMenu,
  getTagGroupByName,
  handleFilterChange,
  user,
  favoritedPeers,
  newPeers,
  audioStopAll,
  playerOpen,
  setPlayerOpen,
  resources,
  providerPlan,
  fomHierarchy,
  recommendedExperiences,
  callRequests,
  currentReflection,
  showReflectionTile,
  showReflectionModal,
  handleReflectionModalDismiss,
  handleReflection,
  handleReflectionDismiss,
}) => {
  const history = useHistory();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const [isVideo, setIsVideo] = useState(true);
  const [dismissPaymentTile, setDismissPaymentTile] = useState(false);
  const [showPaymentTile, setShowPaymentTile] = useState(false);

  const client = user?.caller_role.active_subscription?.package?.client;
  const isUnlimited = user?.onUnlimitedPlan;
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = isUnlimited
    ? undefined
    : user?.caller_role.payment_data.available_minutes;
  const getShowPaymentTile = useCallback(async (user: User) => {
    if (user.onUnlimitedPlan) {
      return false;
    }

    const hasStripePaymentMethod = !!user.caller_role?.stripe_payment_method_id;
    const cappedAccessProduct =
      user.caller_role?.active_subscription?.package?.package_products?.find(
        (pp: any) => pp.product?.key === 'CAPPED_ACCESS' && pp.status === 'enabled'
      );
    // if user is on capped access and we're not hiding the payment tile (config setting), then show
    // tile if the user has insufficient minutes credit and no payment method
    if (cappedAccessProduct) {
      const freeAvailableMinutes = user.caller_role.payment_data.available_minutes;
      const hasSufficientCredit = freeAvailableMinutes > NORMAL_CALL_LENGTH;
      return (
        !cappedAccessProduct?.configuration?.hide_payment_tiles &&
        !hasSufficientCredit &&
        !hasStripePaymentMethod
      );
    }
    // This is either pay-as-you-go or member pay, show the tile if they don't have a card on file
    return !hasStripePaymentMethod;
  }, []);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    getShowPaymentTile(user).then((isShowPaymentTile) => {
      setShowPaymentTile(isShowPaymentTile);
    });
    const videoDisplay = localStorage.getItem('isVideoDisplay');
    setIsVideo(videoDisplay === 'true' || videoDisplay === null ? true : false);
  }, [user, getShowPaymentTile]);

  const handleAddPayment = () => {
    setDismissPaymentTile(true);
    history.push(ROUTE_PATH.PAYMENT);
  };

  return (
    <div className={`bg-gray-100 w-screen min-h-screen ${showFilterMenu ? 'overflow-hidden' : ''}`}>
      <MobileMenuHeader
        onConnect={() => history.push(ROUTE_PATH.AVAILABLE_LISTENERS)}
        prompt={true}
        availableMinutes={availableMinutes}
      />
      <DesktopMenuHeader />
      <div className="w-full mx-auto">
        <FilterBar
          setShowFilterMenu={setShowFilterMenu}
          user={user}
          getTagGroupByName={getTagGroupByName}
          handleFilterChange={handleFilterChange}
          className="df:md:mb-0 df:md:border-t-0 df:md:mt-0"
        />

        <div
          className="
            grid grid-cols-1 mt-3 space-y-3
            df:md:grid-cols-8 df:md:space-y-0 df:md:mx-auto df:md:mt-6 df:md:pb-14 df:md:gap-6 df:md:max-w-7xl df:md:px-6
          "
        >
          {/* We can't use space-y-3 for this div - it breaks styles for dialog */}
          <div className="flex flex-col gap-3 col-span-3 order-1 df:md:order-2 df:md:gap-6">
            <CallConnectionCards callRequests={callRequests} />
            {client && client?.video_configuration && isVideo && (
              <div className="mb-4 df:md:mb-0 df:md:bg-white df:md:border df:md:rounded-lg df:md:p-4">
                <IntroducesVideoCard
                  videoSrc={client?.video_configuration?.video_url}
                  imageSrc={client?.video_configuration?.image_url}
                  handleCloseVideo={() => setIsVideo(false)}
                  className="df:md:max-w-full df:md:max-h-full"
                />
              </div>
            )}
            {showReflectionTile && currentReflection?.data && (
              <div className="mx-auto df:md:bg-white df:md:border df:md:rounded-lg df:md:p-4 df:md:w-full">
                <Actionable
                  buttonContents="Reflect"
                  darken
                  description="Take a moment to reflect and earn rewards"
                  img={ReflectionTile}
                  onClick={handleReflection}
                  onExit={handleReflectionDismiss}
                  title="How are you today?"
                  width={dfMdMedia ? '100%' : undefined}
                />
              </div>
            )}
            {showPaymentTile && !dismissPaymentTile && (
              <div className="mx-auto df:md:bg-white df:md:border df:md:rounded-lg df:md:p-4 df:md:w-full">
                <Actionable
                  data-testid="payment-card"
                  buttonContents="Add"
                  darken
                  description="Before we connect you with Peer Listeners"
                  img={PaymentTile}
                  onClick={handleAddPayment}
                  onExit={() => setDismissPaymentTile(true)}
                  title="Add your payment method & get started"
                  width={dfMdMedia ? '100%' : undefined}
                />
              </div>
            )}
            <MyPeersSection favoritedPeers={favoritedPeers} />
            <ExploreAreasSection className="hidden df:md:block" />
          </div>
          <div className="col-span-5 order-2 space-y-3 df:md:order-1 df:md:space-y-6">
            <ExperienceSection
              setPlayerOpen={setPlayerOpen}
              playerOpen={playerOpen}
              audioStopAll={audioStopAll}
              experiences={recommendedExperiences}
            />
            <ResourcesSection resources={resources} providerPlan={providerPlan} />
            <NewPeersSection newPeers={newPeers} />
            <ExploreAreasSection className="df:md:hidden" />
          </div>
        </div>

        {playerOpen && <div className="h-24"></div>}
      </div>

      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {showReflectionModal && currentReflection?.data && (
        <ReflectionsModal
          open={showReflectionModal}
          handleReflectionModalDismiss={handleReflectionModalDismiss}
        />
      )}
    </div>
  );
};

const MyPeersSection: React.FC<{ favoritedPeers: UseQueryResult<PeerResponse, unknown> }> = ({
  favoritedPeers,
}) => {
  const history = useHistory();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <BlockHorizontalScroll
      dataTestId="my-peer-section"
      className="df:md:border df:md:rounded-lg"
      contentClassName={`
        flex overflow-x-scroll gap-x-3
        df:md:flex-wrap df:md:gap-x-5 df:md:-mb-4
      `}
      isLoading={favoritedPeers.isFetching}
      title="My Peers"
      onViewAll={() => {
        history.push({ pathname: ROUTE_PATH.MY_PEERS });
      }}
      subtitle={
        favoritedPeers.data?.count ? `${favoritedPeers.data?.count} Peers are here for you` : ''
      }
    >
      {favoritedPeers.isLoading &&
        [...Array(10).keys()].map((int) => (
          <div
            key={int}
            className="max-w-full flex flex-col gap-y-3 justify-baseline items-start df:md:mb-4"
          >
            <AvatarSkeleton
              variant={dfMdMedia ? 'normal' : 'large'}
              isPeerListener
            />
            <Skeleton variant="text" className="w-20 df:md:w-15 h-5" />
          </div>
        ))}
      {favoritedPeers?.data?.data?.map((peer) => (
        <div
          key={peer.listener_role_id}
          className="min-w-max flex flex-col gap-y-3 justify-baseline items-center cursor-pointer df:md:mb-4"
          onClick={() => {
            history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
          }}
        >
          <Avatar
            variant={dfMdMedia ? 'normal' : 'large'}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            image={peer.profile_photo_url_square}
          />
          <p
            data-testid={`peer-${peer.display_name}`}
            className="
              text-center text-gray-800 text-sm font-medium leading-tight truncate max-w-[100px]
              df:md:max-w-[64px]
            "
          >
            {peer.display_name}
          </p>
        </div>
      ))}
      {
        favoritedPeers.data?.count === 0 && !favoritedPeers.isLoading && (
          <div className="font-manrope text-center w-full pb-4 space-y-2">
            <p className="text-lg">
              Follow Peers to save them here!
            </p>
            <Link
              className="flex text-violet-950 text-base font-semibold items-center justify-center gap-1"
              to={ROUTE_PATH.RECOMMENDED_PEERS}
            >
              Say hello to a new Peer
              <CircledArrowRightIcon />
            </Link>
          </div>
        )
      }
      {!favoritedPeers.isLoading && favoritedPeers.data?.count !== 0 && (
        <Link className="df:md:mb-4" to={ROUTE_PATH.RECOMMENDED_PEERS}>
          <PlusWithCircle width={dfMdMedia ? 64 : 100} height={dfMdMedia ? 64 : 100} />
        </Link>
      )}
    </BlockHorizontalScroll>
  );
};

interface ResourcesSectionProps {
  resources: UseQueryResult<ResourcesResponse, unknown>;
  providerPlan: ExternalProviderPlan | null;
}

const ResourcesSection: React.FC<ResourcesSectionProps> = ({
  resources: resourcesQuery,
  providerPlan,
}) => {
  const history = useHistory();
  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const { data: resources, isLoading, isFetching } = resourcesQuery;

  const availableResources =
    resources && resources.data.filter((resource: Resource) => resource.image_url);

  if (!availableResources || (availableResources.length === 0 && !providerPlan)) {
    return null;
  }

  let resourceCount = resources.count;

  if (providerPlan) {
    resourceCount += providerPlan.provider_type === 'both' ? 2 : 1;
  }

  return (
    <>
      <BlockHorizontalScroll
        className="df:md:border df:md:rounded-lg"
        isLoading={isFetching}
        title="More help options"
        subtitle={
          resourceCount ? `${resourceCount} resource${resourceCount > 1 ? 's' : ''} to explore` : ''
        }
        onViewAll={() => {
          history.push({ pathname: ROUTE_PATH.RESOURCES });
        }}
        showNavigationButton={dfMdMedia}
        contentClassName="df:md:gap-4"
      >
        {isLoading &&
          [1, 2].map((index) => (
            <Skeleton variant="rounded" key={index} height={'225px'} width={'1550px'} />
          ))}
        {(providerPlan?.provider_type === 'telemed' || providerPlan?.provider_type === 'both') && (
          <Link to={ROUTE_PATH.TELEMED}>
            <Content
              caption="Find out more about +Telemed today."
              displayName="+Telemed"
              imageUrl={TelemedDetailPng}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {(providerPlan?.provider_type === 'counseling' ||
          providerPlan?.provider_type === 'both') && (
          <Link to={ROUTE_PATH.COUNSELING}>
            <Content
              caption="Find out more about +Counseling now"
              displayName="+Counseling"
              imageUrl={CounselingDetailPng}
              variant={dfMdMedia ? 'desktopMedium' : 'medium'}
            />
          </Link>
        )}
        {availableResources &&
          availableResources.map((resource) => (
            <Link
              key={resource.id}
              to={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
            >
              <Content
                caption={resource.description.substring(0, 50) + '...'}
                displayName={resource.name}
                imageUrl={resource.image_url}
                variant={dfMdMedia ? 'desktopMedium' : 'medium'}
                // @ts-ignore
                areas={resource.challenge_areas?.map((id) => getParentByParentId(id)?.name)}
              />
            </Link>
          ))}
      </BlockHorizontalScroll>
    </>
  );
};

const NewPeersSection: React.FC<{ newPeers: UseQueryResult<PeerResponse, unknown> }> = ({
  newPeers,
}) => {
  const history = useHistory();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <>
      <BlockHorizontalScroll
        className="df:md:border df:md:rounded-lg"
        isLoading={newPeers.isFetching}
        title="Recommended Peers"
        subtitle={newPeers.data ? `${newPeers.data.count} Peers have relatable experiences` : ''}
        onViewAll={() => {
          history.push(ROUTE_PATH.RECOMMENDED_PEERS);
        }}
        showNavigationButton={dfMdMedia}
        contentClassName="df:md:gap-4"
      >
        {newPeers.isLoading &&
          [...Array(10).keys()].map((int) => (
            <div
              key={int}
              className="min-w-max flex flex-col gap-y-3 justify-baseline items-start cursor-pointer"
            >
              <AvatarSkeleton
                variant={dfMdMedia ? 'huge' : 'x-large'}
                isPeerListener
              />
              <Skeleton variant="text" className="w-24 h-5" />
              <Skeleton variant="text" className="w-40 h-5" />
              <Skeleton variant="text" className="w-24 h-5" />
            </div>
          ))}
        {newPeers.data &&
          newPeers.data.data.map((peer) => (
            <PeerCard
              key={peer.listener_role_id}
              className="cursor-pointer min-w-fit"
              name={peer.display_name}
              variant={dfMdMedia ? 'desktopSmall' : 'small'}
              available={peer.is_available}
              isPeerListener={peer.is_listener}
              img={peer.profile_photo_url_square}
              description={peer.about_me}
              // @ts-ignore
              areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
              traits={peer.tags.map((tag) => tag.name)}
              onClick={() => {
                history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
              }}
            />
          ))}
      </BlockHorizontalScroll>
    </>
  );
};

const ExperienceSection: React.FC<{
  experiences: UseQueryResult<RecommendedExperienceResponse, unknown>;
  audioStopAll: () => void;
  playerOpen?: number;
  setPlayerOpen: Dispatch<React.SetStateAction<number | undefined>>;
}> = ({ experiences: recommendedExperiences, audioStopAll, playerOpen, setPlayerOpen }) => {
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const { getParentByParentId } = useTagGroups();

  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const [playing, setPlaying] = useState(false);

  const history = useHistory();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const playerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BlockHorizontalScroll
        className="df:md:border df:md:rounded-lg"
        isLoading={recommendedExperiences.isFetching}
        title="Experiences for you"
        onViewAll={() => history.push(ROUTE_PATH.EXPERIENCES)}
        subtitle={
          recommendedExperiences.data
            ? recommendedExperiences.data.count + ' experiences based on your selected topics'
            : ''
        }
        showNavigationButton={dfMdMedia}
        contentClassName="df:md:gap-4"
      >
        {recommendedExperiences.isLoading &&
          [1, 2, 3, 4].map((int) => (
            <div key={int}>
              <Skeleton className="h-40 w-40 pt-5" variant="rounded" />
              <Skeleton variant="text" className="w-24 h-5 pt-4" />
              <Skeleton variant="text" className="w-40 h-5 pt-4" />
              <Skeleton variant="text" className="w-24 h-5 pt-4" />
            </div>
          ))}
        {recommendedExperiences.data &&
          recommendedExperiences.data.data.map((experience) => (
            <Experience
              className="shrink-0"
              variant={dfMdMedia ? 'desktopSmall' : 'small'}
              imageUrl={experience.profile_photo_square_file_url}
              displayName={experience.name}
              audioUrl={experience.audio_url}
              caption={experience.excerpt}
              goToProfile={() => {
                history.push(
                  `${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`
                );
              }}
              // @ts-ignore
              areas={experience.area_ids?.map((x) => getParentByParentId(x)?.name)}
              onFollow={() => {
                return undefined;
              }}
              playing={playerOpen === experience.id && playing}
              setPlaying={setPlaying}
              onPlay={() => {
                audioStopAll();
                setPlayerOpen(experience.id);
                playMutation.mutate(
                  {
                    audioId: experience.id,
                    listenerId: experience.listener_role_id,
                  },
                  {
                    onSuccess: (listenerAudioPlayback) => {
                      setCurrentPlaybackId(listenerAudioPlayback.id);
                    },
                  }
                );
              }}
              key={experience.id}
              openPlayer={playerOpen === experience.id}
              setOpenPlayer={setPlayerOpen}
              onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                const percent = Math.round(
                  (e.currentTarget.currentTime / experience.duration) * 100
                );
                // if the percent is divisible by 10
                if (percent % 10 === 0) {
                  currentPlaybackId &&
                    playBackMutation.mutate({
                      audioId: currentPlaybackId,
                      percentCompleted: percent,
                    });
                }
              }}
              available={experience.is_available}
              isPeerListener={experience.is_listener}
              playerContainer={playerContainerRef?.current}
            />
          ))}
      </BlockHorizontalScroll>
      <div ref={playerContainerRef} />
    </>
  );
};

const CallConnectionCards: React.FC<{ callRequests: CallRequest[] | undefined }> = ({
  callRequests,
}) => {
  const history = useHistory();

  const [isNow, setIsNow] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [originalRequestId, setOriginalRequestId] = useState<number>();
  const [listenerId, setListenerId] = useState<number>();
  const [postCallIdForPostCallModal, setPostCallIdForPostCallModal] = useState<number>();

  const { data: user } = useUser();
  const { data: incompletePostCalls } = usePostCalls(user?.caller_role_id, true);
  const postCallMutation = useUpdatePostCall();
  const { data: tagGroups } = useTagGroups();
  const { data: activeCalls } = useActiveCalls(user?.caller_role_id);

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const postCallModalForm = useRef<PostCallFormRef>(null);

  const pendingRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'matched' || request.status === 'backup_pending'
  );
  const unavailableRequests = callRequests?.filter(
    (request) =>
      request.connections[0].status === 'canceled' || request.connections[0].status === 'passed'
  );
  let backupRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'offered' && request.is_backup_request === true
  );
  const scheduledCalls = callRequests?.filter(
    (request) =>
      ['accepted', 'offered'].includes(request.connections[0].status) &&
      request.is_backup_request === false
  );

  const hasCard =
    (pendingRequests && pendingRequests.length > 0) ||
    (unavailableRequests && unavailableRequests.length > 0) ||
    (backupRequests && backupRequests.length > 0) ||
    (scheduledCalls && scheduledCalls.length > 0) ||
    (incompletePostCalls && incompletePostCalls.length > 0) ||
    (activeCalls && activeCalls.length > 0);

  useEffect(() => {
    if (postCallIdForPostCallModal && !dfMdMedia) {
      sessionStorage.setItem(
        `postCallForm-${postCallIdForPostCallModal}`,
        JSON.stringify({
          step: postCallModalForm?.current?.step,
          ...postCallModalForm?.current?.getValues(),
        })
      );
      history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${postCallIdForPostCallModal}`);
    }
  }, [postCallIdForPostCallModal, dfMdMedia, history]);

  return hasCard ? (
    <>
      <div className="w-full p-4 bg-white mb-4 df:md:mb-0 df:md:border df:md:rounded-lg">
        <div className="text-gray-800 text-xl font-bold leading-[30px] pt-2 pb-6">
          My connections
        </div>
        <div className="gap-y-2 flex flex-col items-center">
          {activeCalls?.map((activeCall) => (
            <ActiveCallCard key={activeCall.id} activeCall={activeCall} />
          ))}
          {backupRequests?.map((backupRequest) => (
            <CallCardWithData
              key={backupRequest.id}
              callCardVariant="backup"
              callData={backupRequest}
            />
          ))}
          {pendingRequests?.map((pendingCall) => (
            <CallCardWithData
              key={pendingCall.id}
              callCardVariant="pending"
              callData={pendingCall}
              showRescheduleModal={showRescheduleModal}
            />
          ))}
          {scheduledCalls?.map((scheduledCall) => (
            <CallCardWithData
              key={scheduledCall.id}
              callCardVariant="scheduled"
              callData={scheduledCall}
              showRescheduleModal={showRescheduleModal}
            />
          ))}
          {unavailableRequests?.map((unavailableRequest) => (
            <CallCardWithData
              key={unavailableRequest.id}
              callCardVariant="unavailable"
              callData={unavailableRequest}
              showRescheduleModal={showRescheduleModal}
            />
          ))}
          {/* This is essentially the completed calls */}
          {incompletePostCalls?.map((incompletePostCall) => (
            <CallCard
              key={incompletePostCall.id}
              variant="completed"
              className="w-full df:md:max-w-full"
              title="Completed Call"
              secondaryText={moment(incompletePostCall.call.ended_at).format(`[Today], hh:mm a`)}
              available={incompletePostCall.call.listener_role.available_now}
              menuItems={[
                {
                  title: 'Report',
                  onClick: () => {
                    history.push(
                      `${ROUTE_PATH.HELP}?option=call-report&call-report=true&call_id=${incompletePostCall.call.id}`
                    );
                  },
                  icon: <SupportBubbleIcon />,
                },
                {
                  title: 'Review',
                  onClick: () => {
                    if (dfMdMedia) {
                      setPostCallIdForPostCallModal(incompletePostCall.id);
                    } else {
                      history.push(
                        `${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${incompletePostCall.id}`
                      );
                    }
                  },
                  icon: <CommentsIcon />,
                },
                {
                  title: 'Dismiss',
                  onClick: () => {
                    postCallMutation.mutate({ postCallId: incompletePostCall.id, payload: {} });
                  },
                  icon: <TrashIcon />,
                },
              ]}
              onButtonClick={() =>
                history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${incompletePostCall.id}`)
              }
              // @ts-ignore
              areaLabelVariants={tagGroups
                ?.filter((tagGroup) =>
                  incompletePostCall.call.request.tag_group_ids?.includes(tagGroup.id)
                )
                .map((tagGroup) => tagGroup.name)}
              img={incompletePostCall.call.listener_role.profile_photo_square_file_url}
            />
          ))}
        </div>
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={scheduleModal}
          isNow={isNow}
          onExit={() => {
            setIsNow(false);
            setListenerId(undefined);
            setOriginalRequestId(undefined);
            setScheduleModal(false);
          }}
          originalRequestId={originalRequestId}
          listenerId={listenerId!}
        />
      )}
      {postCallIdForPostCallModal && (
        <PostCallModal
          formRef={postCallModalForm}
          open={!!postCallIdForPostCallModal}
          onClose={() => setPostCallIdForPostCallModal(undefined)}
          postCallId={postCallIdForPostCallModal}
        />
      )}
    </>
  ) : null;

  function showRescheduleModal(callData: CallRequest) {
    setIsNow(callData.connections[0].listener_role.available_now);
    setListenerId(callData.connections[0].listener_role.id);
    setOriginalRequestId(callData.id);
    setScheduleModal(true);
  }
};

const ExploreAreasSection: React.FC<{ className: string }> = ({ className }) => {
  return (
    <BlockHorizontalScroll
      className={twMerge('df:md:border df:md:rounded-lg', className)}
      contentClassName="flex overflow-x-scroll gap-2 df:md:flex-wrap"
      title="Explore all the areas"
      showViewAll={false}
    >
      {Object.keys(AREA_LABEL_VARIANT).map((areaName: string) => (
        <Link
          key={areaName}
          to={`${ROUTE_PATH.AREA_EXPERIENCES}?${areaName}`}
          className="rounded-[100px]"
        >
          <AreaChip
            className="df:md:text-base"
            variant={areaName as keyof typeof AREA_LABEL_VARIANT}
          />
        </Link>
      ))}
    </BlockHorizontalScroll>
  );
};
