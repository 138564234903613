import { FunctionComponent, HTMLAttributes, ReactNode, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { FieldValues, FormState, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormError, Input, EyeIcon, CloseEyeIcon } from '@kindlyhuman/component-library';

import { useChangePasswordMutation, useUser } from '../../hooks/useUser';

import Toast from '../../components/common/PopUpMessage';

export interface ChangePasswordFormProps extends HTMLAttributes<HTMLFormElement> {
  topActionButton?: (formState: FormState<FieldValues>) => ReactNode;
  bottomActionButton?: (formState: FormState<FieldValues>) => ReactNode;
  onSuccessSubmitted?: () => void;
}

export const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = ({
  topActionButton,
  bottomActionButton,
  onSuccessSubmitted,
  className,
  ...props
}) => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const { data: user } = useUser();
  const changePasswordMutation = useChangePasswordMutation();

  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(changePasswordSchema()),
    mode: 'onTouched',
  });
  const { errors } = formState;

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  /**
   * We have the following problem - a common form to change something + different buttons how do we submit this form
   * There are two ways to solve this problem:
   *  1. Pass the function to the form. The form calls this function with the form state parameters. The function returns JSX
   *  2. We will get the state of the form via ref. We send the form through the methods we added in ref
   * I chose the first option because it is more intuitive and consistent than the second
   */
  const { topActionButtonElement, bottomActionButtonElement } = useMemo(
    () => ({
      topActionButtonElement: topActionButton ? topActionButton(formState) : null,
      bottomActionButtonElement: bottomActionButton ? bottomActionButton(formState) : null,
    }),
    [topActionButton, bottomActionButton, formState]
  );

  const submit: SubmitHandler<
    Partial<{
      current_password: string;
      new_password: string;
      confirm_new_password: string;
    }>
  > = ({ current_password = '', new_password = '', confirm_new_password = '' }) => {
    if (user?.id && new_password === confirm_new_password) {
      return new Promise((res) =>
        changePasswordMutation.mutate(
          {
            currentPassword: current_password,
            newPassword: new_password,
            userId: user.id,
          },
          {
            onSuccess: () => {
              Toast.success(
                'Your password has been changed successfully',
                undefined,
                toastNotificationContainerRef?.current
              );
              reset();
              res(null);
            },
            onError: (error) => {
              // @ts-ignore
              const errorMessage = error?.response?.data?.description;

              if (errorMessage) {
                Toast.error(
                  errorMessage,
                  undefined,
                  toastNotificationContainerRef?.current
                );
              }

              res(error);
            },
          }
        )
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} {...props}>
      <div ref={toastNotificationContainerRef} />
      {topActionButtonElement}
      <div className={className}>
        <Input
          label="CURRENT PASSWORD"
          type={showCurrentPassword ? 'text' : 'password'}
          placeholder="Enter your password"
          required={true}
          {...register('current_password')}
          endIcon={showCurrentPassword ? <CloseEyeIcon /> : <EyeIcon />}
          onIconClick={() => {
            setShowCurrentPassword(!showCurrentPassword);
          }}
          id="current_password"
          error={Boolean(errors?.current_password?.message)}
        />
        {errors?.current_password?.message && (
          <FormError text={errors?.current_password?.message as string} />
        )}
        <Input
          label="NEW PASSWORD"
          type={showNewPassword ? 'text' : 'password'}
          placeholder="Enter new password"
          required={true}
          {...register('new_password')}
          endIcon={showNewPassword ? <CloseEyeIcon /> : <EyeIcon />}
          onIconClick={() => {
            setShowNewPassword(!showNewPassword);
          }}
          id="new_password"
          error={Boolean(errors?.new_password?.message)}
        />
        {errors?.new_password?.message && (
          <FormError text={errors?.new_password?.message as string} />
        )}
        <Input
          label="CONFIRM NEW PASSWORD"
          type={showConfirmNewPassword ? 'text' : 'password'}
          placeholder="Re-enter your new password"
          required={true}
          {...register('confirm_new_password')}
          endIcon={showConfirmNewPassword ? <CloseEyeIcon /> : <EyeIcon />}
          onIconClick={() => {
            setShowConfirmNewPassword(!showConfirmNewPassword);
          }}
          id="confirm_new_password"
          error={Boolean(errors?.confirm_new_password?.message)}
        />
        {errors?.confirm_new_password?.message && (
          <FormError text={errors?.confirm_new_password?.message as string} />
        )}
      </div>
      {bottomActionButtonElement}
    </form>
  );
};

function changePasswordSchema() {
  return yup.object().shape({
    current_password: yup.string().trim().required('Current Password is required.'),
    new_password: yup.string().trim().required('New Password is required.'),
    confirm_new_password: yup
      .string()
      .trim()
      .required('Confirm New Password is required.')
      .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
  });
}
