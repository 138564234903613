import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PartialMember, getPartialMember, useUser } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Button, CircledArrowRightIcon, MobileHeader } from '@kindlyhuman/component-library';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

export const VerificationPage: React.FunctionComponent = (): JSX.Element => {
  const [partialMember, setPartialMember] = useState<PartialMember | undefined>(undefined);
  const [isWelcome, setIsWelcome] = useState(false);
  const [sentLink, setLinkSent] = useState(false);

  const { search } = useLocation();
  const navigate = useHistory();
  const { resendOnboardingLink } = useUser();
  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const sendVerificationLink = (type: 'text' | 'email') => {
    if (!partialMember) {
      return;
    }
    resendOnboardingLink.mutate(
      {
        userId: partialMember.user_id,
        type: type,
      },
      {
        onSuccess: () => {
          setLinkSent(true);
        },
      }
    );
  };

  useEffect(() => {
    getPartialMember(
      { user_id: JSON.parse(sessionStorage.getItem('partial_member_id')!) },
      (partialMember) => {
        setPartialMember(partialMember);
      },
      (err) => {
        navigate.push(ROUTE_PATH['404']);
      }
    );
    setIsWelcome(new URLSearchParams(search).get('mode') === 'welcome');
  }, [navigate, search]);

  return (
    <>
      <MobileHeader
        className="df:md:hidden"
        onBack={() => navigate.goBack()}
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div className="df:md:bg-whiteSmoke df:md:flex df:md:items-center df:md:justify-center">
          <div className="px-7.5 mt-8 pb-20 max-w-[480px] mx-auto">
            {
              sentLink
                ? (
                  <>
                    <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                      Please check your inbox
                    </p>
                    <h6 className="text-dark text-lg pt-8 leading-6">A new invite link has been sent.</h6>
                    <div className="w-full flex justify-center pt-8 pb-4">
                      <Button
                        data-testid="close-button"
                        variant="primary"
                        onClick={() => {
                          navigate.push(ROUTE_PATH.LOGIN);
                        }}
                      >
                        {
                          dfMdMedia
                            ? 'Login'
                            : 'Close'
                        }
                      </Button>
                    </div>
                  </>
                )
                : (
                  <>
                    <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                      {isWelcome ? 'Welcome!' : "Hmm... that didn't work."}
                    </p>
                    <h6 className="text-dark text-lg pt-4 leading-6 pb-8">
                      {isWelcome
                        ? 'We need to verify your email. We just sent you an email - please open it and click the link to verify your account.'
                        : 'It looks like the verification code is incorrect or may be expired. We can resend you a new link by email or text'}
                    </h6>
                    <div className="w-full flex flex-col items-center gap-4">
                      {isWelcome && (
                        <p className="text-sm text-center">
                          Didn’t get an email?
                          {' '}
                          <br className="df:md:hidden" />
                          Click the button below to resend
                        </p>
                      )}
                      <Button
                        data-testid="resend-email-button"
                        variant="primary"
                        onClick={() => {
                          sendVerificationLink('email');
                        }}
                      >
                        Resend Code to my Email
                      </Button>
                    </div>
                    <h6 className="text-dark text-lg py-4 w-full flex justify-center">
                      {partialMember?.user_email_obfuscated}{' '}
                    </h6>
                    {partialMember?.user_phone_obfuscated &&
                      partialMember.user_phone_obfuscated.length > 1 && (
                        <>
                          <br />
                          <div className="pt-4 w-full flex justify-center">
                            <Button
                              data-testid="resend-email-phone"
                              variant="primary"
                              onClick={() => {
                                sendVerificationLink('text');
                              }}
                            >
                              Resend Code to my Phone
                            </Button>
                          </div>
                          <h6 className="text-dark text-lg py-4 w-full flex justify-center">
                            {partialMember?.user_phone_obfuscated}{' '}
                          </h6>
                        </>
                      )}
                  </>
                )
            }
            <div className="hidden df:md:flex justify-between items-center gap-2 mt-4">
              <Link
                className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                to={ROUTE_PATH.SIGNUP}
              >
                <CircledArrowRightIcon className="rotate-180" />
                Sign up
              </Link>
              <Link
                data-testid="login-button"
                className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                to={ROUTE_PATH.LOGIN}
              >
                Log in
                <CircledArrowRightIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
