import React from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import { INVALID_DATE } from '.';
import { FormError, FormItemName } from '@kindlyhuman/component-library';

const DOB: React.FC = () => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  return (
    <div className="space-y-6">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-center df:md:text-3xl
        "
      >
        <span className="font-bold">How old</span>
        {' '}
        are you?
      </p>
      <div className="space-y-1.5">
        <FormItemName
          name="DATE OF BIRTH"
          isRequired
        />
        <Controller
          name="date_of_birth"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value } }) => (
            <DesktopDatePicker
              disableFuture={true}
              inputFormat="LL"
              value={value?.replaceAll('-', '/')}
              onChange={(value: any | null) => {
                if (value) {
                  const date =
                    +value?.format('yyyy') < 1000 || !value?.year()
                      ? INVALID_DATE
                      : value?.format('MM-DD-yyyy').toString();
                  return onChange(date);
                } else {
                  setValue('date_of_birth', '', {
                    shouldDirty: true,
                  });
                }
              }}
              onOpen={() => {
                if (value === '') {
                  return onChange('01-01-1990');
                }
              }}
              InputProps={{
                disableUnderline: true,
                className:
                  'rounded-5px text-gray-900 text-sm font-sans leading-normal h-12.5 px-3 bg-white border border-lightSilver w-full',
              }}
              renderInput={(params) => (
                <TextField
                  id="date_of_birth"
                  {...params}
                  variant="standard"
                  sx={{
                    width: '100%',
                    svg: { color: '#232323' },
                  }}
                />
              )}
            />
          )}
        />
        {errors?.date_of_birth?.message && (
          <FormError text={errors?.date_of_birth?.message as string} />
        )}
        <p className=" text-gray-900 text-sm not-italic font-normal leading-5 df:md:text-neutral-700">
          You must be 18 years or older to use our service. Your age is used to help match you with
          relevant peers.
        </p>
      </div>
    </div>
  );
};

export default DOB;
