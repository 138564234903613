import React, { useEffect, useState } from 'react';

import { useResources } from '../../hooks/useResources';
import { useUser } from '../../hooks/useUser';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useFavoritedPeers, useNewPeers } from '../../hooks/usePeers';
import { useExperiences } from '../../hooks/useExperiences';
import { useCallRequests } from '../../hooks/useCalls';
import { useExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { useReflectionDismissMutation, useReflections } from '../../hooks/useReflection';

import { TagFilters } from '../../components/filter/FomSelectionFilter';

import { HomeMobileView } from './mobile';

const HomePage = () => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [playerOpen, setPlayerOpen] = useState<number>();
  const [showReflectionTile, setShowReflectionTile] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);

  const { data: user, updateTagPreferences } = useUser();
  const { data: fomHierarchy, getTagGroupByName } = useTagGroups();
  const { data: callRequests } = useCallRequests(user?.caller_role_id);
  const { data: providerPlans } = useExternalProviderPlan();

  const favoritedPeers = useFavoritedPeers({ limit: 10 });
  const newPeers = useNewPeers({ limit: 10 });

  const { recommendedExperiences } = useExperiences();
  const resources = useResources();
  const currentReflection = useReflections();
  const reflectionDismiss = useReflectionDismissMutation();

  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  useEffect(() => {
    if (currentReflection && user?.caller_role_id) {
      setShowReflectionTile(true);
    }
  }, [currentReflection, setShowReflectionTile, user]);

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
  };

  const handleReflection = () => {
    setShowReflectionTile(false);
    setShowReflectionModal(true);
  };

  const handleReflectionDismiss = () => {
    reflectionDismiss.mutate(currentReflection?.data?.id);
    setShowReflectionTile(false);
  };

  const handleReflectionModalDismiss = () => {
    setShowReflectionModal(false);
  };

  return (
    <HomeMobileView
      callRequests={callRequests}
      audioStopAll={audioStopAll}
      favoritedPeers={favoritedPeers}
      getTagGroupByName={getTagGroupByName}
      handleFilterChange={handleFilterChange}
      newPeers={newPeers}
      setPlayerOpen={setPlayerOpen}
      setShowFilterMenu={setShowFilterMenu}
      showFilterMenu={showFilterMenu}
      fomHierarchy={fomHierarchy}
      playerOpen={playerOpen}
      resources={resources}
      providerPlan={providerPlan}
      user={user}
      recommendedExperiences={recommendedExperiences}
      currentReflection={currentReflection}
      showReflectionTile={showReflectionTile}
      showReflectionModal={showReflectionModal}
      handleReflectionModalDismiss={handleReflectionModalDismiss}
      handleReflection={handleReflection}
      handleReflectionDismiss={handleReflectionDismiss}
    />
  );
};

export default HomePage;
