import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { MobileHeader, DesktopHeader, Stepper, SpinnerIcon } from '@kindlyhuman/component-library';
import { usePostCall } from '../../hooks/usePostCall';
import { PostCallForm, PostCallFormSteps } from '../../components/common/PostCallForm';

const PostCallPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const postCallId: number = Number(searchParams.get('postCallId'));

  const navigate = useHistory();

  const [step, setStep] = useState<PostCallFormSteps>(
    JSON.parse(sessionStorage.getItem(`postCallForm-${postCallId}`) || 'null')?.step
      || PostCallFormSteps.DESCRIBE
  );

  const { data: postCall, isLoading } = usePostCall(postCallId);

  return (
    <div className="min-h-screen bg-neutral-700 bg-opacity-5 df:md:bg-white">
      <DesktopHeader
        className="hidden df:md:block"
        homeLink={ROUTE_PATH.HOME}
      />
      <MobileHeader
        className="df:md:hidden"
        onBack={() =>
          step === PostCallFormSteps.DESCRIBE || step === PostCallFormSteps.THANK_YOU
            ? navigate.push(ROUTE_PATH.HOME)
            : setStep(step - 1)
        }
        goHome={() =>
          navigate.push({
            pathname: ROUTE_PATH.HOME,
          })
        }
      />
      {
        isLoading
          ? (
            <div className="w-full h-144 flex items-center justify-center">
              <SpinnerIcon />
            </div>
          )
          : (
            <>
              {
                step !== PostCallFormSteps.THANK_YOU && (
                  <div className="p-4 bg-white border-b flex items-center justify-center">
                    <Stepper
                      currentStep={step + 1}
                      // steps is annoying because the steps are used as the keys in react, so they should be unique
                      steps={[...Array(4).keys()].map(index => ''.padEnd(index))}
                    />
                  </div>
                )
              }
              <PostCallForm
                className="mt-5"
                step={step}
                setStep={setStep}
                postCall={postCall}
              />
            </>
          )
      }
    </div>
  );
};

export default PostCallPage;
