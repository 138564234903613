import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ExitIcon,
  Dialog,
  DialogProps,
  Button,
} from '@kindlyhuman/component-library';

import { SupportForm } from '../common/SupportForm';

export interface SupportModalProps extends DialogProps {
  onSuccessSubmitted?: () => void
}

export const SupportModal = ({ onClose, onSuccessSubmitted, ...rest }: SupportModalProps) => {
  const search = useLocation().search;

  const { callReport, callId, option, contactUs } = useMemo(
    () => {
      const urlSearchParams = new URLSearchParams(search);

      return {
        callReport: urlSearchParams.get('call-report'),
        callId: urlSearchParams.get('call_id'),
        option: urlSearchParams.get('option'),
        contactUs: urlSearchParams.get('error'),
      }
    },
    [search]
  );

  return (
    <Dialog
      className="rounded-lg w-full max-w-[620px] shadow-modal p-0 bg-white backdrop:bg-modalBackdropColor"
      onClose={onClose}

      { ...rest }
    >
      <div className="flex items-center justify-between p-6 border-b border-b-neutral-200">
        <div className="space-y-2">
          <p className="text-2xl font-bold text-dark">
            Support
          </p>
          <p className="text-sm font-medium text-neutral-700">
            Let us know how we can best provide support for you today.
          </p>
        </div>
        <button onClick={onClose}>
          <ExitIcon color="#22282F" />
        </button>
      </div>
      <SupportForm
        className="mx-6 my-8"
        option={option}
        callReport={callReport}
        callId={callId}
        contactUs={contactUs}
        bottomActionButton={({ isDirty, isSubmitting }) => (
          <div className="flex justify-end p-6 border-t border-t-neutral-200">
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        )}
        onSuccessSubmitted={() => {
          onClose();

          if (onSuccessSubmitted) {
            onSuccessSubmitted();
          }
        }}
      />
    </Dialog>
  );
}
