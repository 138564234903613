import { useMediaQuery } from '../../hooks/useMediaQuery';
import { MemberManagementMobile } from './mobile';

const MemberManagementPage = () => {
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');
  return sm || md ? <MemberManagementMobile /> : <MemberManagementMobile />;
};

export default MemberManagementPage;


