import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { ActiveCall, useActiveCalls, useEndCallMutation } from '../../hooks/useCalls';
import { useUser } from '../../hooks/useUser';
import {
  AreaLabel,
  Avatar,
  Button,
  MobileHeader,
  DesktopHeader,
  TraitList,
} from '@kindlyhuman/component-library';
import { PeerDetail, usePeerDetail } from '../../hooks/usePeers';
import { useTagGroups } from '../../hooks/useTagGroups';
import { usePhoneFormat } from '../../hooks/usePhoneFormat';

const ActiveCallMobile = () => {
  const { data: user } = useUser();
  const { data: activeCall } = useActiveCalls(user?.caller_role_id);
  const activeCallData = activeCall?.at(0);
  const { data: peerDetails } = usePeerDetail(activeCallData?.listener_role_id);
  const navigate = useHistory();

  const freeAvailableMinutes = user?.caller_role.payment_data.available_minutes;

  useEffect(() => {
    if (!activeCallData) {
      navigate.push(ROUTE_PATH.HOME);
    }
  }, [activeCallData, navigate]);

  return (
    <div className="w-screen min-h-screen bg-neutral-700 bg-opacity-5 flex flex-col gap-y-3">
      <DesktopHeader
        className="hidden df:md:block"
        homeLink={ROUTE_PATH.HOME}
      />
      <MobileHeader
        className="df:md:hidden"
        goHome={() =>
          navigate.push({
            pathname: ROUTE_PATH.HOME,
          })
        }
      />
      <ActiveCallHeader />
      {!user?.onUnlimitedPlan && <BalanceCard freeAvailableMinutes={freeAvailableMinutes!} />}
      <PeerDetailCard peerDetails={peerDetails} activeCall={activeCallData} />
      <DisconnectedCard activeCallData={activeCallData} />
      <div className="pt-20"></div>
      <ButtonFooter activeCallData={activeCallData} />
    </div>
  );
};

const ActiveCallHeader = () => (
  <div className="px-4 pb-4 flex-col justify-center items-start flex">
    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
      <div className="self-stretch text-gray-800 text-2xl font-bold leading-loose">Active call</div>
      <div className="self-stretch text-stone-500 text-sm font-medium leading-tight">
        This call is starting soon or currently active
      </div>
    </div>
  </div>
);

const BalanceCard: React.FC<{ freeAvailableMinutes: number }> = ({ freeAvailableMinutes }) => (
  <div className="py-5 px-4 bg-white border-t border-b border-neutral-200 justify-between items-center gap-6 inline-flex">
    <div className="text-center text-gray-800 text-base font-bold leading-normal">Your balance</div>
    <div className="text-stone-500 text-base font-medium leading-normal">
      {freeAvailableMinutes} min credits
    </div>
  </div>
);

const DisconnectedCard: React.FC<{ activeCallData?: ActiveCall }> = ({ activeCallData }) => {
  const formattedPhone = usePhoneFormat(activeCallData?.phone_number ?? '')

  return (
    <div className="py-5 px-4 bg-white border-t border-b border-neutral-200 justify-between items-center gap-6 inline-flex">
      <div className="text-center text-gray-800 text-base font-bold leading-normal">
        Disconnected? Call
      </div>
      <div className="justify-start items-center gap-1 flex">
        <a
          href={`tel:${activeCallData?.phone_number}`}
          className="text-center text-violet-950 text-base font-semibold leading-normal"
        >
          {formattedPhone}
        </a>
      </div>
    </div>
  );
};

type PeerDetailCardProps = {
  peerDetails?: PeerDetail;
  activeCall?: ActiveCall;
};

const PeerDetailCard: React.FunctionComponent<PeerDetailCardProps> = ({
  peerDetails,
  activeCall,
}): JSX.Element => {
  const { data: tagGroups } = useTagGroups();
  const traitList: string[] = peerDetails
    ? [...peerDetails.profile_traits, ...peerDetails.background_traits]
    : [''];

  return (
    <div className="w-full flex flex-col items-center bg-white py-5 px-5">
      <Avatar
        variant="x-large"
        image={peerDetails?.profile_photo_square_file_url || ''}
        available={true}
        isPeerListener={true}
      />
      <div className="mt-2">
        <h3 className="text-center text-gray-800 text-lg font-bold leading-7">
          {peerDetails?.user?.display_name?.length
            ? peerDetails?.user?.display_name
            : peerDetails?.user?.first_name}
        </h3>
      </div>
      <div className="flex flex-wrap max-w-70vw justify-evenly gap-3 py-4">
        {tagGroups
          ?.filter((tagGroup) => activeCall?.request.tag_group_ids?.includes(tagGroup.id))
          .map((tag_group) => (
            // @ts-ignore
            <AreaLabel key={tag_group.key} variant={tag_group.name} />
          ))}
      </div>
      <div>
        <TraitList align="center" traits={traitList} />
      </div>
    </div>
  );
};

const ButtonFooter: React.FC<{ activeCallData?: ActiveCall }> = ({ activeCallData }) => {
  const endCallMutation = useEndCallMutation();
  return (
    <div className="py-4 flex justify-evenly fixed bottom-0 left-0 w-full bg-white">
      <Button
        onClick={() => {
          activeCallData && endCallMutation.mutate(activeCallData.id);
        }}
        variant="secondary"
      >
        <div className="w-30">End Call</div>
      </Button>
      <Link to={`${ROUTE_PATH.HELP}?option=call-report&call_id=${activeCallData?.id}`}>
        <Button variant="secondary">
          <div className="w-30">Report</div>
        </Button>
      </Link>
    </div>
  );
};

export default ActiveCallMobile;
