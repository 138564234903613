import { Fragment, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

import { ChevronDownIcon, Button, PeerCard, LoadingDotsIcon } from '@kindlyhuman/component-library';

import WelcomeVideo from '../components/welcome-video';
import { AddToHome } from '../components/add-to-home';

import { useTagGroups } from '../../../hooks/useTagGroups';
import { useUser } from '../../../hooks/useUser';
import {
  Peer,
  peersQueryKeys,
  useAddFavoritePeer,
  useRecommendedPeers,
  useRemoveFavoritePeer,
} from '../../../hooks/usePeers';

const pageSize = 10;

const PeersStep = () => {
  const [step, setStep] = useState(1);
  const [loadMessage, setLoadMessage] = useState('Analyzing your interests...');
  const [armed, setArmed] = useState<boolean>(false);
  const recommendedPeers = useRecommendedPeers();

  useEffect(() => {
    const loadingMessageTimeout = setTimeout(
      () => {
        setLoadMessage('Looking for just the right matches...');
      },
      3000 + 1000 * Math.random()
    );

    const secondLoadingMessageTimeout = setTimeout(
      () => {
        setArmed(true);
      },
      5000 + 1000 * Math.random()
    );

    return () => {
      clearTimeout(loadingMessageTimeout);
      clearTimeout(secondLoadingMessageTimeout);
    };
  }, []);

  useEffect(() => {
    if (step === 1 && armed && recommendedPeers.data) {
      setStep(2);
    }
  }, [step, setStep, armed, recommendedPeers.data]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div
            className="
              bg-white flex flex-col items-center justify-center gap-5 pt-20
              df:md:p-0 df:md:bg-whiteSmoke df:md:fixed df:md:top-0 df:md:left-0 df:md:w-screen df:md:h-screen df:md:z-20
            "
          >
            <LoadingDotsIcon />
            <p className="text-[#081D40] text-base font-bold">
              {loadMessage}
            </p>
          </div>
        );
      case 2:
        return (
          <OnboardingPeersList
            peers={recommendedPeers.data?.data ?? []}
            totalNumPeers={recommendedPeers.data?.count ?? -1}
          />
        );
    }
  };

  return <>{renderStep()}</>;
};

const OnboardingPeersList: React.FC<{
  peers: Peer[];
  totalNumPeers: number;
}> = ({ peers, totalNumPeers }) => {
  const { data: user, updateUser } = useUser();
  const [showWelcomeVideo, setShowWelcomeVideo] = useState<boolean>(false);
  const [showAddToHome, setShowAddToHome] = useState<boolean>(false);
  const [numPeersShown, setNumPeersShown] = useState<number>(pageSize);
  const addFavPeerMutation = useAddFavoritePeer(peersQueryKeys.recommended);
  const removeFavPeerMutation = useRemoveFavoritePeer(peersQueryKeys.recommended);
  const { getParentByParentId } = useTagGroups();

  const handleClick = () => {
    updateUser.mutate(
      { is_partial: false },
      {
        onSuccess: () => {
          if (isDesktop) {
            setShowWelcomeVideo(true)
          }
          else {
            setShowAddToHome(true);
          }
        },
      }
    );
  };

  if (showWelcomeVideo) {
    return <WelcomeVideo />;
  }

  if (showAddToHome) {
    return <AddToHome onComplete={() => setShowWelcomeVideo(true)} />
  }

  return (
    <div className="space-y-6">
      <div className="space-y-2 df:md:text-center">
        <p
          className="
            text-gray-900 text-2xl font-manrope
            df:md:text-3xl
          "
        >
          We found some peers we think you may
          {' '}
          <span className="font-bold">connect with</span>
          .
        </p>
        <p className="text-gray-900 text-base">
          Follow as many peers as you'd like
        </p>
      </div>
      <div
        className="
          w-screen -translate-x-1/2 relative left-1/2 grid gap-3
          df:md:grid-cols-2 df:md:gap-6 df:md:w-full df:md:left-0 df:md:translate-x-0
        "
      >
        {peers &&
          peers.slice(0, numPeersShown).map((peer) => (
            <Fragment key={peer.listener_role_id}>
              <PeerCard
                className="overflow-hidden rounded-lg df:md:border df:md:border-neutral-200 df:md:p-5"
                name={peer.display_name}
                variant="wide"
                available={peer.is_available}
                isPeerListener={peer.is_listener}
                img={peer.profile_photo_url_square}
                description={peer.about_me}
                // @ts-ignore
                areasLabels={peer?.challenge_areas
                  ?.filter((item) => user?.caller_role.tag_group_ids?.includes(item.id))
                  .map((challengeArea) => challengeArea.name)}
                // @ts-ignore
                subjectChips={peer?.subject_areas
                  ?.filter((item) => user?.caller_role.tag_group_ids?.includes(item.id))
                  .map((subject) => {
                    return {
                      name: subject.name,
                      variant: getParentByParentId(subject.parent_id)?.key,
                    };
                  })}
                traits={peer.tags.map((tag) => tag.name)}
                followed={peer.is_member_following}
                onFollow={
                  peer.is_member_following
                    ? () => removeFavPeerMutation.mutate(peer.listener_role_id)
                    : () => addFavPeerMutation.mutate(peer.listener_role_id)
                }
              />
              <hr className="h-2 bg-neutral-200 df:md:hidden" />
            </Fragment>
          ))}
      </div>
      {totalNumPeers > numPeersShown && (
        <button
          onClick={() => {
            setNumPeersShown((numPeers) => {
              return numPeers + pageSize;
            });
          }}
          className="py-4 pt-8 flex justify-center gap-1 cursor-pointer mx-auto"
        >
          <div className="text-center text-violet-950 text-base font-bold leading-normal">
            See 10 more
          </div>
          <ChevronDownIcon width={20} color="#24006B" />
        </button>
      )}
      <div className="pt-20"></div>
      <div className="py-8 border-t px-4 gap-x-4 flex justify-evenly fixed bottom-0 left-0 w-full bg-white">
        <Button
          data-testid="next-button"
          className="w-full df:md:w-40"
          variant={
            peers.some((peer) => peer.is_member_following === true) ? 'primary' : 'secondary'
          }
          onClick={handleClick}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PeersStep;
