import { useInfinitePeers } from '../../hooks/usePeers';
import { useUser } from '../../hooks/useUser';
import { useTagGroups } from '../../hooks/useTagGroups';

import { TagFilters } from '../../components/filter/FomSelectionFilter';

import { RecommendedPeersMobile } from './mobile';
import { useState } from 'react';

// TODO this is very similar to the My Peers page. When we have more time, consider consolidating them to improve code reuse.
const RecommendedPeersPage = () => {
  const peerQuery = useInfinitePeers();
  const { data: user, updateTagPreferences } = useUser();
  const { data: fomHierarchy, getTagGroupByName } = useTagGroups();
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false)
  };

  return (
    <RecommendedPeersMobile
    showFilterMenu={showFilterMenu}
    setShowFilterMenu={setShowFilterMenu}
      peerQuery={peerQuery}
      getTagGroupByName={getTagGroupByName}
      handleFilterChange={handleFilterChange}
      user={user}
      fomHierarchy={fomHierarchy}
    />
  );
};

export default RecommendedPeersPage;
