import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckmarkIcon,
  CircledArrowRightIcon,
  DesktopHeader,
  MobileHeader,
  SpinnerIcon,
} from '@kindlyhuman/component-library';

import { twMerge } from 'tailwind-merge';
import { useSubscriptions } from '../../../hooks/useSubscription';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { useHistory } from 'react-router-dom';
import { SubHeader } from '../../../components/MenuHeader';
import { UserPlanBenefits } from '../../../components/common/user_plan';
import { useMediaQueryWithDesktopFeature } from '../../../hooks/useMediaQueryWithDesktopFeature';

interface PaymentPlanProps {
  className?: string;
  banner?: string;
  minutes?: number;
  planPrice: number;
  discountPercentage?: number;
  planDescription?: string;
  planDetails?: string;
  selected?: boolean;
  onSelect?: (id: number) => void;
  id?: number;
}

export const PaymentPlan: FunctionComponent<
  PaymentPlanProps & { selected: boolean; onSelect: (id: any) => void; id: any }
> = ({
  className,
  banner,
  minutes,
  planPrice,
  discountPercentage,
  planDescription,
  planDetails,
  selected,
  onSelect,
  id,
}) => {
  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <div
      data-testid={`plan-${planDescription}`}
      className={twMerge(
        'space-y-4',
        !dfMdMedia && selected ? 'bg-[#E6E6E6]' : 'bg-white',
        className
      )}
    >
      <div className="text-gray-800 font-medium space-y-2">
        {banner && (
          <div className="w-screen -translate-x-1/2 relative left-1/2 df:md:w-full df:md:left-0 df:md:translate-x-0">
            <p
              className="
                px-4 py-1 bg-[#AFFF54] bg-opacity-30 rounded-tr-lg rounded-br-lg text-sm text-center inline-block
                df:md:rounded-lg
              "
            >
              {banner}
            </p>
          </div>
        )}
        <div className="flex justify-between">
          <p className="text-base">{minutes} minutes</p>
          <div className="df:md:hidden">
            <Checkbox
              data-testid={`plan-checkbox-${planDescription}`}
              className="mr-0"
              variant="DEFAULT"
              checked={selected}
              onChange={() => onSelect(id)}
            />
          </div>
        </div>
        <p className="text-xl font-bold df:md:text-2xl">${planPrice} / month</p>
        <p className="text-sm">{planDescription}</p>
      </div>
      {!!discountPercentage && (
        <div className="flex items-center gap-2">
          <CheckmarkIcon />
          <p className="text-sm font-bold">Save {discountPercentage}%</p>
        </div>
      )}
      <Button
        className="w-full hidden df:md:block"
        variant="primary"
        onClick={() => onSelect(id)}
      >
        Select
      </Button>
      {planDetails && (
        <UserPlanBenefits
          data-testid="user-plan-dropdown"
          className={twMerge(
            'p-0',
            !dfMdMedia && selected ? 'bg-[#E6E6E6]' : 'bg-white'
          )}
          details={planDetails}
          defaultOpen
          hideTriggerButton
        />
      )}
    </div>
  );
};

const SelectPlan: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const clientCode = String(sessionStorage.getItem('clientCode'));

  const { data: subscriptions, isLoading } = useSubscriptions(clientCode);

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handleSelectPlan = (planId: any) => {
    setSelectedPlanId(selectedPlanId !== planId ? planId : null);
  };

  const saveSelectedPlanID = useCallback(
    (planId: any = selectedPlanId) => {
      if (planId) {
        localStorage.setItem('planId', planId);
        navigate.replace(ROUTE_PATH.ONBOARDING);
      }
    },
    [navigate, selectedPlanId]
  );

  return (
    <div className="min-h-screen flex flex-col">
      <DesktopHeader className="hidden df:md:block" />
      <MobileHeader
        className="df:md:hidden"
        onBack={() => navigate.goBack()}
      />
      <div className="bg-white h-full grow df:md:bg-whiteSmoke">
        <div className="mx-auto df:md:max-w-7xl df:md:px-6">
          <SubHeader
            className="hidden mt-6 df:md:block"
            title=""
            onBackButtonClick={() => navigate.goBack()}
          />
          <div className="font-manrope text-gray-900 px-5 py-8 df:md:text-center df:md:pt-0">
            <span className="text-2xl font-bold df:md:text-3xl">Select Plan</span>
            <p className="text-base font-normal flex flex-wrap gap-2 df:md:hidden">
              Have any questions? Check our
              <button
                onClick={() => {}}
                className="flex text-violet-950 font-semibold items-center cursor-pointer"
              >
                FAQ's &nbsp;
                <CircledArrowRightIcon />
              </button>
            </p>
          </div>
          {
            isLoading
              ? (
                <div className="w-full h-50 flex items-center justify-center">
                  <SpinnerIcon />
                </div>
              )
              : (
                <>
                  <div
                    className={twMerge(
                      'grid grid-cols-1 gap-6 df:md:pb-8',
                      (subscriptions?.length || 0) === 1 && 'df:md:place-items-center',
                      (subscriptions?.length || 0) === 2 && 'df:md:grid-cols-2',
                      (subscriptions?.length || 0) >= 3 && 'df:md:grid-cols-3'
                    )}
                  >
                    {subscriptions?.map(subscription => (
                      <div className="space-y-4" key={subscription.id}>
                        <PaymentPlan
                          id={subscription.id}
                          selected={selectedPlanId === subscription.id}
                          onSelect={
                            dfMdMedia
                              ? saveSelectedPlanID
                              : handleSelectPlan
                          }
                          className="
                            py-5 px-4 border-t border-b border-neutral-200
                            df:md:rounded-lg df:md:border df:md:p-6
                          "
                          banner={subscription.banner_text}
                          minutes={subscription.minutes}
                          discountPercentage={subscription.discount}
                          planDescription={subscription.description}
                          planPrice={subscription.price / 100}
                          planDetails={subscription.details}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="py-3 px-4 df:md:hidden">
                    <Button
                      data-testid="select-plan-button"
                      className="w-full"
                      variant="primary"
                      onClick={() => saveSelectedPlanID()}
                      disabled={selectedPlanId === null}
                    >
                      Select plan
                    </Button>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;
