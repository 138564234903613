import React, { useState } from 'react';
import { Button, MobileHeader, Input, EyeIcon, LockIcon } from '@kindlyhuman/component-library';
import '@kindlyhuman/component-library/dist/style.css';

import { useUser } from '../../../hooks/useUser';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { Checkbox, Modal, Typography } from '@mui/material';
import { CloseIcon } from '../../../components/common/svgs';
import { useAppSettings } from '../../../hooks/useAppSettings';
import { WelcomeSlogan } from '../../../components/common/WelcomeSlogan';

export const SetPassword: React.FC = () => {
  const { updateUser } = useUser();
  const { data: appSettings } = useAppSettings();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [modal, setModal] = useState<'t&c' | 'privacy' | undefined>();
  const navigate = useHistory();

  const handleCheckboxChange = () => {
    setTermsConditionsChecked(!termsConditionsChecked);
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError('Password must be at least 8 characters long and contain a number');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleContinue = async () => {
    updateUser.mutate(
      {
        password: password,
      },
      {
        onSuccess: () => {
          navigate.push(ROUTE_PATH.ONBOARDING);
        },
        onError: (error: any) => {
          if (error?.response?.data?.description) {
            setPasswordError(error.response.data.description);
          } else {
            setPasswordError('');
          }
        },
      }
    );
  };

  return (
    <>
      <MobileHeader
        className="df:md:hidden"
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div className="pt-8 pb-32 df:md:bg-whiteSmoke df:md:flex df:md:items-center df:md:justify-center">
          <div className="px-5 space-y-4 df:lg:px-30 df:md:px-15">
            <div className="space-y-1">
              <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                Finish creating your account
              </p>
              <p className="text-gray-900 text-base not-italic font-normal leading-6">
                Set a password below
              </p>
            </div>
            <div className="space-y-4">
              <Input
                required
                endIcon={<EyeIcon />}
                label="Password"
                id="password"
                placeholder="Password..."
                startIcon={<LockIcon />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={validatePassword}
                error={passwordError !== ''}
                helperText={passwordError}
                type={showPassword ? 'text' : 'password'}
                onIconClick={() => setShowPassword(!showPassword)}
              />
              <Input
                required
                id="password2"
                endIcon={<EyeIcon />}
                label="Re-enter Password"
                placeholder="Re-enter your Password..."
                startIcon={<LockIcon />}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={validateConfirmPassword}
                error={confirmPasswordError !== ''}
                helperText={confirmPasswordError}
                type={showPasswordConfirm ? 'text' : 'password'}
                onIconClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              />
              <label>
                <Checkbox checked={termsConditionsChecked} onChange={handleCheckboxChange} />I have read
                and accept these{' '}
                <Typography component="a" href="#" onClick={() => setModal('t&c')} color="primary">
                  Terms & Conditions
                </Typography>{' '}
                and{' '}
                <Typography component="a" href="#" onClick={() => setModal('privacy')} color="primary">
                  Privacy Policy
                </Typography>
                .
              </label>
              <Button
                className="w-full"
                variant="primary"
                onClick={handleContinue}
                disabled={
                  passwordError !== '' || confirmPasswordError !== '' || !termsConditionsChecked || updateUser.isLoading
                }
                loading={updateUser.isLoading}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modal === 't&c'}
        onClose={() => setModal(undefined)}
        aria-labelledby="terms-modal-title"
      >
        <div className="bg-white p-4 overflow-auto scrollable-height m-4">
          <div className="flex justify-end">
            <CloseIcon
              className="text-primary cursor-pointer w-4 h-4"
              onClick={() => setModal(undefined)}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: appSettings?.TERMS_OF_SERVICE ?? '',
            }}
          ></div>
          <Button onClick={() => setModal(undefined)} className="w-full my-4" variant="primary">
            Close
          </Button>
        </div>
      </Modal>

      <Modal
        open={modal === 'privacy'}
        onClose={() => setModal(undefined)}
        aria-labelledby="privacy-modal-title"
      >
        <div className="bg-white p-4 overflow-auto scrollable-height m-4">
          <div className="flex justify-end">
            <CloseIcon
              className="text-primary cursor-pointer w-4 h-4"
              onClick={() => setModal(undefined)}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: appSettings?.PRIVACY_POLICY ?? '',
            }}
          ></div>
          <Button onClick={() => setModal(undefined)} className="w-full my-4" variant="primary">
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};
