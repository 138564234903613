import React, { useState } from 'react';
import {
  Button,
  MobileHeader,
  Input,
  CircledArrowRightIcon,
  EmailIcon,
  Dialog,
} from '@kindlyhuman/component-library';
import '@kindlyhuman/component-library/dist/style.css';
import { AxiosResponse } from 'axios';
import { Link, useHistory } from 'react-router-dom';

import { MemberSignupResponse, getPartialMember, useUser } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { emailRegex } from '../../components/common/constants';
import AccountGroupIdVerification from '../onboarding/components/account-groupid-verification';
import { isGroupSelfEnroll } from '../../hooks/usePackageDetails';
import rollbar from '../rollbar';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';

const SignupPage: React.FC = () => {
  const { signupUser, resendOnboardingLink } = useUser();
  const navigate = useHistory();

  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState('');
  const [groupVerificationError, setGroupVerificationError] = useState<string | undefined>();
  const [groupIdMessageShowing, setGroupIdMessageShowing] = useState(false);
  const [groupIdValue, setGroupIdValue] = useState('');
  const [showGroupIdModal, setShowGroupIdModal] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const validateEmail = () => {
    const trimEmail = emailAddress?.trim();
    setEmailAddress(trimEmail);

    if (trimEmail === '' || trimEmail === undefined || !emailRegex.test(trimEmail)) {
      setEmailError('Enter valid email address');
    } else {
      setEmailError('');
    }
  };

  const signup = (clientCode: string) =>
    signupUser.mutate(
      { email_address: emailAddress, package_code: clientCode },
      {
        onSuccess: (userResponse: AxiosResponse<MemberSignupResponse>) => {
          setShowGroupIdModal(false);
          sessionStorage.setItem('partial_member_id', JSON.stringify(userResponse.data.id));
          resendOnboardingLink.mutate({ userId: userResponse.data.id, type: 'email' });
          navigate.push(`${ROUTE_PATH.VERIFICATION}?mode=welcome`);
        },
        onError: (error: any) => {
          setEmailError(error.response.data.description);
        },
      }
    );

  const handleContinue = async () => {
    if (emailError) {
      return;
    }

    setInProgress(true);

    getPartialMember(
      { email_address: emailAddress },
      // onSuccess:
      (partialMember) => {
        // Yes - there already exists a partial member.  IE, they came in from an eligibility file.
        // then redirect to Verification page
        sessionStorage.setItem('partial_member_id', JSON.stringify(partialMember.user_id));
        navigate.push(ROUTE_PATH.VERIFICATION);
      },
      (err) => {
        const clientCode = sessionStorage.getItem('clientCode');
        if (clientCode) {
          signup(clientCode);
        } else {
          switch (err.response?.status) {
            case 400:
              // @ts-ignore
              let error_message = err.response?.data.description;
              error_message = error_message.charAt(0).toUpperCase() + error_message.slice(1); // capitalize the error message.
              setEmailError(error_message);
              break;
            case 404:
              setGroupIdMessageShowing(true);
              setShowGroupIdModal(true);
              break;
            default:
              setEmailError('Something went wrong; please try again later.');
              rollbar.error(`Error looking for a partial member`, new Date(), {
                message: `Environment: ${process.env.REACT_APP_ENVTYPE}; Error: ${err}`,
              });
              break;
          }
        }
      },
      () => {
        setInProgress(false);
      }
    );
  };

  return (
    <>
      <MobileHeader
        className="df:md:hidden"
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div className="pt-8 px-5 pb-32 df:md:bg-whiteSmoke df:md:flex df:md:items-center df:md:justify-center">
          <div className="w-full max-w-[480px] mx-auto">
            <div className="font-manrope text-gray-900 space-y-1 df:md:space-y-2">
              <p className="text-2xl font-bold leading-8">
                Create your account
              </p>
              <p className="text-gray-900 text-base leading-6">
                Enter your details below
              </p>
            </div>
            <div className="py-8 flex flex-col gap-4">
              <Input
                required
                id="email_address"
                label="EMAIL ADDRESS"
                onIconClick={() => {}}
                value={emailAddress}
                placeholder="Enter your email"
                startIcon={<EmailIcon />}
                error={emailError !== ''}
                helperText={emailError}
                onBlur={validateEmail}
                onChange={(e) => {
                  setGroupIdMessageShowing(false);
                  setEmailAddress(e.target.value)
                }}
              />
            </div>
            <div className="space-y-4">
              <Button
                data-id="continue-button"
                className="w-full"
                variant="primary"
                onClick={
                  groupIdMessageShowing
                    ? () => setShowGroupIdModal(true)
                    : handleContinue
                }
                disabled={emailError !== '' || inProgress}
                loading={inProgress}
              >
                {
                  groupIdMessageShowing
                    ? 'Verify Group ID'
                    : 'Continue'
                }
              </Button>
              <div className="flex justify-center items-center gap-2">
                <div className="text-gray-800 text-sm font-normal leading-tight">
                  Already have an account?
                </div>
                <Link
                  data-testid="login-button"
                  className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                  to={ROUTE_PATH.LOGIN}
                >
                  Log in
                  <CircledArrowRightIcon />
                </Link>
              </div>
            </div>
          </div>
          <Dialog
            open={showGroupIdModal}
            onClose={() => setShowGroupIdModal(false)}
            closeOnOutsideClick={true}
            className="rounded-2xl"
          >
            <AccountGroupIdVerification
              className="df:md:max-w-[500px]"
              error={groupVerificationError}
              onChange={setGroupIdValue}
              onPrimary={async () => {
                await isGroupSelfEnroll(
                  groupIdValue,
                  (isSelfEnroll) => {
                    if (isSelfEnroll) {
                      signup(groupIdValue);
                    } else {
                      setGroupVerificationError(
                        'Sorry, the entered email does not match that Group ID.'
                      );
                    }
                  },
                  (reason) => {
                    setGroupVerificationError(
                      'Sorry, the entered email does not match that Group ID.'
                    );
                  }
                );
              }}
            />
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
