import React from 'react';
import { RadioList } from './RadioList';

type pronounProps = {
  pronounList: string[] | undefined;
};

const Pronoun: React.FunctionComponent<pronounProps> = ({ pronounList }): JSX.Element => (
  <div className="space-y-6">
    <div className="space-y-2 df:md:text-center">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-3xl
        "
      >
        What are your
        {' '}
        <span className="font-bold">pronouns</span>
        ?
      </p>
      <p className="text-gray-900 text-base df:md:hidden">
        Enter your details below
      </p>
    </div>
    <RadioList
      name="pronoun"
      options={pronounList}
    />
  </div>
);

export default Pronoun;
