import { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

import { QuestionDropdown, QuestionDropdownProps } from '@kindlyhuman/component-library';

import { User } from '../../../hooks/useUser';

export interface UserPlanProps extends HTMLAttributes<HTMLDivElement> {
  user: User;
}

export const UserPlan: FunctionComponent<UserPlanProps> = ({ user, className }) => {
  const packageProducts = user?.caller_role.active_subscription?.package.package_products;

  const { planName, planPrice, planDescription } = useMemo(() => {
    const minutesPerUser =
      packageProducts?.find((item: any) => item.configuration?.maximum_minutes_per_user)
        ?.configuration?.maximum_minutes_per_user || 0;
    const planPrice = 0;

    return {
      planName: user?.onUnlimitedPlan
        ? 'Unlimited'
        : user?.onCappedPlan
        ? `${minutesPerUser} minutes / month`
        : // NOT IN USE UNTIL STRUCTURE OF PAY PER MINUTE PLANS IS HARDENED ie.
          // Where is discount and/or cents_per_minute stored?
          'Pay Per Minute',
      planPrice: user?.onUnlimitedPlan || user?.onCappedPlan ? `$${planPrice} / month` : '',
      planDescription: user?.onUnlimitedPlan
        ? 'Your program covers all calls with Listeners.'
        : user?.onCappedPlan
        ? `${minutesPerUser} minutes of talk time with certified Peer Listeners each month.`
        : 'Pay for only the minutes you use.',
    };
  }, [user, packageProducts]);

  return (
    <div className={twMerge('space-y-4', className)}>
      <div className="text-gray-800 font-medium space-y-2">
        <p className="text-base leading-normal">{planName}</p>
        <p className="text-xl font-bold leading-loose">{planPrice}</p>
        <p className="text-sm leading-tight">{planDescription}</p>
      </div>
      {!!user?.caller_role.active_subscription?.package.benefits_description && (
        <UserPlanBenefits
          className="p-0 bg-transparent"
          details={user?.caller_role.active_subscription?.package.benefits_description || ''}
        />
      )}
    </div>
  );
};

export const UserPlanBalance: FunctionComponent<UserPlanProps> = ({ user, className }) => (
  <div className={twMerge('space-y-4 text-base leading-normal', className)}>
    <p className="text-gray-800 font-bold">Your balance</p>
    <p className="text-stone-500 font-medium">
      {user?.user_subscription?.remaining_minutes} credits
    </p>
  </div>
);

export const UserPlanRenewsDate: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
}) => (
  <div className={twMerge('flex justify-between items-center text-base leading-normal', className)}>
    <div className="text-gray-800 font-bold">Renews on</div>
    <div className="text-stone-500 font-medium">
      {moment().set('date', 1).add(1, 'M').format('MMMM D, YYYY')}
    </div>
  </div>
);

export interface UserPlanBenefitsProps extends Omit<QuestionDropdownProps, 'title' | 'body'> {
  details?: string;
}

export const UserPlanBenefits: FunctionComponent<UserPlanBenefitsProps> = ({ details = '', ...props }) => (
  <QuestionDropdown
    title="See benefits"
    body={
      <div
        className="
          [&>ul]:list-disc [&>ul]:list-image-blue_check [&>ul]:pl-[21px] [&>ul]:space-y-2
          [&>ul>li>span]:relative [&>ul>li>span]:top-[-4px]
        "
        dangerouslySetInnerHTML={{
          __html: details,
        }}
      />
    }
    {...props}
  />
);
