import React from 'react';
import { FormError, Input, Select, Toggle } from '@kindlyhuman/component-library';
import { useFormContext } from 'react-hook-form';
import { useAppSettings } from '../../../hooks/useAppSettings';

type phoneProps = {
  firstName?: string;
};

const PhoneNumber: React.FC<phoneProps> = ({ firstName }) => {
  const { data: appSettings } = useAppSettings();

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="space-y-6">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-center df:md:text-3xl
        "
      >
        Hi {firstName} what's your
        {' '}
        <br className="df:md:hidden" />
        <span className="font-bold">phone number</span>
        ?
      </p>
      <div>
        <div className="flex flex-col">
          <Input
            required
            {...register('mobile_phone')}
            type="text"
            id="mobile_phone"
            label="PHONE NUMBER"
            onIconClick={() => {}}
            placeholder="(123) 456-7890"
            error={Boolean(errors?.mobile_phone?.message)}
          />
          {errors?.mobile_phone?.message && (
            <FormError text={errors?.mobile_phone?.message as string} />
          )}
        </div>
        <p className=" text-gray-900 text-sm not-italic font-normal leading-5 pt-1.5 df:md:text-neutral-700">
          We use your phone number when we're setting up a call with a peer. No peers will see your
          number at any time.
        </p>
      </div>
      <div>
        <div className="flex flex-col">
          <label className="mb-3 text-xs font-manrope uppercase text-[#240089] font-medium">
            Receive Text Messages?
          </label>
        </div>
        <div className="flex pt-1.5 space-x-2">
          <p className="text-gray-900 text-sm not-italic font-normal leading-5">
            Is this a mobile phone that can receive text messages? We send text messages to remind you of scheduled
            calls.
          </p>
          <div>
            <Toggle
              checked={watch('is_text_compatible_phone') ?? true}
              {...register('is_text_compatible_phone')}
              onChange={(checked) => {
                setValue('is_text_compatible_phone', checked, { shouldDirty: true });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col">
          <Select
            {...register('timezone')}
            label="YOUR TIME ZONE"
            isRequired
            className={errors?.supported_timezones?.message && 'border border-red'}
            options={appSettings?.supported_timezones.map(item => ({
              value: item,
              label: item,
            })) ?? []}
          />
          <div className="text-gray-900 text-sm not-italic font-normal leading-5 pt-1.5 space-y-4 df:md:text-neutral-700">
            <p>
              We use your timezone when scheduling calls with our Peers. This should represent the
              timezone where you will normally be accessing the service.
            </p>
            <p>If the timezone shown above is incorrect, please select the correct timezone.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
