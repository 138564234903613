import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Switch, useLocation, useHistory, Route } from 'react-router-dom';
import {
  VerificationPage,
  HomePage,
  PeerDetails,
  Profile,
  MyPreferences,
  Resources,
  Help,
  Support,
  ResetPassword,
  Login,
  NotFound404,
  Counseling,
  Telemed,
  WelcomePage,
  ActiveCall,
  PostCallSequnece,
  MyPlan,
  SignupPage,
  OnboardingStepper,
  DependentsPage,
  AvailableListenersPage,
  SelectPlan,
} from '../containers';
import PrivateRoute from './privateRoute';
import { ROUTE_PATH } from './route-paths';
import MyPeersPage from '../containers/my-peers';
import RecommendedPeersPage from '../containers/recommended-peers';
import AboutUs from '../containers/about-us';
import AccountSettings from '../containers/account-settings';
import ChangePasswordPage from '../containers/change-password';
import MemberManagementPage from '../containers/member-management';
import FavoriteExperiencesPage from '../containers/favorite-experiences';
import { CallHistory } from '../containers/member-management/call-history';
import { PrivacyPolicyPage, TermsPage } from '../containers/terms-and-privacy-policy';
import RecommendedExperiencesPage from '../containers/recommended-experiences';
import { PaymentMethodPage } from '../containers/member-management/payment-method';
import { User, useUser } from '../hooks/useUser';
import { useNotifications } from '../hooks/useNotifications';
import { useClientCode } from '../hooks/useClientCode';
import rollbar from '../containers/rollbar';
import AreaExperiencesPage from '../containers/area-experiences';
import { ResourceDetailPage } from '../containers/resources/resource-detail';
import { Spinner } from '../components/common';

declare global {
  interface Window {
    branch: any;
  }
}

export interface BranchData {
  data: string; // "" if nothing is obtained
  data_parsed: DataParsed; // {} if nothing is obtained
  has_app: boolean; // false if nothing is obtained
  identity: any | null; // null if nothing obtained
  developer_identity: any | null; // null if nothing obtained
  referring_identity: any | null; // null if nothing obtained
  referring_link: string | null; // null if nothing obtained
}

export interface DataParsed {
  $listener_role_id?: number;
  engagement_id?: number;
  $password_reset_token: string;
  '~creation_source': number;
  '+click_timestamp': number;
  $deeplink_action: string;
  $user_id: number;
  $user_token: string;
  '+match_guaranteed': boolean;
  $fallback_url: string;
  $web_only: boolean;
  '~tags': string[];
  '+clicked_branch_link': boolean;
  $one_time_use: boolean;
  '~id': number;
  $user_phone_obfuscated: string;
  '+is_first_session': boolean;
  '~referring_link': string;
  $user_email_obfuscated: string;
  $user_display_name: string;
}

const useOnboardingNavigation = (userDetails?: User) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isPartial = userDetails?.is_partial && !userDetails?.needs_password;
    if (isPartial) {
      history.replace(ROUTE_PATH.ONBOARDING);
    }
    // adding pathname to this dep array because we want to check this everytime a user hits a page in the app.
  }, [userDetails, history, pathname]);
};

const useInitBranch = () => {
  const [branchInit, setBranchInit] = useState(false);
  useEffect(() => {
    if (!window?.branch || branchInit) {
      return;
    }
    window.addEventListener('load', () => {
      var options = { no_journeys: true };
      window.branch.init(process.env.REACT_APP_BRANCH_KEY, options, () => {
        setBranchInit(true);
      });
    });
  }, [setBranchInit, branchInit]);

  return branchInit;
};

const useBranchNavigation = () => {
  const [branchData, setBranchData] = useState<BranchData>();
  const branchInit = useInitBranch();
  const history = useHistory();
  const { authenticateWithBranchToken, data: user } = useUser();
  useEffect(() => {
    if (!window?.branch || !branchInit || branchData) {
      return;
    }
    // err comes back null if there was no branch redirect.
    window.branch.data((err: string, data: BranchData) => {
      if (err) {
        rollbar.error(`Error parsing branch link`, new Date(), {
          message: `Environment: ${process.env.REACT_APP_ENVTYPE}; Error: ${err}`,
        });
        return;
      }
      setBranchData(data);

      // The user is already authenticated, disregard smart link and redirect them to homepage or onboarding
      if (user?.is_partial) {
        history.replace(ROUTE_PATH.ONBOARDING);
        return;
      } else if (user) {
        history.replace(ROUTE_PATH.HOME);
        return;
      }

      // refetch password
      if (data.data_parsed.$password_reset_token) {
        localStorage.setItem('resetToken', data.data_parsed.$password_reset_token);
        history.push(ROUTE_PATH.RESET_PASSWORD);
      }

      // start onboarding flow
      if (data.data_parsed.$user_token) {
        authenticateWithBranchToken.mutate(
          {
            token: data.data_parsed.$user_token,
            userId: data.data_parsed.$user_id,
          },
          {
            onError: () => {
              sessionStorage.setItem(
                'partial_member_id',
                JSON.stringify(data.data_parsed.$user_id)
              );
              history.push(ROUTE_PATH.VERIFICATION);
            },
          }
        );
        history.replace(ROUTE_PATH.ONBOARDING);
      }
    });
  }, [history, authenticateWithBranchToken, branchInit, branchData, setBranchData, user]);

  return branchData;
};

const useGroupCodeNavigation = () => {
  const allowedURLS = ['/resource'];
  const { pathname } = useLocation();
  const groupId = pathname.split('/')[1];
  const isGroupId = Object.values(ROUTE_PATH).every((item) => {
    if (allowedURLS.some((url) => pathname.startsWith(url))) return false;
    return pathname !== item;
  });
  const history = useHistory();
  const { data: clientCode, isError: clientCodeError } = useClientCode({
    partnerCode: isGroupId ? groupId : undefined,
  });

  useEffect(() => {
    clientCodeError && history.replace(ROUTE_PATH['404']);
    if (clientCode) {
      sessionStorage.setItem('clientCode', groupId);
      history.replace(ROUTE_PATH.WELCOME_PAGE);
    }
  }, [clientCode, clientCodeError, groupId, history]);
};

const Routes: React.FunctionComponent = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const {
    data: user,
    authenticateWithSSOToken,
    isLoading: userLoading,
    fetchStatus: userFetchStatus,
  } = useUser();

  const ssoRedirect = new URLSearchParams(search).get('sso_redirect');

  const ssoAuthentication = useCallback(
    (ssoRedirect: string | null) => {
      if (
        ssoRedirect !== null &&
        authenticateWithSSOToken.data === undefined &&
        authenticateWithSSOToken.isIdle
      ) {
        authenticateWithSSOToken.mutate({ token: ssoRedirect });
      }
    },
    [authenticateWithSSOToken]
  );

  useNotifications();
  useBranchNavigation();

  useEffect(() => {
    ssoAuthentication(ssoRedirect);
  }, [ssoRedirect, ssoAuthentication]);

  useOnboardingNavigation(user);
  useGroupCodeNavigation();

  // this sends you to the top of the window when you change pages
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // if the user does not have an auth token we won't try to get the user, thus the fetch status will be idle.
  if (userFetchStatus !== 'idle' && userLoading) {
    return <Spinner />;
  }

  return (
    <Switch>
      {/* non authenticated routes */}
      <Route path={ROUTE_PATH.LOGIN} exact component={Login}></Route>
      <Route path={ROUTE_PATH.SIGNUP} exact component={SignupPage}></Route>
      <Route path={ROUTE_PATH.LANDING_PAGE} exact component={WelcomePage}></Route>
      <Route path={ROUTE_PATH.WELCOME_PAGE} exact component={WelcomePage}></Route>
      <Route path={ROUTE_PATH.RESET_PASSWORD} exact component={ResetPassword}></Route>
      <Route path={ROUTE_PATH.PLAN} exact component={MyPlan}></Route>
      <Route path={ROUTE_PATH.ONBOARDING} exact component={OnboardingStepper}></Route>
      <Route path={ROUTE_PATH.SELECT_PLAN} exact component={SelectPlan}></Route>
      <Route path={ROUTE_PATH.VERIFICATION} exact component={VerificationPage}></Route>

      {/* authenticated routes */}
      <PrivateRoute path={ROUTE_PATH.ActiveCall} exact component={ActiveCall}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.ONBOARDING} exact component={OnboardingStepper}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.HOME} exact component={HomePage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PEER_DETAILS} exact component={PeerDetails}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.DEPENDENTS} exact component={DependentsPage}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.FAVORITE_EXPERIENCES}
        exact
        component={FavoriteExperiencesPage}
      ></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.AVAILABLE_LISTENERS}
        exact
        component={AvailableListenersPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PAYMENT} exact component={PaymentMethodPage}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.ACCOUNT_SETTINGS}
        exact
        component={AccountSettings}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.MY_PEERS} exact component={MyPeersPage}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.RECOMMENDED_PEERS}
        exact
        component={RecommendedPeersPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.PROFILE} exact component={Profile}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.MY_PREFERENCES} exact component={MyPreferences}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.CHANGE_PASSWORD}
        exact
        component={ChangePasswordPage}
      ></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.MEMBERSHIP_MANAGEMENT}
        exact
        component={MemberManagementPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.RESOURCES} exact component={Resources}></PrivateRoute>
      <PrivateRoute
        path={`${ROUTE_PATH.RESOURCE}/:name`}
        exact
        component={ResourceDetailPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.HELP} exact component={Help} />
      <PrivateRoute path={ROUTE_PATH.SUPPORT} exact component={Support} />
      <PrivateRoute path={ROUTE_PATH.ABOUT} exact component={AboutUs}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.EXPERIENCES}
        exact
        component={RecommendedExperiencesPage}
      ></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.AREA_EXPERIENCES}
        exact
        component={AreaExperiencesPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.TERMS_AND_SERVICE} exact component={TermsPage}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.PRIVACY_POLICY}
        exact
        component={PrivacyPolicyPage}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.RETURN} exact component={HomePage}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.COUNSELING} exact component={Counseling}></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.TELEMED} exact component={Telemed}></PrivateRoute>
      <PrivateRoute
        path={ROUTE_PATH.POSTCALLSEQUENCE}
        exact
        component={PostCallSequnece}
      ></PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.CALL_HISTORY} exact component={CallHistory}></PrivateRoute>

      {/* 404 page */}
      <Route path={ROUTE_PATH['404']} component={NotFound404}></Route>
    </Switch>
  );
};

export default Routes;
