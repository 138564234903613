import React from 'react';
import { RadioList } from './RadioList';

type relationshipProps = {
  relationshipList: string[] | undefined;
};

const Relationship: React.FunctionComponent<relationshipProps> = ({
  relationshipList,
}): JSX.Element => (
  <div className="space-y-6">
    <div className="space-y-2 df:md:text-center">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-3xl
        "
      >
        What is your
        {' '}
        <span className="font-bold">relationship status</span>
        ?
      </p>
      <p className="text-gray-900 text-base df:md:hidden">
        Enter your details below
      </p>
    </div>
    <RadioList
      name="relationship"
      options={relationshipList}
    />
  </div>
);

export default Relationship;
