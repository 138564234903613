import React, { useEffect, useState } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { useHistory, useLocation } from 'react-router-dom';

import { Toggle } from '@kindlyhuman/component-library';

import { getRollBarEnvironment } from './containers/rollbar';

import { useFeatureFlag } from './hooks/useFeatureFlag';
import { useActiveCalls } from './hooks/useCalls';
import { useUser } from './hooks/useUser';
import { useMediaQueryWithDesktopFeature } from './hooks/useMediaQueryWithDesktopFeature';

import Routes from './routes';
import { ROUTE_PATH } from './routes/route-paths';

import { ActiveCallModal } from './components/common/CallCards/ActiveCallModal';

import './styles/main.css';

declare global {
  interface Window {
    MorphiiWidgets: any;
  }
}

// We want to load this in early so the collection shows up faster
export const morphiiContext = new window.MorphiiWidgets.Collection();

const App: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [showActiveCallModal, setShowActiveCallModal] = useState<boolean>(false);
  const [shownActiveCall, setShownActiveCall] = useState<(number | undefined)[]>([]);

  const {
    enabled: isMwaResponsiveDesignEnable,
    setEnabled: setIsMwaResponsiveDesignEnable,
  } = useFeatureFlag("MWA_RESPONSIVE_DESIGN");
  const { data: activeCalls } = useActiveCalls(useUser()?.data?.caller_role_id);
  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const rollbarConfig =
    window.location.origin.indexOf('localhost') !== -1
      ? {}
      : {
          accessToken: process.env.REACT_APP_ROLL_BAR_ACCESS_TOKEN,
          environment: getRollBarEnvironment(),
          code_version: '2.3.0',
        };

  /**
   * This useEffect defines situations when the user needs to show information about an active call
   * If the user uses a desktop, then a modal with information about an active call will be displayed
   * If the user uses mobile, then the user will be redirected to a page with information about an active call
   * Each of these actions is performed only once per call
   * If the user switches from desktop to mobile and the modal with information about the active call is open,
   *  the user will be redirected to the page with information about the active call
   */
  useEffect(
    () => {
      if (activeCalls?.length && !shownActiveCall.includes(activeCalls?.at(-1)?.id)) {
        setShownActiveCall(value => [...value, activeCalls?.at(-1)?.id]);
        if (dfMdMedia && pathname !== ROUTE_PATH.ActiveCall) {
          setShowActiveCallModal(true);
        }
        else {
          history.push(ROUTE_PATH.ActiveCall);
        }
      }
      if (!dfMdMedia && showActiveCallModal) {
        setShowActiveCallModal(false);
        history.push(ROUTE_PATH.ActiveCall);
      }
    },
    [activeCalls, dfMdMedia, history, pathname, shownActiveCall, showActiveCallModal]
  );

  return (
    <div className="App h-full" data-df={isMwaResponsiveDesignEnable}>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Routes />
          <ActiveCallModal
            open={showActiveCallModal}
            onClose={() => setShowActiveCallModal(false)}
            activeCall={activeCalls?.at(-1)}
          />
          {
            // TODO: Remove this block when isMwaResponsiveDesignEnable is no longer needed
            getRollBarEnvironment() === 'development' && (
              <label
                className="
                  fixed bottom-0 flex items-center p-2 bg-neutral-700 text-white z-200 transition-all cursor-pointer
                  right-[-280px] df:right-[-265px] hover:!right-0
                "
              >
                <span className="pr-2">
                  {'<-'}
                </span>
                <Toggle
                  checked={isMwaResponsiveDesignEnable}
                  onChange={() => setIsMwaResponsiveDesignEnable(!isMwaResponsiveDesignEnable)}
                  className="mr-4"
                />
                Responsive Design {isMwaResponsiveDesignEnable ? 'Enable' : 'Disabled'}
              </label>
            )
          }
        </ErrorBoundary>
      </Provider>
    </div>
  );
};

export default App;
