import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser, User } from './useUser';
import { Experience, FavoriteExperiencesTopicTag } from './useExperiences';
import { axiosGetV3, axiosGet, axiosPost } from '../api/axios-handler';

export const peersQueryKeys = {
  recommended: ['peerRecommended'] as const,
  favorites: ['peerFavorites'] as const,
  new: ['peerNew'] as const,
  available: ['peerAvailable'] as const,
  infiniteRecommended: ['infiniteRecommended'] as const,
  peerDetail: (peerID?: number) => ['peerDetail', ...(peerID ? [peerID] : [])] as const,
};

export interface PeerResponse {
  count: number;
  data: Peer[];
}

export interface Peer {
  about_me: string;
  challenge_areas: Tag[];
  display_name: string;
  first_name: string;
  is_available: boolean;
  is_listener: boolean;
  is_member_following: boolean;
  listener_role_id: number;
  profile_photo_url: string;
  profile_photo_url_square: string;
  tags: Omit<Tag, 'parent_id' | 'key'>[];
  subject_areas: Tag[];
}

export interface Tag {
  id: number;
  name: string;
  tag_type: string;
  parent_id: number;
  key: string;
}

interface PeerRequest {
  favorites?: boolean;
  limit?: number;
  page?: number;
}

export const getPeers = async (
  favorite?: boolean,
  limit?: number,
  page?: number
): Promise<PeerResponse> => {
  const data: PeerRequest = {};
  if (favorite !== undefined) {
    data['favorites'] = favorite;
  }
  if (limit !== undefined) {
    data['limit'] = limit;
  }
  if (limit !== undefined && page !== undefined) {
    data['page'] = page;
  }
  return await axiosGetV3(`/peers/recommended`, data).then((peerResponse) => peerResponse.data);
};

interface PeerHookOptions {
  limit?: number;
  page?: number;
}

interface AvailableListenersResponse {
  count: number;
  data: Peer[];
}

const getAvailableListeners = async (): Promise<AvailableListenersResponse> => {
  return await axiosGetV3('/listeners/available', null).then(
    (listenerResponse) => listenerResponse.data
  );
};

export const useAvailableListeners = () => {
  return useQuery<AvailableListenersResponse>(peersQueryKeys.available, () =>
    getAvailableListeners()
  );
};

export const useRecommendedPeers = (options?: PeerHookOptions) => {
  return useQuery<PeerResponse>(
    peersQueryKeys.recommended,
    () => getPeers(undefined, options?.limit),
    {
      staleTime: 1000 * 60 * 15,
    }
  );
};

export const getInfinitePeers = async (
  limit: number,
  page: number,
  favorite?: boolean
): Promise<PeerResponse> => {
  const data: PeerRequest = {
    limit,
    page,
  };
  if (favorite !== undefined) {
    data['favorites'] = favorite;
  }

  return await axiosGetV3(`/peers/recommended`, data).then((response) => {
    return {
      count: response.data.count,
      data: response.data.data,
      nextPage: response.data.count >= page * limit ? page + 1 : undefined,
    };
  });
};

export interface InfinitePeersQueryResults extends PeerResponse {
  nextPage?: number;
}

const PEER_PAGE_LIMIT = 10;
export const useInfinitePeers = () => {
  return useInfiniteQuery<InfinitePeersQueryResults>({
    queryKey: peersQueryKeys.infiniteRecommended,
    queryFn: ({ pageParam = 1 }) => getInfinitePeers(PEER_PAGE_LIMIT, pageParam, false),
    getNextPageParam: (currentPage) => {
      return currentPage.nextPage;
    },
  });
};

export const useFavoritedPeers = (options?: PeerHookOptions) => {
  return useQuery<PeerResponse>(peersQueryKeys.favorites, () => getPeers(true));
};

export const useNewPeers = (options?: PeerHookOptions) => {
  return useQuery<PeerResponse>(peersQueryKeys.new, () => getPeers(false, options?.limit), {
    staleTime: 1000 * 60 * 15,
  });
};

export interface PeerFavoriteResponse {
  id: number;
  type: string;
  listener_role_id: number;
  caller_role_id: number;
}

const addFavoritePeer = async (
  listenerRoleId: number,
  callerRoleId?: number
): Promise<PeerFavoriteResponse> => {
  const data = {
    caller_role_id: callerRoleId,
    listener_role_id: listenerRoleId,
    type: 'caller_of_listener',
  };

  return await axiosPost(`/favorites/`, data).then((favResponse) => {
    return favResponse.data;
  });
};

// TODO add some error handling etc...
export const useAddFavoritePeer = (queryKey: readonly unknown[]) => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  return useMutation({
    mutationFn: (listenerRoleId: number) => addFavoritePeer(listenerRoleId, user?.caller_role_id),
    onSuccess: (newFav, _variables, context) => {
      switch (queryKey[0]) {
        case peersQueryKeys.infiniteRecommended[0]:
          queryClient.setQueryData<{ pages: InfinitePeersQueryResults[] }>(
            peersQueryKeys.infiniteRecommended,
            (existingPeerData) => {
              const newData = existingPeerData?.pages.map((page) => {
                return {
                  count: page.count,
                  data: page.data.map((peer) =>
                    peer.listener_role_id === newFav.listener_role_id
                      ? { ...peer, is_member_following: true }
                      : peer
                  ),
                };
              });
              return {
                pages: newData ?? [],
              };
            }
          );
          break;
        case peersQueryKeys.peerDetail()[0]:
          queryClient.setQueryData<PeerDetail>(
            queryKey,
            (oldPeerDetail) => ({
              ...oldPeerDetail,
              favorite_id: newFav.id,
            } as PeerDetail)
          );
          break;
        default:
          queryClient.setQueryData<PeerResponse>(queryKey, (existingPeerData) => {
            return existingPeerData
              ? {
                  count: existingPeerData.count,
                  data: existingPeerData.data.map((peer) =>
                    peer.listener_role_id === newFav.listener_role_id
                      ? { ...peer, is_member_following: true }
                      : peer
                  ),
                }
              : { count: 0, data: [] };
          });
      }
    },
    onError: () => {
      queryClient.invalidateQueries(peersQueryKeys.infiniteRecommended);
      queryClient.invalidateQueries(peersQueryKeys.recommended);
    },
  });
};

// TODO consider leveraging the pattern here https://gitlab.com/listeners-on-call/listener-web-app/-/blob/develop/src/common/http/hooks/tags.tsx#L258
// so that we can encapsulate the toggling logic inside the hook.

const removeFavoritePeer = async (
  listenerRoleId: number,
  callerRoleId?: number
): Promise<number> => {
  return await axiosPost(
    `/peers/${listenerRoleId}/unfavorite`,
    {
      caller_role_id: callerRoleId,
    },
    'v3'
  ).then((_) => listenerRoleId); // the endpoint doesn't return anything but we'll echo listenerRoleId to the onSuccess handler
};

export const useRemoveFavoritePeer = (queryKey: readonly unknown[]) => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  return useMutation({
    mutationFn: (listenerRoleId: number) =>
      removeFavoritePeer(listenerRoleId, user?.caller_role_id),
    onSuccess: (listenerRoleId, _variables, context) => {
      switch (queryKey[0]) {
        case peersQueryKeys.infiniteRecommended[0]:
          queryClient.setQueryData<{ pages: InfinitePeersQueryResults[] }>(
            peersQueryKeys.infiniteRecommended,
            (existingPeerData) => {
              const newData = existingPeerData?.pages.map((page) => {
                return {
                  count: page.count,
                  data: page.data.map((peer) =>
                    peer.listener_role_id === listenerRoleId
                      ? { ...peer, is_member_following: false }
                      : peer
                  ),
                };
              });
              return {
                pages: newData ?? [],
              };
            }
          );
          break;
        case peersQueryKeys.peerDetail()[0]:
          queryClient.setQueryData<PeerDetail>(
            queryKey,
            (oldPeerDetail) => ({
              ...oldPeerDetail,
              favorite_id: null,
            } as PeerDetail)
          );
          break;
        default:
          queryClient.setQueryData<PeerResponse>(queryKey, (existingPeerData) => {
            return existingPeerData
              ? {
                  count: existingPeerData.count,
                  data: existingPeerData.data.map((peer) =>
                    peer.listener_role_id === listenerRoleId
                      ? { ...peer, is_member_following: false }
                      : peer
                  ),
                }
              : { count: 0, data: [] };
          });
      }
    },
    onError: () => {
      queryClient.invalidateQueries(peersQueryKeys.infiniteRecommended);
      queryClient.invalidateQueries(peersQueryKeys.recommended);
    },
  });
};

export interface Availability {
  day_of_week: string;
  end_date: string;
  ends_at: string;
  start_date: string;
  starts_at: string;
}

export interface PeerDetailsExperience extends Experience {
  file_url: string;
  topic_tags: FavoriteExperiencesTopicTag[];
  is_favorite: boolean;
}

export interface PeerDetail {
  available: boolean;
  availabilities: Availability[];
  available_now: boolean;
  background_traits: string[];
  can_take_calls: boolean;
  connection_blocked: boolean;
  favorite_id: number | null;
  id: number;
  is_active: boolean;
  is_peer_active: boolean;
  listener_audio: PeerDetailsExperience[];
  profile_photo_file_url: string;
  profile_photo_square_file_url: string;
  profile_traits: string[];
  secondary_language_tag: string;
  user: User;
  about_me: string;
  challenge_areas: Tag[];
  subject_areas: Tag[];
}

export interface PeerDetailResponse {
  data: PeerDetail[];
}

const getPeerDetail = async (peerId: number): Promise<PeerDetail> => {
  return await axiosGet(`/requests/available_listeners/${peerId}`, {
    include_timezone_offset: true,
  }).then((peerResponse) => peerResponse.data);
};

export const usePeerDetail = (peerId?: number) =>
  useQuery<PeerDetail>(peersQueryKeys.peerDetail(peerId), () => getPeerDetail(peerId!), {
    enabled: peerId !== undefined,
  });
