import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PlusIcon, Experience } from '@kindlyhuman/component-library';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';

import {
  FavoriteExperience,
  useFavoriteExperience,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import {
  useListenerAudioPlaybackMutation,
  useListenerAudioPlayMutation,
} from '../../hooks/useListenerAudio';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { ROUTE_PATH } from '../../routes/route-paths';

import { ExperienceSkeletonWide } from '../recommended-experiences/mobile';

export const FavoriteExperiencesMobile: React.FC<{
  favoriteExperiences?: FavoriteExperience[];
  isLoading?: boolean;
}> = ({ favoriteExperiences, isLoading }) => {
  const navigate = useHistory();

  return (
    <div className="pb-10 bg-neutral-700 bg-opacity-5 min-h-screen">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <div className="w-full df:md:max-w-7xl mx-auto df:md:px-6">
        <Header />
        <Body isLoading={isLoading} favoriteExperiences={favoriteExperiences} />
      </div>
    </div>
  );
};

const Header = () => (
  <div
    className="
      bg-neutral-700 bg-opacity-5 p-4 space-y-2
      df:md:bg-transparent df:md:p-0 df:md:mt-6 df:md:mb-8
    "
  >
    <p className="text-gray-800 text-2xl font-bold leading-loose">
      Favorite Experiences
    </p>
    <p className="text-stone-500 text-sm font-medium leading-tight">
      Listen to peers who made an impact
    </p>
  </div>
);

const Body: React.FC<{ favoriteExperiences?: FavoriteExperience[]; isLoading?: boolean }> = ({
  favoriteExperiences,
  isLoading,
}) => {
  const { getTagGroupByTagId } = useTagGroups();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();

  const [playerOpen, setPlayerOpen] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  return (
    <div className="flex flex-col items-center gap-3">
      <div className="w-full grid gap-3 df:md:grid-cols-2 df:lg:grid-cols-3 df:md:gap-6">
        {!isLoading
          ? favoriteExperiences?.map((experience) => (
            <Experience
              variant={dfMdMedia ? "desktopWide" : "wide"}
              className="bg-white"
              imageUrl={experience.listener_role.profile_photo_square_file_url}
              displayName={experience.listener_role.user.display_name}
              audioUrl={experience.file_url}
              caption={experience.excerpt ?? ''}
              favorited={experience.is_favorite}
              hideFollow
              // @ts-ignore
              areas={[
                ...new Set(experience.topic_tags?.map((x) => getTagGroupByTagId(x.tag.id)?.name)),
              ]}
              duration={experience.duration}
              onFollow={() => {
                if (experience.is_favorite) {
                  unfavoriteMutation.mutate({
                    tagId: experience.topic_tags[0].id,
                    listenerId: experience.listener_role_id,
                    audioId: experience.id,
                  });
                }
                else {
                  favoriteMutation.mutate({
                    tagId: experience.topic_tags[0].id,
                    listenerId: experience.listener_role_id,
                    audioId: experience.id,
                  });
                }
              }}
              playing={playerOpen === experience.id && playing}
              setPlaying={setPlaying}
              onPlay={() => {
                audioStopAll();
                setPlayerOpen(experience.id);
                playMutation.mutate(
                  {
                    audioId: experience.id,
                    listenerId: experience.listener_role_id,
                    tagIds: experience.topic_tags.map((tag) => tag.id),
                  },
                  {
                    onSuccess: (listenerAudioPlayback) => {
                      setCurrentPlaybackId(listenerAudioPlayback.id);
                    },
                  }
                );
              }}
              key={experience.id}
              openPlayer={playerOpen === experience.id}
              setOpenPlayer={setPlayerOpen}
              onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                const percent = Math.round(
                  (e.currentTarget.currentTime / experience.duration) * 100
                );
                // if the percent is divisible by 10
                if (percent % 10 === 0) {
                  currentPlaybackId &&
                    playBackMutation.mutate({
                      audioId: currentPlaybackId,
                      percentCompleted: percent,
                    });
                }
              }}
              available={experience.listener_role.available_now}
              isPeerListener={experience.listener_role.can_take_calls}
            />
          ))
          : [...(Array(6)).keys()].map(index => <ExperienceSkeletonWide key={index} />)
        }
      </div>
      <FindMoreExperiencesButton />
    </div>
  );
};

const FindMoreExperiencesButton = () => {
  return (
    <Link className="self-center items-center gap-1 inline-flex text-center" to={ROUTE_PATH.EXPERIENCES}>
      <div className="pt-8 self-center items-center gap-1 inline-flex">
        <div className="w-5 h-5 relative">
          <PlusIcon />
        </div>
        <div className="text-center text-violet-950 text-base font-bold leading-normal">
          Find more experiences
        </div>
      </div>
    </Link>
  );
};
