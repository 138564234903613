import { HTMLAttributes, FunctionComponent, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { LeftArrowIcon } from '@kindlyhuman/component-library';

export interface SubHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: ReactNode;
  subtitle?: ReactNode;
  onBackButtonClick?: () => void;
}

export const SubHeader: FunctionComponent<SubHeaderProps> = ({
  title,

  subtitle,
  onBackButtonClick,
  className,
}) => (
  <div className={twMerge('df:md:space-y-2', className)}>
    {
      onBackButtonClick && (
        <button
          onClick={onBackButtonClick}
          className="flex items-center gap-2 mb-4"
        >
          <LeftArrowIcon />
          Back
        </button>
      )
    }
    <p className="text-gray-800 text-2xl font-bold leading-loose">
      {title}
    </p>
    {subtitle && (
      <p className="text-stone-500 text-sm font-medium leading-tight">
        {subtitle}
      </p>
    )}
  </div>
);
