import { useMemo } from "react";
import moment from "moment";

import { Avatar, PhoneIcon } from "@kindlyhuman/component-library";

import { ActiveCall } from "../../../hooks/useCalls";
import { useMediaQueryWithDesktopFeature } from "../../../hooks/useMediaQueryWithDesktopFeature";
import { Skeleton } from "@mui/material";

type HistoryCallCardProps = {
  call: ActiveCall;
};

export const HistoryCallCard = ({ call }: HistoryCallCardProps) => {
  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const callTimeString = useMemo(
    () => {
      const callTime = moment(0).utc().add(call.logged_duration, 'seconds')
      const hours = callTime.hours()
      const minutes = callTime.minutes()
      const seconds = callTime.seconds()

      return `
        ${timeString(hours, 'h', true)}
        ${timeString(minutes, 'min')}
        ${hours || minutes ? timeString(seconds, 's', true) : `${seconds} s`}
      `.trim()

      function timeString(time: number, name: string, disablePlural: boolean = false) {
        return time ? `${time} ${name}${time > 1 && !disablePlural ? 's' : ''}` : ''
      }
    },
    [call.logged_duration]
  );
  const price = useMemo(
    () => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(call.caller_charged_amount / 100),
    [call.caller_charged_amount]
  )

  return (
    <div className="flex justify-start items-center gap-4 text-dark text-base font-bold">
      <Avatar
        image={call.listener_role.profile_photo_square_file_url}
        variant="normal"
        imgClassName={dfMdMedia ? 'h-12 w-12' : ''}
      />
      <div className="flex-grow space-y-1">
        <div className="flex justify-start items-center gap-1">
          <PhoneIcon width={20} height={20} color="#240089" />
          <p>
            {call.listener_role.user.display_name}
          </p>
        </div>
        <div className="flex justify-start items-center gap-2 text-sm font-medium">
          <p>
            {moment(call.created_at).format('D MMMM YYYY')}
          </p>
          <div className="w-1 h-1 bg-[#B3B3B3] rounded-full" />
          <p>
            {callTimeString}
          </p>
        </div>
      </div>
      <p>
        {price}
      </p>
    </div>
  );
};

export const HistoryCallCardSkeleton = () => (
  <div className="flex justify-start items-center gap-4 text-dark text-base font-bold">
    <Skeleton variant="circular" className="w-16 h-16 df:md:w-12 df:md:h-12" />
    <div className="flex-grow space-y-1">
      <div className="flex justify-start items-center gap-1">
        <PhoneIcon width={20} height={20} color="#240089" />
        <Skeleton className="w-16 h-6" />
      </div>
      <div className="flex justify-start items-center gap-2 text-sm font-medium">
        <Skeleton className="w-16 h-5" />
        <div className="w-1 h-1 bg-[#B3B3B3] rounded-full" />
        <Skeleton className="w-16 h-5" />
      </div>
    </div>
    <Skeleton className="w-16 h-6" />
  </div>
);
