import { useQuery } from '@tanstack/react-query';
import { axiosGetV3 } from '../api/axios-handler';

export const resourceKeys = {
  all: ['resources'] as const,
};

export interface Resource {
  client_id: number | null;
  description: string;
  id: number;
  identifier: string;
  image_url: string;
  name: string;
  notes: string;
  phone_number: string;
  sort_weight: number;
  status: string;
  url: string;
  url_description: string;
  subject_areas: number[] | null;
  challenge_areas: number[] | null;
  long_description?: string;
}

export interface ResourcesResponse {
  data: Resource[];
  count: number;
}

const getResources = async (): Promise<ResourcesResponse> => {
  return await axiosGetV3(`/resources/recommended`, {}).then(
    (resourceResponse) => resourceResponse.data
  );
};

export const useResources = () => {
  return useQuery<ResourcesResponse>(resourceKeys.all, () => getResources(), {
    staleTime: Infinity,
  });
};

const getResourceByID = async (id: number): Promise<Resource> => {
  return await axiosGetV3(`/resources/${id}`, {}).then(
    (resourceResponse) => resourceResponse.data
  );
};

export const useResourceByID = (id: number | undefined) => {
  return useQuery<Resource>(
    [...resourceKeys.all, id],
    () => id
      ? getResourceByID(id)
      : Promise.resolve({} as Resource),
    {
      staleTime: Infinity,
    }
  );
};
