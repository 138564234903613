import {
  useMemo,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useRef,
} from 'react';
import { useForm, FormState, FieldValues, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Input,
  FormError,
} from '@kindlyhuman/component-library';

import { normalizePhoneNumber } from '../common/normalize-phone-input';
import Toast from '../common/PopUpMessage';

import { useUser } from '../../hooks/useUser';
import { Dependent, useInviteDependents } from '../../hooks/useInviteDependents';

export interface InviteDependentsFormProps extends HTMLAttributes<HTMLFormElement> {
  topActionButton?: (formState: FormState<FieldValues>) => ReactNode
  bottomActionButton?: (formState: FormState<FieldValues>) => ReactNode
  onSuccessSubmitted?: () => void
}

export const InviteDependentsForm: FunctionComponent<InviteDependentsFormProps> = ({
  topActionButton,
  bottomActionButton,
  onSuccessSubmitted,
  className,
  ...props
}): JSX.Element => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const { data: user } = useUser();
  const { saveDependent } = useInviteDependents(user?.id);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isSubmitting, isValid, ...formState },
  } = useForm({
    resolver: yupResolver(inviteDependentsSchema()),
    mode: 'onTouched',
  });

  /**
   * We have the following problem - a common form to change something + different buttons how do we submit this form
   * There are two ways to solve this problem:
   *  1. Pass the function to the form. The form calls this function with the form state parameters. The function returns JSX
   *  2. We will get the state of the form via ref. We send the form through the methods we added in ref
   * I chose the first option because it is more intuitive and consistent than the second
   */
  const {
    topActionButtonElement,
    bottomActionButtonElement,
  } = useMemo(
    () => ({
      topActionButtonElement: topActionButton
        ? topActionButton({ errors, isDirty, isSubmitting, isValid, ...formState })
        : null,
      bottomActionButtonElement: bottomActionButton
        ? bottomActionButton({ errors, isDirty, isSubmitting, isValid, ...formState })
        : null,
    }),
    [topActionButton, bottomActionButton, errors, isDirty, isSubmitting, isValid, formState]
  );

  const submit: SubmitHandler<Partial<Dependent>> = ({
    first_name = '',
    last_name = '',
    mobile_phone = '',
    email_address = '',
  }) => {
    return new Promise(res => saveDependent.mutate(
      {
        first_name,
        last_name,
        mobile_phone,
        email_address,
      },
      {
        onSuccess: () => {
          Toast.success(
            `You have successfully sent an invitation to ${email_address}`,
            undefined,
            toastNotificationContainerRef?.current,
          );

          reset();

          if (onSuccessSubmitted) {
            onSuccessSubmitted();
          }

          res(null);
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.description;

          if (errorMessage) {
            Toast.error(
              `There was an error saving your dependent. ${
                errorMessage ? errorMessage : 'Please contact your administrator.'
              }`,
              undefined,
              toastNotificationContainerRef?.current,
            );
          }

          res(error);
        },
      }
    ));
  };

  return (
    <form onSubmit={handleSubmit(submit)} {...props}>
      <div ref={toastNotificationContainerRef} />
      {topActionButtonElement}
      <div className={className}>
        <Input
          label="FIRST NAME"
          type="text"
          placeholder="First Name"
          required={true}
          {...register('first_name')}
          onIconClick={() => {}}
          id="first_name"
          error={Boolean(errors?.first_name?.message)}
        />
        {errors?.first_name?.message && (
          <FormError text={errors?.first_name?.message as string} />
        )}
        <Input
          label="LAST NAME"
          type="text"
          placeholder="Last Name"
          required={true}
          {...register('last_name')}
          onIconClick={() => {}}
          id="last_name"
          error={Boolean(errors?.last_name?.message)}
        />
        {errors?.last_name?.message && (
          <FormError text={errors?.last_name?.message as string} />
        )}
        <Input
          label="EMAIL ADDRESS"
          type="email"
          placeholder="example@abc.com"
          required={true}
          {...register('email_address')}
          onIconClick={() => {}}
          id="email_address"
          error={Boolean(errors?.email_address?.message)}
        />
        {errors?.email_address?.message && (
          <FormError text={errors?.email_address?.message as string} />
        )}
        <Input
          label="PHONE NUMBER"
          type="text"
          required
          placeholder="(123) 456-7890"
          {...register('mobile_phone')}
          id="mobile_phone"
          onIconClick={() => {}}
          onChange={(e)=>{
            const value = e.target.value || "";
            setValue('mobile_phone', normalizePhoneNumber(value));
          }}
          error={Boolean(errors?.mobile_phone?.message)}
        />
        {errors?.mobile_phone?.message && (
          <FormError text={errors?.mobile_phone?.message as string} />
        )}
      </div>
      {bottomActionButtonElement}
    </form>
  );
};

function inviteDependentsSchema() {
  return yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required('First Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'Name must be 2 characters or more',
        name: 'Minimum Characters',
      }),
    last_name: yup
      .string()
      .trim()
      .required('Last Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'Last name must be 2 characters or more',
        name: 'Minimum Last Name Characters',
      }),
    email_address: yup
      .string()
      .trim()
      .email('Must be valid email')
      .required('Email is required.'),
    mobile_phone: yup
      .string()
      .trim()
      .required('Phone number is required')
  })
}
