import { Button } from '@kindlyhuman/component-library';
import { FormProvider, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { DescribeStep } from './steps/describe';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PostCallThankYouStep } from './steps/thank-you';
import { MorphiiStep } from './steps/morphii';
import { ConnectAgain } from './steps/connect-again';
import { FeedbackStep } from './steps/feedback';
import { PostCall, PostCallData, useUpdatePostCall } from '../../../hooks/usePostCall';
import { MouseEvent, ReactNode, Ref, useEffect, useMemo } from 'react';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { morphiiContext } from '../../../app';
import { MorphiiResult } from '../../morphii/morphii-widget';
import Toast from '../PopUpMessage';
import React from 'react';

export interface PostCallFormRef extends UseFormReturn {
  step: number;
  setStep: (step: number) => void;
}

export interface PostCallFormProps {
  setStep: React.Dispatch<React.SetStateAction<PostCallFormSteps>>;
  step: PostCallFormSteps;
  postCall?: PostCall;
  onCancel?: () => void;
  className?: string;
  formRef?: Ref<PostCallFormRef>;
}

export enum PostCallFormSteps {
  DESCRIBE,
  MORPHII,
  CONNECT_AGAIN,
  FEEDBACK,
  THANK_YOU,
}

const validationSchema = {
  [PostCallFormSteps.DESCRIBE]: yup.object({
    description: yup.string().notRequired(),
  }),
  [PostCallFormSteps.MORPHII]: yup.object({
    morphii: yup.string().notRequired(),
  }),
  [PostCallFormSteps.CONNECT_AGAIN]: yup.object({
    connectAgain: yup.boolean().notRequired(),
  }),
  [PostCallFormSteps.FEEDBACK]: yup.object({
    feedback: yup.string().notRequired(),
  }),
  [PostCallFormSteps.THANK_YOU]: yup.object(),
};

export const PostCallForm: React.FC<PostCallFormProps> = ({
  step,
  setStep,
  postCall,
  onCancel,
  className,
  formRef,
}) => {
  const postCallMutation = useUpdatePostCall();

  const methods = useForm({
    defaultValues: JSON.parse(sessionStorage.getItem(`postCallForm-${postCall?.id}`) || 'null') || {
      morphii: {} as MorphiiResult,
    },
    resolver: yupResolver(validationSchema[step]),
    mode: 'onChange',
  });

  const { handleSubmit, setValue } = methods;

  const submit: SubmitHandler<PostCallData> = async (data) => {
    if (postCall) {
      postCallMutation.mutate(
        { postCallId: postCall.id, payload: data },
        {
          onSuccess: () => {
            setStep(PostCallFormSteps.THANK_YOU);
          },
        }
      );
    }
  };

  const goToNextStep = async (event: MouseEvent) => {
    if (step === PostCallFormSteps.MORPHII) {
      morphiiContext.submit((errors: any, results: MorphiiResult[]) => {
        if (errors) {
          if (errors[0].error?.message === 'Morphii intensity required.') {
            Toast.error('Morphii intensity required.');
          } else {
            Toast.error('Something went wrong. If this problem persists please contact help.');
          }
        } else {
          setValue('morphii', results[0]);
          setStep(step + 1);
        }
      });
    }
    else {
      // The button type has time to change while the event pops up
      event.preventDefault();
      setStep(step + 1);
    }
  }

  const currentRenderStep = useMemo(
    () => {
      switch (step) {
        case PostCallFormSteps.DESCRIBE:
          return (
            <DescribeStep
              className="df:md:px-6 df:md:py-8"
              postCall={postCall}
            />
          );
        case PostCallFormSteps.MORPHII:
          return (
            <MorphiiStep
              className="df:md:px-6 df:md:py-8"
              postCall={postCall}
            />
          );
        case PostCallFormSteps.CONNECT_AGAIN:
          return (
            <ConnectAgain
              className="df:md:px-6 df:md:py-8"
              postCall={postCall}
            />
          );
        case PostCallFormSteps.FEEDBACK:
          return (
            <FeedbackStep
              className="df:md:px-6 df:md:py-8"
              postCall={postCall}
            />
          );
        case PostCallFormSteps.THANK_YOU:
          return (
            <PostCallThankYouStep
              className="df:md:px-6 df:md:py-8"
              onClose={onCancel}
            />
          );
        default:
          return null;
      }
    },
    [postCall, step, onCancel]
  );

  useEffect(
    () => {
      sessionStorage.removeItem(`postCallForm-${postCall?.id}`)
    },
    [postCall]
  )

  return (
    <FormProvider {...methods}>
      <form
        ref={() => {
          if (formRef) {
            const currentFormRef = {
              step,
              setStep,
              ...methods,
            };

            if (typeof formRef === "function") {
              formRef(currentFormRef);
            } else {
              // @ts-ignore
              formRef.current = currentFormRef;
            }
          }
        }}
        onSubmit={handleSubmit(submit)}
        className={twMerge('', className)}
      >
        {currentRenderStep}
        {
          step <= PostCallFormSteps.FEEDBACK && (
            <ButtonPanel
              className={twMerge(
                `
                  py-3 fixed bottom-0 left-0 w-full
                  df:md:p-6 df:md:justify-between df:md:border-t df:md:border-neutral-200
                `,
                onCancel && 'df:md:relative'
              )}
              onNextStep={step <= PostCallFormSteps.CONNECT_AGAIN ? goToNextStep : undefined}
              onCancel={onCancel}
              type={step <= PostCallFormSteps.CONNECT_AGAIN ? 'button' : 'submit'}
            />
          )
        }
        {
          step === PostCallFormSteps.THANK_YOU && onCancel && (
            <div
              className="hidden df:md:flex p-6 justify-end border-t border-neutral-200"
            >
              <Button variant="primary" onClick={onCancel}>
                Close
              </Button>
            </div>
          )
        }
      </form>
    </FormProvider>
  );
}

type ButtonPanelProps = {
  type?: 'submit' | 'button';
  onNextStep?: (event: MouseEvent) => void;
  onCancel?: () => void;
  className?: string;
};

export const ButtonPanel: React.FC<ButtonPanelProps> = ({ onNextStep, onCancel, type = 'button', className }) => {
  return (
    <div className={twMerge('flex justify-evenly', className)}>
      <Button onClick={onCancel || onNextStep} variant="secondary">
        <div className="w-30 df:md:w-auto">
          {
            onCancel
              ? 'Cancel'
              : 'Skip'
          }
        </div>
      </Button>

      <Button type={type} onClick={onNextStep} variant="primary">
        <div className="w-30 df:md:w-auto">{type === 'submit' ? 'Finish' : 'Next'}</div>
      </Button>
    </div>
  );
};

export interface SubheaderProps {
  question: ReactNode;
  postCall?: PostCall;
  className?: string;
}

export const Subheader: React.FC<SubheaderProps> = ({ question, postCall, className }) => (
  <div className={twMerge('font-manrope text-gray-800 space-y-2', className)}>
    <p className="text-2xl leading-loose">
      {question}
    </p>
    <p>
      {postCall && `${Math.floor(postCall?.call.logged_duration / 60)} minute call on `}{' '}
      {moment(postCall?.call.ended_at).format('MMMM DD, hh:mm a')}
    </p>
  </div>
);
