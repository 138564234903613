import { FunctionComponent, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export const AboutUsText: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ className }) => (
  <div
    className={twMerge('space-y-2 df:md:space-y-4 text-dark text-base font-medium', className)}
  >
    <p className="text-xl font-bold df:md:text-4xl">
      We believe in the power of humans connecting with humans.
    </p>
    <p>
      Society is facing an existential crisis of mental health, loneliness and isolation stemming
      from our devotion to technology as the answer to all of our problems. Ironically, most
      attempts to address this truly human issue rely on even more technology, hoping an app or bot
      can be the answer.
    </p>
    <p>
      We are going in a different direction.
    </p>
    <p>
      We believe authentic human connection for all kinds of humans is the way to make a positive
      impact on all of humankind.
    </p>
    <p>
      We’re on a mission to ensure that everyone knows they have support from real people with
      genuine empathy in whatever they may be facing. Because we believe the answer to feeling heard
      and understood is simple: Humans connecting with other humans is a game-changer.
    </p>
    <p>
      We hope you’ll join us as we redefine what it means to be truly connected in today’s culture
      of technology.
    </p>
  </div>
);
