import {
  Button,
  Dialog,
  DialogProps,
} from '@kindlyhuman/component-library';

interface UnsavedChangesModalProps extends DialogProps {
  onDiscard?: () => void;
  savingInProgress?: boolean;
}

export const UnsavedChangesModal = ({ className, onClose, onDiscard, savingInProgress, ...props }: UnsavedChangesModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      closeOnOutsideClick
      className="rounded-lg shadow-modal bg-whiteSmoke backdrop:bg-modalBackdropColor !m-auto"
      {...props}
    >
      <div className="w-80 h-30 p-8 flex-col justify-center items-center gap-4 inline-flex">
        <div className="flex flex-col">
          <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope leading-loose">
            Unsaved changes
          </div>
          <div className="text-center text-stone-500 font-manrope">
            If you leave page, any changes you have made will be lost
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <Button
          data-testid="modal-discard-button"
          onClick={onDiscard}
          type="button"
          variant="secondary"
          disabled={savingInProgress}
        >
          Discard
        </Button>
        <Button
          data-testid="modal-save-button"
          variant="primary"
          type="submit"
          loading={savingInProgress}
          disabled={savingInProgress}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};
