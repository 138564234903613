import { useEffect, useState } from 'react';
import { MobileHeader, DesktopHeader, Stepper } from '@kindlyhuman/component-library';
import { InterestsStep, PeersStep, ProfileStep } from '..';
import { useUser } from '../../hooks/useUser';
import { SetPassword } from './set-password';
import { twMerge } from 'tailwind-merge';

const OnboardingStepper = () => {
  const { data: user } = useUser();

  const [step, setStep] = useState(1);
  const [needsPassword, setNeedsPassword] = useState(true);

  const handleNext = () => {
    setStep(step + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ProfileStep onComplete={handleNext} />;
      case 2:
        return <InterestsStep onComplete={handleNext} />;
      case 3:
        return <PeersStep />;
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setNeedsPassword(user.needs_password);
  }, [user]);

  return (
    <>
      {needsPassword ? (
        <SetPassword />
      ) : (
        <div className="min-h-screen flex flex-col">
          <DesktopHeader className="hidden df:md:block relative z-10" />
          <MobileHeader className="df:md:hidden relative z-10" />
          <div className="items-center flex flex-col py-4 border-b border-[#E6E6E6] bg-white border-opacity-70 relative z-10">
            <Stepper currentStep={step} steps={['Profile', 'Interests', 'Peers']} />
          </div>
          <div
            className="
              pb-20 bg-white h-full grow overflow-x-hidden
              df:md:bg-whiteSmoke
            "
          >
            <div
              className={twMerge(
                'px-5 mx-auto mt-5 df:md:mt-20',
                step <= 2 ? 'df:md:max-w-160' : 'df:md:max-w-7xl df:md:mx-auto df:md:px-6'
              )}
            >
              {renderStep()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnboardingStepper;
