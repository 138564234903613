import { useMediaQuery } from '../../hooks/useMediaQuery';
import AccountSettingsMobile from './mobile';

const AccountSettings = () => {
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');
  return sm || md ? <AccountSettingsMobile /> : <AccountSettingsMobile />;
};

export default AccountSettings;
