import React from 'react';
import { RadioList } from './RadioList';

type genderProps = {
  genderList: string[] | undefined;
};

const Gender: React.FunctionComponent<genderProps> = ({ genderList }): JSX.Element => (
  <div className="space-y-6">
    <div className="space-y-2 df:md:text-center">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-3xl
        "
      >
        What is your
        {' '}
        <span className="font-bold">gender identity</span>
        ?
      </p>
      <p className="text-gray-900 text-base">
        Enter your details below
      </p>
    </div>
    <div className="space-y-4">
      <RadioList
        name="gender"
        options={genderList}
      />
      <p className="text-gray-900 text-sm df:md:text-neutral-700">
        We use this information to help recommend Peers, but it is not required.
      </p>
    </div>
  </div>
);

export default Gender;
