import React, { useState } from 'react';
import { Button, EyeIcon, MobileHeader, Input, LockIcon } from '@kindlyhuman/component-library';
import { useUser } from '../../hooks/useUser';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import Toast from '../../components/common/PopUpMessage';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Spinner } from '../../components/common/spinner';

const ResetPassword: React.FunctionComponent = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const { redeemPasswordRequest } = useUser();
  const [newPassword, setNewPassword] = useState<any>('');
  const [confirmPassword, setConfirmPassword] = useState<any>();

  const { isLoading } = useFeatureFlag("MWA_RESPONSIVE_DESIGN");

  const validatePassword = () => {
    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError('Password must be at least 8 characters long and contain a number');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handlePasswordReset = (): void => {
    const resetToken = localStorage.getItem('resetToken');
    const input = {
      token: resetToken,
      password: newPassword,
    };
    redeemPasswordRequest.mutate(input, {
      onSuccess: () => {
        Toast.success('Your password has been successfully changed! Please sign in with a new password');
      },
      onError: (error: any) => {
        const errorCode = error?.response?.data?.error_code;
        const errorMessage = error?.response?.data?.description;

        if (errorCode === 'NOT_FOUND') {
          Toast.error(errorMessage);
        }
        else if (errorMessage) {
          setPasswordError(errorMessage);
        }
      },
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-[#F6F6F6]">
      <MobileHeader
        className="df:md:hidden"
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:min-h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div className="p-5 df:md:px-15 df:lg:px-30 df:md:flex flex-col items-center justify-center">
          <div className="flex mt-8 flex-col self-stretch gap-1">
            <h2 className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
              Reset your Password
            </h2>
            <p className="text-gray-900 text-base not-italic font-normal leading-6">
              Enter your new password below
            </p>
          </div>
          <div className="py-8 flex flex-col gap-4 w-full">
            <Input
              id="newPassword"
              label={'New Password'}
              value={newPassword}
              placeholder="Enter your password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              onBlur={validatePassword}
              error={passwordError !== ''}
              type={showPassword ? 'text' : 'password'}
              helperText={passwordError}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowPassword(!showPassword)}
            />
            <Input
              id="confirmPassword"
              label={'Confirm new password'}
              value={confirmPassword}
              placeholder="Re-enter your password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              onBlur={validateConfirmPassword}
              error={confirmPasswordError !== ''}
              type={showConfirmPassword ? 'text' : 'password'}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              helperText={confirmPasswordError}
            />
          </div>
          <Button
            variant="primary"
            className="w-full"
            onClick={handlePasswordReset}
            disabled={redeemPasswordRequest.isLoading || passwordError !== '' || confirmPasswordError !== ''}
            loading={redeemPasswordRequest.isLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
