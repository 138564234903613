import React from 'react';
import { OptionRadio } from '@kindlyhuman/component-library';
import { Controller, useFormContext, ControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface RadioListProps extends Omit<ControllerProps, 'render'> {
  options: string[] | undefined;
  className?: string;
  radioClassName?: string;
};

export const RadioList: React.FunctionComponent<RadioListProps> = ({
  options,
  className,
  radioClassName,
  ...props
}): JSX.Element => {
  const { control } = useFormContext();

  return (
    <div
      className={twMerge(
        `
          grid grid-cols-1
          df:md:grid-cols-2 df:md:bg-neutral-200 df:md:border df:md:border-neutral-200 df:md:rounded-[10px]
          df:md:overflow-hidden df:md:gap-[1px]
        `,
        className
      )}
    >
      {options?.map((item: string) => (
        <Controller
          {...props}
          key={item}
          control={control}
          render={({ field: { value, onChange } }) => (
            <label htmlFor={item} className="cursor-pointer">
              <OptionRadio
                id={item}
                label={item}
                value={item}
                checked={value === item}
                onChange={() => onChange(item)}
                className={twMerge(
                  `
                    w-screen -translate-x-1/2 relative left-1/2
                    after:w-full after:h-full after:block after:bg-white after:absolute after:bottom-0
                    after:left-[calc(100%+1px)] after:pointer-events-none
                    df:md:w-full df:md:left-0 df:md:translate-x-0 df:md:bg-white df:md:border-0
                    df:md:after:content-['']
                  `,
                  radioClassName
                )}
              />
            </label>
          )}
          rules={{
            required: 'This field is required.',
          }}
        />
      ))}
    </div>
  );
};
