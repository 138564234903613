import { Button, MobileHeader, CircledArrowRightIcon, LeftArrowWithCircle, SpinnerIcon } from '@kindlyhuman/component-library';

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import './welcome-page.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import welcomeSlide1 from '../../assets/images/welcome-slide-1.jpg';
import welcomeSlide2 from '../../assets/images/welcome-slide-2.jpg';
import welcomeSlide3 from '../../assets/images/welcome-slide-3.jpg';
import welcomeSlide4 from '../../assets/images/welcome-slide-4.jpg';
import welcomeSlide5 from '../../assets/images/welcome-slide-5.jpg';
import welcomeSlide6 from '../../assets/images/welcome-slide-6.jpg';

import { EffectCreative } from 'swiper';
import { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useUser } from '../../hooks/useUser';
import { twMerge } from 'tailwind-merge';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const Slide: React.FC<{
  img: any;
  title: ReactNode;
  subtitle: ReactNode;
  description: ReactNode;
}> = ({ img, title, subtitle, description }) => {
  return (
    <div
      className="
        cursor-pointer pointer-events-none w-[335px] space-y-4
        df:md:cursor-auto df:lg:w-[470px] df:md:space-y-8
      "
    >
      <img src={img} alt="Welcome Slide" />
      <div className="text-center space-y-4 df:md:space-x-2">
        <div className="text-center text-violet-950 text-2xl font-bold leading-loose df:md:text-3xl">
          <div>{title}</div>
          <div>{subtitle}</div>
        </div>
        <div className="text-center text-gray-800 text-base font-normal leading-normal">
          {description}
        </div>
      </div>
    </div>
  );
};

interface NavigationDotIconProps extends HTMLAttributes<HTMLDivElement> {
  isActive?: boolean
}

const NavigationDotIcon = ({
  isActive,
  className,
  ...props
}: NavigationDotIconProps) => (
  <div
    {...props}
    className={twMerge(
      'w-3 h-3 rounded-full cursor-pointer',
      isActive
        ? 'bg-primary shadow-md shadow-[#25008a] border border-primary'
        : 'bg-neutral-200',
      className
    )}
  />
);

const WelcomePage = (): JSX.Element => {
  const history = useHistory();

  const [activeSlide, setActiveSlide] = useState(0);

  const { data: user } = useUser();
  const { isLoading: isFeatureFlagLoading } = useFeatureFlag("MWA_RESPONSIVE_DESIGN");

  const mobileSwiperRef = useRef<SwiperRef | undefined>();
  const desktopSwiperRef = useRef<SwiperRef | undefined>();

  /*
  This section is set up for the member pay addition. This is currently not used as we don't send the user to member pay until we turn it on
  */
  // const clientCode = sessionStorage.getItem('clientCode');
  // const { data: subscriptions } = useSubscriptions(clientCode);
  // const isMemberPay = clientCode && subscriptions?.length;

  const isSSO =
    user?.caller_role.active_subscription?.package.client.sso_configuration?.collect_password ===
    false;

  const getStartedLink = isSSO
    ? ROUTE_PATH.ONBOARDING : ROUTE_PATH.SIGNUP;

  if (user) {
    const isOnboarded = !user?.is_partial;
    isOnboarded && history.push(ROUTE_PATH.HOME);
  }

  const slides = [
    <Slide
      title="Hello!"
      subtitle="We’re glad you’re here."
      description="Kindly Human offers support and guidance for the everyday challenges we all face. You’re never alone in whatever you are facing."
      img={welcomeSlide1}
    />,
    <Slide
      title="We’re here to help."
      subtitle="Get started today."
      description="Select the topic that suits your needs and receive recommendations for connections and resources – immediately."
      img={welcomeSlide2}
    />,
    <Slide
      title="Hear from voices of "
      subtitle="experience."
      description="Our peers have been there. Listen to short stories from others who have experienced something similar to what you’re facing"
      img={welcomeSlide3}
    />,
    <Slide
      title="Connect now."
      subtitle=""
      description="Connect with a peer who is trained to offer support and empathy - anonymously. You can schedule a call at your convenience - even the same day."
      img={welcomeSlide4}
    />,
    <Slide
      title="Find the right resource."
      subtitle=""
      description="Connect with a resource or group specific to your situation. Explore and discover the right resource from our library of support."
      img={welcomeSlide5}
    />,
    <Slide
      title="Start. Hear. Today!"
      subtitle=""
      description="Create your account and be matched with peers you can connect with as soon as today. Say hello to a Kindly Human!"
      img={welcomeSlide6}
    />,
  ];

  if (isFeatureFlagLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <MobileHeader
        className="df:md:hidden"
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div className="flex flex-col items-center justify-center pb-16 df:md:pb-0">
          <div className="w-full">
            <Swiper
              className="hidden df:md:block"
              // @ts-ignore
              ref={desktopSwiperRef}
              simulateTouch={false}
              grabCursor={false}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.activeIndex);
              }}
            >
              {slides.map((item, index) => <SwiperSlide key={index}>{item}</SwiperSlide>)}
            </Swiper>
            <Swiper
              className="mySwiper df:md:hidden"
              // @ts-ignore
              ref={mobileSwiperRef}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.activeIndex);
              }}
              simulateTouch
              grabCursor
              effect="creative"
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }}
              modules={[EffectCreative]}
            >
              {slides.map((item, index) => <SwiperSlide key={index}>{item}</SwiperSlide>)}
            </Swiper>
            {
              activeSlide < slides.length - 1
                ? (
                  <div
                    className="
                      hidden max-w-[355px] mx-auto items-center justify-between
                      df:md:flex df:lg:max-w-[470px]
                    "
                  >
                    <Link
                      className="text-primary text-sm font-bold leading-tight"
                      to={getStartedLink}
                    >
                      Sign up
                    </Link>
                    <div className="space-x-2">
                      {
                        !!activeSlide && (
                          <button
                            className="rotate-180"
                            onClick={() => {
                              mobileSwiperRef?.current?.swiper?.slideTo(activeSlide - 1);
                              desktopSwiperRef?.current?.swiper?.slideTo(activeSlide - 1);
                            }}
                          >
                            <LeftArrowWithCircle />
                          </button>
                        )
                      }
                      {
                        activeSlide < slides.length - 1 && (
                          <button
                            onClick={() => {
                              mobileSwiperRef?.current?.swiper?.slideTo(activeSlide + 1);
                              desktopSwiperRef?.current?.swiper?.slideTo(activeSlide + 1);
                            }}
                          >
                            <LeftArrowWithCircle />
                          </button>
                        )
                      }
                    </div>
                  </div>
                )
                : (
                  <div className="hidden mx-auto items-center justify-center df:md:flex">
                    <Link to={getStartedLink}>
                      <Button variant="primary">Get started</Button>
                    </Link>
                  </div>
                )
            }
            <div className="flex items-center gap-y-8 flex-col df:md:hidden">
              <div className="flex justify-between w-40">
                {slides.map((_, index) => (
                  <NavigationDotIcon
                    key={index}
                    isActive={index === activeSlide}
                    onClick={() => {
                      mobileSwiperRef?.current?.swiper?.slideTo(index);
                      desktopSwiperRef?.current?.swiper?.slideTo(index);
                    }}
                  />
                ))}
              </div>
              <Link to={getStartedLink}>
                <Button variant="primary">Get started</Button>
              </Link>
            </div>
            <div className="flex justify-center items-center mt-8 gap-3">
              <div className="text-gray-800 text-sm font-normal leading-tight">
                Already have an account?
              </div>
              <Link
                data-testid="login-button"
                className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                to={ROUTE_PATH.LOGIN}
              >
                Log in
                <CircledArrowRightIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
