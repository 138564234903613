import { Dispatch, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';

import {
  ChevronDownIcon,
  Experience as ExperienceComponent,
  SpinnerIcon,
} from '@kindlyhuman/component-library';

import { MobileMenuHeader, SubHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { FilterBar } from '../../components/filter/FilterBar';
import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';

import { ROUTE_PATH } from '../../routes/route-paths';

import { TagGroup, useTagGroups } from '../../hooks/useTagGroups';
import { User } from '../../hooks/useUser';
import {
  RecommendedExperienceResponse,
  useFavoriteExperience,
  useUnfavoriteExperience,
} from '../../hooks/useExperiences';
import {
  useListenerAudioPlaybackMutation,
  useListenerAudioPlayMutation,
} from '../../hooks/useListenerAudio';
import { ResourcesResponse } from '../../hooks/useResources';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { ResourceSection } from '../peer-details/mobile';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import { twMerge } from 'tailwind-merge';
import { TextSearchModal } from '../../components/search-modal/search-modal';

export const RecommendedExperiencesMobile: React.FC<{
  showFilterMenu: boolean;
  setShowFilterMenu: Dispatch<React.SetStateAction<boolean>>;
  getTagGroupByName: (name: string) => TagGroup | undefined;
  handleFilterChange: ({ tag_group_ids, tag_ids }: TagFilters) => void;
  user?: User;
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
  fomHierarchy?: TagGroup[];
  resources?: ResourcesResponse;
  textSearchModal: boolean;
  textSearchQuery: UseQueryResult<RecommendedExperienceResponse, unknown>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  changeTextModalStatus: (status: boolean) => void;
}> = ({
  recommendedExperiencesQuery,
  user,
  handleFilterChange,
  getTagGroupByName,
  setShowFilterMenu,
  showFilterMenu,
  fomHierarchy,
  resources,
  textSearchModal,
  textSearchQuery,
  changeTextModalStatus,
  setQuery,
}) => {
  const navigate = useHistory();
  const textSearchFlag = useFeatureFlag(Features.TEXT_SEARCH);
  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <div className="pb-10 flex flex-col bg-neutral-700 bg-opacity-5 min-h-screen">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <FilterBar
        open={textSearchModal}
        openTextSearch={textSearchFlag.enabled ? changeTextModalStatus : undefined}
        setShowFilterMenu={setShowFilterMenu}
        getTagGroupByName={getTagGroupByName}
        handleFilterChange={handleFilterChange}
        user={user}
        className="df:md:mb-0 df:md:border-t-0 df:md:mt-0"
      />
      <div className="w-full df:md:max-w-7xl df:md:mx-auto df:md:px-6">
        <SubHeader
          className="p-4 pb-10 df:md:px-0 df:md:pt-6 df:md:pb-8"
          title="Experiences for you"
          subtitle="Recommended experiences from your Peers"
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <Body
          textSearchQuery={textSearchQuery}
          recommendedExperiencesQuery={recommendedExperiencesQuery}
        />
        {resources && <ResourceSection resources={resources} />}
      </div>
      <div className="pt-20" />
      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {textSearchModal && (
        <TextSearchModal
          search={(text) => {
            setQuery(text);
            changeTextModalStatus(false);
          }}
          onExit={() => changeTextModalStatus(false)}
        />
      )}
    </div>
  );
};

const Body: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
  textSearchQuery: UseQueryResult<RecommendedExperienceResponse, unknown>;
}> = ({ recommendedExperiencesQuery, textSearchQuery }) => {
  const experiences = textSearchQuery.data?.data
    ? textSearchQuery.data?.data
    : recommendedExperiencesQuery?.data?.pages.map((page) => page.data).flat();

  const [playerOpen, setPlayerOpen] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();

  const playBackMutation = useListenerAudioPlaybackMutation();
  const playMutation = useListenerAudioPlayMutation();
  const favoriteMutation = useFavoriteExperience();
  const unfavoriteMutation = useUnfavoriteExperience();
  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="w-full grid gap-3 df:md:grid-cols-2 df:lg:grid-cols-3 df:md:gap-6">
        {experiences
          ? experiences?.map((experience) => (
              <ExperienceComponent
                variant={dfMdMedia ? 'desktopWide' : 'wide'}
                hideFollow
                className="bg-white"
                imageUrl={experience.profile_photo_square_file_url}
                displayName={experience.name}
                audioUrl={experience.audio_url}
                caption={experience.excerpt}
                // @ts-ignore
                areas={experience.area_ids.map((x) => getParentByParentId(x).name)}
                favorited={experience.is_favorite}
                duration={experience.duration}
                onFollow={async () => {
                  if (experience.is_favorite) {
                    await unfavoriteMutation.mutateAsync({
                      tagId: 0,
                      listenerId: experience.listener_role_id,
                      audioId: experience.id,
                    });
                  } else {
                    await favoriteMutation.mutateAsync({
                      tagId: 0,
                      listenerId: experience.listener_role_id,
                      audioId: experience.id,
                    });
                  }

                  experience.is_favorite = !experience.is_favorite;
                }}
                playing={playerOpen === experience.id && playing}
                setPlaying={setPlaying}
                onPlay={() => {
                  audioStopAll();
                  setPlayerOpen(experience.id);
                  playMutation.mutate(
                    {
                      audioId: experience.id,
                      listenerId: experience.listener_role_id,
                    },
                    {
                      onSuccess: (listenerAudioPlayback) => {
                        setCurrentPlaybackId(listenerAudioPlayback.id);
                      },
                    }
                  );
                }}
                key={experience.id}
                openPlayer={playerOpen === experience.id}
                setOpenPlayer={setPlayerOpen}
                onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
                  const percent = Math.round(
                    (e.currentTarget.currentTime / experience.duration) * 100
                  );
                  // if the percent is divisible by 10
                  if (percent % 10 === 0) {
                    currentPlaybackId &&
                      playBackMutation.mutate({
                        audioId: currentPlaybackId,
                        percentCompleted: percent,
                      });
                  }
                }}
                available={experience.is_available}
                isPeerListener={experience.is_listener}
              />
            ))
          : [...Array(6).keys()].map((index) => (
              <ExperienceSkeletonWide key={index} className="w-full" />
            ))}
      </div>
      <div className="self-center py-8">
        <TextButton recommendedExperiencesQuery={recommendedExperiencesQuery} />
      </div>
    </div>
  );
};

const TextButton: React.FC<{
  recommendedExperiencesQuery?: UseInfiniteQueryResult<RecommendedExperienceResponse, unknown>;
}> = ({ recommendedExperiencesQuery }) =>
  recommendedExperiencesQuery?.hasNextPage ? (
    !recommendedExperiencesQuery?.isFetchingNextPage ? (
      <button
        onClick={() => recommendedExperiencesQuery?.fetchNextPage()}
        className="h-12 py-6 px-6 justify-start items-center gap-1 flex"
      >
        <div className="text-center text-violet-950 text-base font-bold leading-normal">
          See more
        </div>
        <ChevronDownIcon width={20} color="#24006B" />
      </button>
    ) : (
      <div className="w-full h-12 flex items-center justify-center">
        <SpinnerIcon />
      </div>
    )
  ) : (
    <div className="pt-12" />
  );

export interface ExperienceSkeletonWideProps {
  className?: string;
}

export const ExperienceSkeletonWide: React.FC<ExperienceSkeletonWideProps> = ({ className }) => (
  <div className={twMerge('flex flex-col px-4 py-4 bg-white', className)}>
    <div className="flex items-center">
      <Skeleton variant="circular" className="mr-6 h-10 w-10" />
      <Skeleton variant="text" className="w-12 h-5" />
    </div>
    <Skeleton className="h-12 w-full" />
    <Skeleton className="h-12 w-32" />
    <Skeleton className="h-12 w-full" />
  </div>
);
