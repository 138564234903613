import { useState } from 'react';

import { TagFilters } from '../../components/filter/FomSelectionFilter';

import {
  useInfiniteExperiences,
  useRecommendedExperiencesTextSearch,
} from '../../hooks/useExperiences';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useUser } from '../../hooks/useUser';
import { useResources } from '../../hooks/useResources';

import { RecommendedExperiencesMobile } from './mobile';

const RecommendedExperiencesPage = () => {
  const experiences = useInfiniteExperiences();
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const { data: user, updateTagPreferences } = useUser();
  const { data: fomHierarchy, getTagGroupByName } = useTagGroups();
  const { data: resources } = useResources();
  const [textSearchModal, setTextSearchModalStatus] = useState(false);
  const [query, setQuery] = useState('');

  const textSearchQuery = useRecommendedExperiencesTextSearch({
    query,
    enabled: query.trim() !== '',
  });
  const changeTextModalStatus = (open: boolean) => {
    setTextSearchModalStatus(open);
  };

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
    setQuery('');
  };

  return (
    <RecommendedExperiencesMobile
    setQuery={setQuery}
      textSearchModal={textSearchModal}
      textSearchQuery={textSearchQuery}
      changeTextModalStatus={changeTextModalStatus}
      resources={resources}
      fomHierarchy={fomHierarchy}
      handleFilterChange={handleFilterChange}
      recommendedExperiencesQuery={experiences}
      showFilterMenu={showFilterMenu}
      setShowFilterMenu={setShowFilterMenu}
      user={user}
      getTagGroupByName={getTagGroupByName}
    />
  );
};

export default RecommendedExperiencesPage;
