import React from 'react';

import { Spinner } from '../../components/common';

import { useUser } from '../../hooks/useUser';
import { useResources } from '../../hooks/useResources';
import { useExternalProviderPlan } from '../../hooks/useExternalProviderPlans';

import { ResourcePageMobile } from './mobile';

const Resources: React.FunctionComponent = (): JSX.Element => {
  const { data: user } = useUser();
  const { data: resources, isLoading: loadingResources } = useResources();
  const { data: providerPlans, isLoading: loadingProviderPlan } = useExternalProviderPlan();

  if (loadingProviderPlan || loadingResources) return <Spinner />;

  const providerPlan = providerPlans?.find((plan) => {
    return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
  });

  return <ResourcePageMobile providerPlan={providerPlan} clientResourcesData={resources} />;
};

export default Resources;
