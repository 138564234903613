import { LogoWideIcon } from '@kindlyhuman/component-library';
import welcomeDesktopBg from '../../assets/images/welcome_desktop_bg.png';
import { twMerge } from 'tailwind-merge';
import { FC } from 'react';

export interface WelcomeSloganProps {
  className?: string
}

export const WelcomeSlogan:FC<WelcomeSloganProps> = ({ className }) => (
  <div className={twMerge('bg-[#081D40] px-20 pt-12 pb-20', className)}>
    <LogoWideIcon letterColor="white" />
    <div
      className="w-[calc(50vw-13rem)] h-[calc(50vw-13rem)] mx-auto relative bg-cover bg-no-repeat mt-28 max-w-180 max-h-180"
      style={{
        backgroundImage: `url(${welcomeDesktopBg})`,
      }}
    >
      <p
        className="
          text-xl font-medium font-['Bitter'] text-[#F4F2F9] w-[110%]
          absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
          df:lg:text-4xl df:lg:leading-[48px]
        "
      >
        We believe in the power of humans connecting with humans.
        <br />
        We’re all human.{' '}
        <span className="text-[#AFFF54]">
          Be kindly.
        </span>
      </p>
    </div>
  </div>
);
