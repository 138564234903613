import { useLocation } from 'react-router-dom';
import { useInfiniteExperiences } from '../../hooks/useExperiences';
import { useTagGroups } from '../../hooks/useTagGroups';
import { AreaExperiencesMobile } from './mobile';
import { useResources } from '../../hooks/useResources';

const AreaExperiencesPage = () => {
  const { data: tagGroups, isLoading } = useTagGroups();
  const { data: resources } = useResources();
  const areaName = useLocation().search.slice(1);
  const tagGroupId = tagGroups?.find((tagGroup) => tagGroup.name === areaName)?.id;
  const experiences = useInfiniteExperiences(tagGroupId, !isLoading);

  return (
    <AreaExperiencesMobile
      areaName={areaName}
      resources={resources}
      recommendedExperiencesQuery={experiences}
    />
  );
};

export default AreaExperiencesPage;
