import { FunctionComponent, HTMLAttributes, ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { FieldValues, FormState, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge } from 'tailwind-merge';

import {
  FormError,
  Select,
  Textarea,
} from '@kindlyhuman/component-library';

import { useUser } from '../../hooks/useUser';
import { useSupportRequest } from '../../hooks/useSupport';

import { FormBlock } from './form_block';

type SupportCategoryType = {
  label: string,
  group: string,
}

const supportCategories: Record<string, SupportCategoryType> = {
  suggestion: {
    label: 'Suggestion',
    group: 'PRODUCT',
  },
  payment_concern: {
    label: 'Payment Concern',
    group: 'FINANCE',
  },
  call_connection: {
    label: 'Call Connection Issue',
    group: 'TECHNOLOGY',
  },
  listener_praise: {
    label: 'Listener Praise',
    group: 'FEEDBACK',
  },
  listener_message_concern: {
    label: 'Listener Message Concern',
    group: 'REPORT USER',
  },
  listener_call_concern: {
    label: 'Listener Call Concern',
    group: 'REPORT USER',
  },
  // training: {
  //   label: 'Training',
  //   group: 'ACADEMY',
  // },
  // payment: {
  //   label: 'Payment Concern',
  //   group: 'FINANCE',
  // },
  // caller_message_concern: {
  //   label: 'Caller Message Concern',
  //   group: 'REPORT USER',
  // },
  // caller_praise: {
  //   label: 'Caller Praise',
  //   group: 'FEEDBACK',
  // },
  // block_caller: {
  //   label: 'Block Caller',
  //   group: 'BLOCK',
  // },
  // caller_call_concern: {
  //   label: 'Caller Call Concern',
  //   group: 'REPORT USER',
  // },
};

const callerMenuReasons = [
  'suggestion',
  'payment_concern',
  'call_connection',
  'listener_praise',
  'listener_message_concern',
];

const callerOfCallReasons = [
  'listener_praise',
  'listener_call_concern',
  'suggestion',
  'call_connection',
];

export interface SupportFormProps extends HTMLAttributes<HTMLFormElement> {
  topActionButton?: (formState: FormState<FieldValues>) => ReactNode
  bottomActionButton?: (formState: FormState<FieldValues>) => ReactNode
  onSuccessSubmitted?: () => void
  option?: 'menu' | 'call-report' | string | null
  callReport?: string | null
  callId?: string | null
  contactUs?: string | null
}

export const SupportForm: FunctionComponent<SupportFormProps> = ({
  topActionButton,
  bottomActionButton,
  onSuccessSubmitted,
  option,
  callReport,
  callId,
  contactUs,
  className,
  ...props
}) => {
  const { data: user } = useUser();
  const supportRequestMutation = useSupportRequest();

  const {
    register,
    handleSubmit,
    reset,
    formState,
  } = useForm({
    resolver: yupResolver(supportSchema()),
    mode: 'onTouched',
  });
  const { errors } = formState;

  /**
   * We have the following problem - a common form to change something + different buttons how do we submit this form
   * There are two ways to solve this problem:
   *  1. Pass the function to the form. The form calls this function with the form state parameters. The function returns JSX
   *  2. We will get the state of the form via ref. We send the form through the methods we added in ref
   * I chose the first option because it is more intuitive and consistent than the second
   */
  const { topActionButtonElement, bottomActionButtonElement } = useMemo(
    () => ({
      topActionButtonElement: topActionButton
        ? topActionButton(formState)
        : null,
      bottomActionButtonElement: bottomActionButton
        ? bottomActionButton(formState)
        : null,
    }),
    [topActionButton, bottomActionButton, formState]
  );
  const { supportCategoryOptions, context } = useMemo(
    () => {
      return {
        supportCategoryOptions: (
          option === 'call-report'
            ? callerOfCallReasons
            : callerMenuReasons
        ).filter(item => callReport
          ? ['listener_praise', 'call_connection', 'listener_call_concern'].includes(item)
          : true
        ),
        context: option === 'call-report' ? 'caller_of_call' : 'menu',
      }
    },
    [option, callReport]
  )

  const submit: SubmitHandler<Partial<{
    supportCategory: string,
    comments: string,
  }>> = ({
    supportCategory = '',
    comments = '',
  }) => {
    if (user?.id) {
      if (option === 'call-report') {
        localStorage.setItem('call-report-submit', callId ? callId : '0');
      }

      return new Promise(res => supportRequestMutation.mutate(
        {
          userId: user?.id,
          input: {
            userId: user?.id,
            group: supportCategories[supportCategory].group,
            subject: supportCategories[supportCategory].label,
            context: contactUs === 'login' ? contactUs : context,
            comments: comments,
            call_id: callId ? callId : undefined,
            platform: 'User Agent',
            // TODO: inject useful metadata see https://listenersoncall.atlassian.net/browse/LOC-3110
            build: Math.floor(Math.random() * 1_000_000_000_000),
          },
        },
        {
          onSuccess: () => {
            reset();
            res(null);

            if (onSuccessSubmitted) {
              onSuccessSubmitted();
            }
          }
        }
      ))
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} {...props}>
      {topActionButtonElement}
      <div className={twMerge('', className)}>
        <FormBlock>
          <Select
            {...register('supportCategory')}
            label="SUPPORT CATEGORY"
            isRequired
            className={errors?.supportCategory?.message && 'border border-red'}
            options={supportCategoryOptions.map(item => ({
              label: supportCategories[item].label,
              value: item,
            })) || []}
          />
          {errors?.supportCategory?.message && (
            <FormError text={errors?.supportCategory?.message as string} className="mt-3" />
          )}
        </FormBlock>
        <Textarea
          {...register('comments')}
          className="mt-4 max-md:rounded-none max-md:border-l-0 max-md:border-r-0"
          placeholder="Please, provide context and any details that will help us address your support need."
          hasError={!!errors?.comments?.message}
          minRows={7}
          maxRows={12}
        />
        {errors?.comments?.message && (
          <FormError text={errors?.comments?.message as string} className="mt-3" />
        )}
      </div>
      {bottomActionButtonElement}
    </form>
  );
};

function supportSchema() {
  return yup.object().shape({
    supportCategory: yup
      .string()
      .trim()
      .required('Support category is required.'),
    comments: yup
      .string()
      .trim()
      .required('Message cannot be empty.'),
  })
}
