import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosGet, axiosGetV3, axiosPost } from '../api/axios-handler';
import Toast from '../components/common/PopUpMessage';

export const experiencesQueryKeys = {
  recommended: ['recommendedExperiences'] as const,
  infiniteRecommended: ['infinitieExperiences'] as const,
};

export interface RecommendedExperienceResponse {
  count: number;
  data: Experience[];
}

export interface Experience {
  duration: number;
  excerpt: string;
  audio_url: string;
  id: number;
  listener_audio_id: number;
  listener_role_id: number;
  name: string;
  profile_photo_square_file_url: string;
  score: number;
  title: string;
  is_available: boolean;
  is_listener: boolean;
  is_favorite: boolean;
  area_ids: number[];
}

export interface FavoriteExperience {
  created_at: string;
  duration: number;
  excerpt?: string;
  file_url: string;
  first_approved_at?: string;
  id: number;
  is_favorite: boolean;
  last_updated_at: string;
  listener_role: ListenerRole;
  listener_role_id: number;
  message_quality: number;
  title: string;
  topic_tags: FavoriteExperiencesTopicTag[];
}

export interface ListenerRole {
  about_me?: string;
  available: boolean;
  available_now: boolean;
  background_traits: any[];
  can_take_calls: boolean;
  connection_blocked: boolean;
  favorite_id?: number;
  id: number;
  is_active: boolean;
  is_peer_active: boolean;
  military_tag: any;
  next_available_text?: string;
  peer_status: string;
  primary_language_tag: string;
  profile_photo_file_url: string;
  profile_photo_square_file_url: string;
  profile_traits: string[];
  secondary_language_tag: string;
  share_link_url: string;
  spirituality_tag: string;
  state: string;
  status: string;
  tier_id: number;
  type: any;
  user: User;
}

export interface User {
  display_name: string;
  first_name: string;
  pronoun?: string | null;
}

export interface FavoriteExperiencesTopicTag {
  created_at: string;
  id: number;
  is_approved: boolean;
  listener_audio_id: number;
  media: any;
  postcall_metadata_id: any;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: any;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: Medum[];
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface Medum {
  file_key: string;
  file_url: string;
}

const RECOMMENDED_EXPERIENCES_LIMIT = 12;
const getRecommendedExperiences = async ({
  tagGroupId,
  pageParam,
}: {
  tagGroupId?: number;
  pageParam: number;
}): Promise<InfiniteExperienceQueryResults> => {
  return await axiosGetV3('/listener_audio/recommended', {
    tag_group_ids: tagGroupId,
    limit: RECOMMENDED_EXPERIENCES_LIMIT,
    page: pageParam,
  }).then((response) => {
    return {
      count: response.data.count,
      data: response.data.data,
      nextPage:
        response.data.count >= pageParam * RECOMMENDED_EXPERIENCES_LIMIT
          ? pageParam + 1
          : undefined,
    };
  });
};

const getFavoriteExperiences = async (): Promise<FavoriteExperience[]> => {
  return await axiosGet('/listeners/audio?is_favorite=true', {}).then((response: { data: any; }) => response.data);
};

export interface TextSearchResponse {
  caller_role_id: number;
  created_at: string;
  id: number;
  query: string;
}

export const useRecommendedExperiencesTextSearch = ({
  query,
  enabled,
}: {
  query: string;
  enabled: boolean;
}) =>
  useQuery<RecommendedExperienceResponse>(
    ['textSearchExperiences', query],
    () =>
      axiosGetV3('/listener_audio/search', { query, limit: 20 }).then(
        (response: { data: any; }) => response.data
      ),
    {
      enabled,
      onError: (err) => {
        // @ts-ignore
        Toast.error(err.response.data.description);
      },
    }
  );

export const useFavoriteExperiences = () =>
  useQuery<FavoriteExperience[]>(['favoriteExperiences'], () => getFavoriteExperiences());

export const useExperiences = () => {
  const recommendedExperiences = useQuery<RecommendedExperienceResponse>(
    experiencesQueryKeys.recommended,
    () => getRecommendedExperiences({ pageParam: 1 }),
    {
      staleTime: 1000 * 60 * 15,
    }
  );

  return { recommendedExperiences };
};

interface InfiniteExperienceQueryResults extends RecommendedExperienceResponse {
  nextPage?: number;
}

export const useInfiniteExperiences = (tagGroupId?: number, fetchAutomatically: boolean = true) =>
  useInfiniteQuery<InfiniteExperienceQueryResults>({
    queryKey: experiencesQueryKeys.infiniteRecommended,
    queryFn: ({ pageParam = 1 }) => getRecommendedExperiences({ pageParam, tagGroupId }),
    getNextPageParam: (currentPage) => {
      return currentPage.nextPage;
    },
    enabled: fetchAutomatically,
  });

export const useFavoriteExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      tagId: tag_id,
      listenerId,
      audioId,
    }: {
      tagId?: number;
      listenerId: number;
      audioId: number;
    }) => {
      return axiosPost(
        `/listeners/${listenerId}/audio/${audioId}/favorite`,
        tag_id
          ? {
              tag_id,
            }
          : null,
        'v2'
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['favoriteExperiences']);
      },
    }
  );
};

export const useUnfavoriteExperience = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      tagId: tag_id,
      listenerId,
      audioId,
    }: {
      tagId?: number;
      listenerId: number;
      audioId: number;
    }) => {
      return axiosPost(
        `/listeners/${listenerId}/audio/${audioId}/unfavorite`,
        tag_id
          ? {
              tag_id,
            }
          : null,
        'v2'
      );
    },
    {
      onSuccess: (data, { audioId }) => {
        /*
          here I'm manually setting is_favorite to false on success so we keep the item in the list.
          this way if they accidently unfavorited they can refavorite it easily
        */
        queryClient.setQueryData(['favoriteExperiences'], (oldFavoritesData: any) =>
          oldFavoritesData?.filter(
            (oldExperience: FavoriteExperience) => oldExperience.id !== audioId
          )
        );
      },
    }
  );
};
