import { useEffect, useRef, useState } from 'react';
import { WelcomeImg } from '../../../assets';
import { PlayerIcon } from '../../../components/common/svgs';
import { Button } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

interface VideoElement extends HTMLVideoElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
  webkitEnterFullscreen?: () => void;
}

const WelcomeVideo = () => {
  const domain = process.env.REACT_APP_DOMAIN_NAME;

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const videoFullScreenBoxRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const videoPlayerRef = useRef<VideoElement>() as React.MutableRefObject<VideoElement>;

  const playVideo = () => {
    const elem = videoPlayerRef?.current;
    const box = videoFullScreenBoxRef?.current;

    if (elem) {
      if (isMobile) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          if (!box?.hasChildNodes()) {
            box.appendChild(elem);
          }
          box.style.display = 'block';
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
          elem.webkitEnterFullscreen();
        }
      }
      else {
        setShowVideo(true);
      }

      elem.play();
    }
  };

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    return () => {
      document.removeEventListener('fullscreenchange', () => {});
      document.removeEventListener('mozfullscreenchange', () => {});
      document.removeEventListener('MSFullscreenChange', () => {});
      document.removeEventListener('webkitfullscreenchange', () => {});

      removeChildFromBox();
    };
  }, []); // eslint-disable-line

  const exitHandler = () => {
    const elem = videoPlayerRef?.current;
    const box = videoFullScreenBoxRef?.current;

    if (!document['fullscreenElement']) {
      if (
        //@ts-ignore
        !document['webkitIsFullScreen'] &&
        //@ts-ignore
        !document['mozFullScreen'] &&
        !document['msFullscreenElement']
      ) {
        // Run code on exit
        box.style.display = 'none';
        if (elem) elem.pause();
      }
    }
  };

  const videoTimeUpdate = () => {
    const currentTimeUpdate = videoPlayerRef.current.currentTime;
    const duration = videoPlayerRef.current.duration;

    if (currentTimeUpdate === duration && isMobile) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  const removeChildFromBox = () => {
    const box = videoFullScreenBoxRef?.current;

    if (box?.hasChildNodes()) {
      box.removeChild(box.children[0]);
    }
  }

  return (
    <>
      <div
        ref={videoFullScreenBoxRef}
      />
      <div
        className={twMerge(
          'px-7.5 df:md:max-w-screen-xsm df:md:mx-auto df:md:px-6',
          showVideo && 'df:md:max-w-screen-xl flex flex-col items-center justify-center h-[calc(100vh-300px)]'
        )}
      >
        <div
          className={twMerge(
            'w-full relative rounded-md',
            !showVideo
              ? 'h-124'
              : 'h-full'
          )}
        >
          <video
            ref={videoPlayerRef}
            className={twMerge(
              'w-full rounded-md',
              !showVideo
                ? 'h-52 absolute -z-1'
                : 'h-full mx-auto'
            )}
            id="myvideo"
            controls
            onClick={playVideo}
            role="button"
            playsInline
            onTimeUpdate={videoTimeUpdate}
          >
            <source src={`${domain}/video/welcome_video`} />
          </video>
          {
            !showVideo && (
              <>
                <div className="absolute z-10 bottom-0 onbording-welcome-video-bg w-full h-42 rounded-t-md"></div>
                <div className="absolute z-10 bottom-0 w-full h-42 text-white px-8 pt-4 rounded-t-md">
                  <h3 className="font-extrabold text-2xl">Hear, together.</h3>
                  <h6 className="text-lg font-normal w-36 leading-6 py-2">Welcome to Kindly, Human</h6>
                </div>
                <div
                  className={`w-full h-124 absolute z-20 items-center flex justify-center`}
                  role="button"
                  onClick={playVideo}
                >
                  <div className="w-20 h-20">
                    <PlayerIcon className="text-primary w-20 h-20" />
                  </div>
                </div>
                <img src={WelcomeImg} alt="welcomeImage" className="w-full h-full rounded-md" />
              </>
            )
          }
        </div>
        <div className="flex justify-center mt-9">
          <Button variant="primary">
            <Link data-testid="start-hear-button" to={'/home'}>Start. Hear.</Link>
          </Button>
        </div>
      </div>
    </>
  );
};
export default WelcomeVideo;
