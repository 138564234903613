import { SpinnerIcon } from '@kindlyhuman/component-library';
import { useAppSettings } from '../../../../hooks/useAppSettings';

import { TabHeader } from '../tab_header';

export const PrivacyPolicyTab = () => {
  const { data: settings, isLoading: isSettingsLoading } = useAppSettings();

  return (
    <>
      <TabHeader
        header="Privacy Policy"
      />
      {
        isSettingsLoading
          ? (
            <div className="w-full h-full flex items-center justify-center">
              <SpinnerIcon />
            </div>
          )
          : (
            <div
              className="
                py-4 space-y-2
                [counter-reset:listCounter]
                [&>ul]:list-disc [&>ul]:pl-4
                [&>ol>li]:before:content-[counter(listCounter)_'._'] [&>ol>li]:before:[counter-increment:listCounter]
              "
              dangerouslySetInnerHTML={{
                __html: settings?.PRIVACY_POLICY ?? '',
              }}
            />
          )
      }
    </>
  );
};
