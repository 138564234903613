import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { ToggleWithLabel, Button } from '@kindlyhuman/component-library';
import { User, useUser } from '../../hooks/useUser';
import { useForm, SubmitHandler } from 'react-hook-form';
import Toast from '../../components/common/PopUpMessage';
import { FormBlock } from '../../components/common/form_block';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';

export interface NotificationsProfileData {
  notifications_listener_online: boolean;
  notifications_check_ins: boolean;
}

const MyPreferences: React.FC = (): React.JSX.Element => {
  const { data: user, updateUser } = useUser();
  const navigate = useHistory();
  const [saveChangesModal, setSaveChangesModal] = useState(false);

  const generateDefaultValues = (user: User | undefined): Partial<NotificationsProfileData> => ({
    notifications_listener_online: user?.caller_role?.notifications_listener_online,
    notifications_check_ins: user?.caller_role?.notifications_check_ins,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: React.useMemo(() => {
      return generateDefaultValues(user);
    }, [user]),
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      reset(generateDefaultValues(user));
    }
  }, [user, reset]);

  const submit: SubmitHandler<Partial<NotificationsProfileData>> = async (data) => {
    await updateUser.mutateAsync(
      {
        caller_role: {
          ...user?.caller_role!,
          notifications_listener_online: data.notifications_listener_online!,
          notifications_check_ins: data.notifications_check_ins!,
        },
      }
    );

    Toast.success('Profile updated successfully');

    if (saveChangesModal) {
      setSaveChangesModal(false);
      navigate.goBack();
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DesktopMenuHeader />
      <MobileMenuHeader
        prompt={true}
        onBack={() => {
          isDirty ? setSaveChangesModal(true) : navigate.goBack();
        }}
      />
      <div className="min-h-screen w-full bg-neutral-700 bg-opacity-5 space-y-4">
        <Header />
        <FormBlock>
          <ToggleWithLabel
            header="My Listeners"
            subHeader="Receive reminders when my Listeners are on-call"
            checked={watch('notifications_listener_online') ?? false}
            {...register('notifications_listener_online')}
            onChange={(checked: boolean) => {
              setValue('notifications_listener_online', checked, { shouldDirty: true });
            }}
          />
        </FormBlock>
        <FormBlock>
          <ToggleWithLabel
            header="Reflection Reminders"
            subHeader="Allow occasional reminders to take a moment and reflect"
            checked={watch('notifications_check_ins') ?? false}
            {...register('notifications_check_ins')}
            onChange={(checked: boolean) => {
              setValue('notifications_check_ins', checked, { shouldDirty: true });
            }}
          />
        </FormBlock>
        {isDirty ? (
          <div className="fixed bottom-0 left-0 right-0 py-4 bg-white border-t border-neutral-200">
            <div className="flex items-center justify-center">
              <Button variant="primary" type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
                Save Changes
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
        <UnsavedChangesModal
          open={saveChangesModal}
          onClose={() => {
            setSaveChangesModal(false);
          }}
          onDiscard={() => navigate.goBack()}
          savingInProgress={isSubmitting}
        />
        {/* <Dialog
          open={saveChangesModal}
          onClose={() => setSaveChangesModal(false)}
          closeOnOutsideClick={true}
          className="rounded-2xl backdrop:bg-gray-400"
        >
          <div className="w-80 h-30 p-8 flex-col justify-center items-center gap-4 inline-flex">
            <div className="flex flex-col">
              <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope leading-loose">
                Unsaved changes
              </div>
              <div className="text-center text-stone-500 font-manrope">
                If you leave page, any changes you have made will be lost
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <Button onClick={() => navigate.goBack()} type="button" variant="secondary">
              Discard
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Dialog> */}
      </div>
    </form>
  );
};

const Header = () => (
  <div className="self-stretch text-gray-800 text-2xl font-bold leading-loose px-4 pt-4 text-center">
    Notifications
  </div>
);

export default MyPreferences;
