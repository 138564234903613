import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { axiosGetV3 } from '../api/axios-handler';

export const featureFlagKeys = {
  featureFlags: ['featureFlags'] as const,
};

export interface FeatureFlags {
  count: number;
  data: { [key: string]: FeatureFlag };
}

export interface FeatureFlag {
  created_at: Date;
  description: string;
  enabled: boolean;
  id: number;
  key: string;
}

// NOTE: this is manually synced (AKA copypasta) from app/models/feature_flags.py
export enum Features {
  DISPLAY_NAME_VALIDATION = 'DISPLAY_NAME_VALIDATION',
  DISABLE_HIGH_VOLUME_REPORTING = 'DISABLE_HIGH_VOLUME_REPORTING',
  MWA_RESPONSIVE_DESIGN = 'MWA_RESPONSIVE_DESIGN',
  CLIENT_FEATURE_FLAG = 'CLIENT_FEATURE_FLAG',
  TEXT_SEARCH = 'TEXT_SEARCH',
}

const getFeatureFlags = async (): Promise<FeatureFlags> => {
  return await axiosGetV3(`/feature_flags/me`, {}).then(
    (response: AxiosResponse<FeatureFlags>) => response.data
  );
};

export const useFeatureFlag = (key: keyof typeof Features) => {
  const queryClient = useQueryClient();
  const query = useQuery<FeatureFlags>(featureFlagKeys.featureFlags, () => getFeatureFlags(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
  });

  return {
    ...query,
    enabled: !!query.data?.data[key]?.enabled,
    setEnabled: (newValue: Boolean) => {
      queryClient.setQueryData(['featureFlags'], {
        ...query.data,
        data: {
          ...query.data?.data,
          [key]: {
            ...query.data?.data?.[key],
            enabled: newValue,
          },
        },
      });
    },
  };
};
