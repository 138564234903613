export const PlayerIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="currentColor" />
      <path fill="#FFF" d="m11.9 10.128 8.39 4.786-8.487 4.957z" />
    </g>
  </svg>
);

export const CloseIcon = (props: any) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.36842 2.36842L15.6316 15.6316"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M15.6316 2.36842L2.36842 15.6316"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export const ExitIcon = ({ width = 20, height = 20, color = '#fff', ...rest }) => (
  <svg
    {...rest}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47 4.47a.75.75 0 011.06 0L10 8.94l4.47-4.47a.75.75 0 111.06 1.06L11.06 10l4.47 4.47a.75.75 0 11-1.06 1.06L10 11.06l-4.47 4.47a.75.75 0 01-1.06-1.06L8.94 10 4.47 5.53a.75.75 0 010-1.06z"
      fill={color}
    />
  </svg>
);

export const PluseIcon = (props: any) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.24 17.48V0.519999H10.76V17.48H8.24ZM0.8 10.2V7.84H18.2V10.2H0.8Z"
      fill="currentColor"
    />
  </svg>
);

export const RightIcon = (props: any) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 5.16L4.75 9L11 1" stroke="currentColor" stroke-width="2" />
  </svg>
);

export const LogoText = (props: any) => (
  <svg
    width="228"
    height="117"
    viewBox="0 0 228 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4697 49.4451H18.3429V19.8658H13.4697V13.3353H30.9366V19.7561H25.6253V34.2439L39.9164 19.7561H35.0432V13.3353H53.8242V19.8658H49.2795L37.3429 31.6097L48.2392 49.4451H53.879V55.9756H43.6398L31.7579 36.5487L25.6253 42.5853V49.4451H30.9366V55.9756H13.4697V49.4451Z"
      fill="currentColor"
    />
    <path
      d="M57.438 50.1585H61.7637V33.5853H57.438V27.6036H68.8271V50.1585H73.0432V56.0305H57.438V50.1585Z"
      fill="currentColor"
    />
    <path
      d="M59.6282 18C59.6282 15.4207 61.8732 13.3353 64.4467 13.3353C66.9654 13.3353 69.1009 15.4207 69.1009 18C69.1009 20.5792 66.8559 22.6646 64.2824 22.6646C61.7637 22.6646 59.6282 20.5792 59.6282 18Z"
      fill="currentColor"
    />
    <path
      d="M87.6628 32.1585C90.4006 29.5244 93.4121 27.1097 97.5735 27.1097C100.914 27.1097 103.378 28.4817 104.911 30.7317C105.896 32.2683 106.389 34.2439 106.389 36.5488V50.1585H110.605V55.9756H95.438V50.1585H99.3256V38.3049C99.3256 37.1524 99.1066 36.1097 98.7233 35.3963C98.0663 34.189 96.9164 33.5305 95.219 33.5305C92.755 33.5305 90.1815 34.9573 87.8818 36.8232V50.2134H91.7694V56.0305H76.657V50.1585H80.8732V33.5854H76.657V27.6036H87.6628V32.1585Z"
      fill="currentColor"
    />
    <path
      d="M111.372 41.9268C111.372 33.6951 117.34 27.0549 124.951 27.0549C128.565 27.0549 131.248 28.4268 132.836 29.7988V14.4878H128.565V8.5061H139.899V50.1585H144.115V55.9756H132.836V52.8476C130.755 54.8781 128.127 56.4695 124.458 56.4695C117.012 56.5244 111.372 50.4878 111.372 41.9268ZM132.89 47.5793V35.3963C131.029 34.0793 128.839 33.2012 126.156 33.2012C122.213 33.2012 118.654 36.2744 118.654 41.872C118.654 47.3049 121.994 50.4329 126.156 50.4329C128.784 50.4329 130.919 49.2805 132.89 47.5793Z"
      fill="currentColor"
    />
    <path
      d="M146.524 50.1585H150.905V14.4878H146.524V8.5061H157.968V50.1585H162.184V55.9756H146.524V50.1585Z"
      fill="currentColor"
    />
    <path
      d="M168.919 68.9268C165.141 68.9268 162.841 66.7317 162.841 64.4268C162.841 62.0122 164.484 60.8049 166.346 60.8049C168.043 60.8049 168.974 61.8475 169.467 62.9451C170.179 62.7256 170.945 62.2866 171.712 61.2439C172.15 60.6402 172.533 59.9268 173.026 59.0488L175.161 54.5488L165.086 33.5305H161.527V27.6036H176.147V33.2561H171.986L178.501 48.2378L185.127 33.2561H180.746V27.6036H195.311V33.5853H191.862L178.611 61.7927C177.954 63.1097 177.242 64.2622 176.421 65.25C174.34 67.6646 171.767 68.9268 168.919 68.9268Z"
      fill="currentColor"
    />
    <path
      d="M186.769 63.0548C190.548 60.0366 192.464 57.128 192.573 55.4268C190.493 54.878 189.069 53.2317 189.069 50.9268C189.069 48.6768 191.15 46.5914 193.723 46.5914C196.352 46.5914 198.597 48.4573 198.597 52.2439C198.597 55.7561 196.078 61.0792 188.631 65.3597L186.769 63.0548Z"
      fill="#AFFF54"
    />
    <path
      d="M13.4697 97.1342H18.3429V67.5549H13.4697V61.0244H30.9366V67.5549H25.6253V78.8598H45.2824V67.5549H39.9712V61.0244H57.4928V67.5549H52.6196V97.1342H57.4928V103.665H40.0259V97.1342H45.3372V85.2805H25.6253V97.1342H30.9366V103.665H13.4697V97.1342Z"
      fill="currentColor"
    />
    <path
      d="M61.8732 100.427C60.9423 98.9451 60.4495 96.9695 60.4495 94.7195V81.2195H56.2334V75.2378H67.5129V92.9634C67.5129 94.1707 67.732 95.1036 68.1152 95.8719C68.7723 97.0793 69.9222 97.7378 71.6196 97.7378C74.0835 97.7378 76.657 96.311 78.9567 94.4451V81.2195H74.5763V75.2378H85.9654V97.7927H90.1815V103.61H79.1758V99.0549C76.438 101.689 73.4265 104.104 69.2651 104.104C65.8155 104.213 63.2968 102.787 61.8732 100.427Z"
      fill="currentColor"
    />
    <path
      d="M93.7953 97.8476H98.0115V81.2195H93.7953V75.2378H104.801V79.7378C106.991 77.3232 110.112 74.7439 114.328 74.7439C117.395 74.7439 119.64 75.8963 121.063 77.8171C121.447 78.311 121.72 78.8598 121.994 79.4634C124.622 76.8841 127.798 74.7988 131.467 74.7988C135.409 74.7988 138.147 76.2256 139.571 78.8598C140.337 80.2866 140.72 82.0976 140.72 84.1829V97.8476H144.937V103.665H129.879V97.8476H133.712V85.8293C133.712 84.7317 133.602 83.9085 133.274 83.1951C132.671 81.8232 131.522 81.1646 129.934 81.1646C127.579 81.1646 125.115 82.5366 122.98 84.3476V97.8476H126.813V103.665H112.029V97.8476H115.916V85.8293C115.916 84.7317 115.807 83.9085 115.478 83.1951C114.876 81.8232 113.781 81.1646 112.138 81.1646C109.784 81.1646 107.32 82.5366 105.184 84.3476V97.8476H108.962V103.665H93.9596V97.8476H93.7953Z"
      fill="currentColor"
    />
    <path
      d="M147.51 96.2013C147.51 90.8781 152.164 87.6403 158.899 87.6403C161.801 87.6403 164.539 88.0245 166.072 88.4086V86.1586C166.072 84.4574 165.689 83.1952 164.977 82.2623C163.827 80.7805 161.911 80.1769 159.392 80.1769C158.187 80.1769 157.256 80.3964 156.599 80.6159C156.709 81.0001 156.873 81.6586 156.873 82.0427C156.873 84.183 155.176 85.7196 153.04 85.7196C150.905 85.7196 149.262 84.2379 149.262 81.8781C149.262 78.3659 152.821 74.6891 160.542 74.6891C165.415 74.6891 168.7 76.061 170.726 78.5306C172.205 80.3415 172.916 82.811 172.916 85.6647V97.7927H177.133V103.61H166.182V100.482C163.937 102.403 160.487 104.104 156.654 104.104C151.836 104.213 147.51 101.524 147.51 96.2013ZM166.072 96.5305V92.0305C164.429 91.6464 162.458 91.372 160.268 91.372C156.818 91.372 154.738 92.9086 154.738 95.5427C154.738 97.7927 156.435 99.0549 158.954 99.0549C161.473 99.0549 163.937 98.122 166.072 96.5305Z"
      fill="currentColor"
    />
    <path
      d="M191.752 79.7927C194.49 77.1585 197.501 74.7439 201.663 74.7439C205.003 74.7439 207.467 76.1159 209 78.3659C209.986 79.9024 210.478 81.878 210.478 84.1829V97.7927H214.695V103.61H199.527V97.7927H203.415V85.939C203.415 84.7866 203.196 83.7439 202.813 83.0305C202.156 81.8232 201.006 81.1646 199.308 81.1646C196.844 81.1646 194.271 82.5915 191.971 84.4573V97.8476H195.859V103.665H180.692V97.8476H184.908V81.2195H180.692V75.2378H191.697V79.7927H191.752Z"
      fill="currentColor"
    />
    <path
      d="M214.202 79.189H214.914V75.8414H213.709V76.5H213.107V75.2378H217.378V76.5H216.72V75.8414H215.516V79.189H216.173V79.7927H214.147V79.189H214.202ZM217.816 79.189H218.473L218.637 75.8963H217.98V75.2927H219.458L220.772 78.2561L222.141 75.2927H223.62V75.8963H222.908L223.072 79.189H223.784V79.7927H221.922V79.189H222.47L222.36 76.1707L220.937 79.2439H220.608L219.184 76.1707L219.075 79.189H219.622V79.7927H217.761V79.189H217.816Z"
      fill="currentColor"
    />
  </svg>
);
