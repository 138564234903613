import { CircledArrowRightIcon, Button } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../../routes/route-paths';
import welcomeSlide6 from '../../../../assets/images/welcome-slide-6.jpg';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface PostCallThankYouStepProps {
  className?: string;
  onClose?: () => void;
}

export const PostCallThankYouStep: React.FC<PostCallThankYouStepProps> = ({ className, onClose }) => (
  <div className={twMerge('df:md:space-y-8', className)}>
    <img
      className="hidden mx-auto df:md:block"
      src={welcomeSlide6}
      alt="Thank you!"
    />
    <div className="text-center font-manrope space-y-2 mt-32 df:md:mt-0">
      <p className="text-violet-950 text-2xl font-bold leading-loose">
        Thank you!
      </p>
      <p className="text-gray-800 text-base font-normal leading-normal">
        Your feedback submitted successfully
      </p>
      <Link
        className={twMerge(
          'block !mt-12',
          onClose && 'df:md:hidden'
        )}
        to={ROUTE_PATH.HOME}
        replace
      >
        <Button variant="primary">Close</Button>
      </Link>
    </div>
    <SupportLinks
      className="
        flex items-center justify-evenly fixed bottom-0 w-full mb-16
        df:md:justify-between df:md:relative df:md:mb-0
      "
    />
  </div>
);

export interface SupportLinksProps {
  className?: string;
}

const SupportLinks: React.FC<SupportLinksProps> = ({ className }) => (
  <div className={twMerge('text-violet-950 text-base font-semibold font-manrope leading-normal', className)}>
    <Link
      className="flex justify-start items-center gap-1"
      to={ROUTE_PATH.HELP}
    >
      Contact support
      <CircledArrowRightIcon />
    </Link>
    <Link
      className="flex justify-start items-center gap-1"
      to={ROUTE_PATH.HELP}
    >
      Report call
      <CircledArrowRightIcon />
    </Link>
  </div>
);
