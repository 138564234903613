import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { useAvailableListeners } from '../../hooks/usePeers';
import { PeerCard, SpinnerIcon } from '@kindlyhuman/component-library';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useTagGroups } from '../../hooks/useTagGroups';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

// TODO: This page is very similar to other peer listings pages.
// They should be consolidated into a single PeerListing page into which a list of peers is injected.
const AvailableListenersPage = () => {
  const navigate = useHistory();

  const [scheduleModal, setScheduleModal] = useState<number>();

  const { data: availableListeners, isLoading: isAvailableListenersLoading } = useAvailableListeners();
  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <>
      <div className="bg-neutral-700 bg-opacity-5 min-h-screen flex flex-col">
        <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
        <DesktopMenuHeader />
        <div className="w-full df:md:max-w-7xl df:md:mx-auto df:md:px-6 df:md:pb-10">
          <SubHeader
            title="Peers Available to Connect"
            subtitle="Talk now or schedule a time that's convenient"
            className="px-4 pt-5 pb-7 df:md:px-0 df:md:pt-6 df:md:pb-8"
            onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
          />
          {
            isAvailableListenersLoading
              ? (
                <div className="w-screen max-w-full h-full py-26 flex items-center justify-center">
                  <SpinnerIcon />
                </div>
              )
              : (
                <div className="grid gap-3 df:lg:grid-cols-2 df:md:gap-6">
                  {availableListeners?.data?.map((peer) => (
                    <PeerCard
                      key={peer.listener_role_id}
                      className="overflow-hidden rounded-lg df:md:border df:md:border-neutral-200 df:md:p-5"
                      // @ts-ignore
                      areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
                      // @ts-ignore
                      subjectChips={peer?.subject_areas?.map((subject) => {
                        return {
                          name: subject.name,
                          variant: getParentByParentId(subject.parent_id)?.key,
                        };
                      })}
                      available={peer.is_available}
                      isPeerListener={peer.is_listener}
                      description={peer.about_me}
                      img={peer.profile_photo_url_square}
                      name={peer.display_name}
                      traits={peer.tags.map((tag) => tag.name)}
                      onClick={() =>
                        navigate.push({
                          pathname: ROUTE_PATH.PEER_DETAILS,
                          search: `?listenerId=${peer.listener_role_id}`,
                        })
                      }
                      onConnect={
                        peer.is_available
                          ? () => {
                              setScheduleModal(peer.listener_role_id);
                            }
                          : undefined
                      }
                      variant="wide"
                    />
                  ))}
                </div>
              )
          }
        </div>
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={true}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

export default AvailableListenersPage;
