import { useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet } from '../api/axios-handler';
import { useUser } from './useUser';

interface Notification {
  context: 'engagement_check_in' | 'updated_call' | 'updated_offers';
}

const fetchNotifications = async (userId?: number) => {
  if (userId) {
    return await axiosGet(`/users/${userId}/notification`, {}).then((response) => response.data);
  }
};

export const useNotifications = (options?: { onSuccess?: (data: Notification | null) => void }) => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();

  // long poll
  return useQuery(['poll', user?.id], () => fetchNotifications(user?.id), {
    retry: true,
    retryDelay: 1000 * 30,
    enabled: user !== undefined,
    onSuccess: (data) => {
      options?.onSuccess?.(data);
      // TODO: find out what contexts are available
      if (data?.context === 'updated_call') queryClient.invalidateQueries(['activeCalls']);
      if (data?.context === 'updated_offers') queryClient.invalidateQueries(['callRequests']);
      if (data?.context === 'engagement_check_in') queryClient.invalidateQueries(['reflections'])
      queryClient.invalidateQueries(['poll']);
    },
  });
};
