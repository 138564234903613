import { useHistory } from 'react-router-dom';

import { LogoShortIcon } from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../routes/route-paths';

import aboutUsImage from '../../assets/images/about-us.jpg';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { AboutUsText } from '../../components/common/AboutUsText';

const AboutUsMobile = () => {
  const navigate = useHistory();

  return (
    <div className="pb-10 df:md:pb-0">
      <MobileMenuHeader onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <DesktopMenuHeader />
      <div className="bg-neutral-700 bg-opacity-5 p-4 space-y-2 df:md:hidden">
        <div className="text-gray-800 text-2xl font-bold">
          About us
        </div>
        <div className="text-stone-500 text-sm font-medium">
          We’re all human. Be kindly.
        </div>
      </div>
      <div
        className="
          mx-auto grid grid-cols-1 max-w-3xl
          df:md:grid-cols-2 df:md:gap-13 df:md:h-[calc(100vh-64px)] df:md:items-center df:md:max-w-7xl
        "
      >
        <img
          className="py-3 px-4 order-1 df:md:order-2"
          src={aboutUsImage}
          alt="about us"
        />
        <div className="px-4 order-2 df:md:order-1">
          <AboutUsText className="pt-3 pb-12" />
          <LogoShortIcon className="mx-auto df:md:mx-0" />
        </div>
      </div>
    </div>
  );
};

export default AboutUsMobile;
