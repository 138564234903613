import { Button, CircledArrowRightIcon, Input } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';

function KeyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#222833"
          fillRule="evenodd"
          d="M20.53 2.47a.75.75 0 010 1.06l-.47.47 2.47 2.47a.75.75 0 010 1.06l-3.5 3.5a.75.75 0 01-1.06 0L15.5 8.56 11.562 12.5a5.75 5.75 0 11-1.06-1.06l4.468-4.47 3.5-3.5 1-1a.75.75 0 011.06 0zM16.56 7.5l1.94 1.94L20.94 7 19 5.06 16.56 7.5zM7 11.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

type AccountGroupIdVerificationProps = {
  error: string | undefined;
  onChange: (newVal: string) => void;
  onPrimary: () => Promise<unknown>;
  className?: string;
};
const AccountGroupIdVerification = ({ error, onChange, onPrimary, className }: AccountGroupIdVerificationProps) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  return (
    <div className={twMerge('p-6', className)}>
      <p className="text-gray-800 text-2xl font-bold leading-loose">
        {
          error
            ? 'Organization Not Recognized'
            : 'Your Organization'
        }
      </p>
      <h6 className="text-dark text-lg font-normal pt-2 leading-6">
        {
          error
            ? 'If you do not know your Group ID, contact your plan administrator.'
            : 'If you belong to an organization, confirm your Group ID below. If you do not know your Group ID and no ID is shown below, contact your plan administrator.'
        }
      </h6>
      <Input
        required
        id="group_id"
        label="GROUP ID"
        className="py-8"
        type="text"
        error={!!error}
        helperText="Not a recognized Group ID"
        placeholder="Enter Group ID"
        onIconClick={() => {}}
        onChange={(elem) => onChange(elem.target.value)}
        startIcon={<KeyIcon />}
      />
      <Button
        className="df:md:w-full"
        data-testid="verify-group-button"
        variant="primary"
        children="Verify Group ID"
        onClick={onSubmit}
        loading={inProgress}
        disabled={inProgress}
      />
      <div className="flex justify-center items-center gap-2 mt-4">
        <div className="text-gray-800 text-sm font-normal leading-tight">
          Already have an account?
        </div>
        <Link
          className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
          to={ROUTE_PATH.LOGIN}
        >
          Log in
          <CircledArrowRightIcon />
        </Link>
      </div>
    </div>
  );

  async function onSubmit() {
    try {
      setInProgress(true);

      await onPrimary();
    }
    finally {
      setInProgress(false);
    }
  }
};
export default AccountGroupIdVerification;
