import { Dispatch, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { AvatarSkeleton, ChevronDownIcon, PeerCard } from '@kindlyhuman/component-library';

import {
  InfinitePeersQueryResults,
  peersQueryKeys,
  useAddFavoritePeer,
  useRemoveFavoritePeer,
} from '../../hooks/usePeers';
import { TagGroup, useTagGroups } from '../../hooks/useTagGroups';
import { User } from '../../hooks/useUser';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FilterBar } from '../../components/filter/FilterBar';
import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';

import { ROUTE_PATH } from '../../routes/route-paths';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

interface RecommendedPeersProps {
  showFilterMenu: boolean;
  setShowFilterMenu: Dispatch<React.SetStateAction<boolean>>;
  peerQuery: UseInfiniteQueryResult<InfinitePeersQueryResults, unknown>;
  getTagGroupByName: (name: string) => TagGroup | undefined;
  handleFilterChange: ({ tag_group_ids, tag_ids }: TagFilters) => void;
  user?: User;
  fomHierarchy?: TagGroup[];
}

export const RecommendedPeersMobile: React.FC<RecommendedPeersProps> = ({
  showFilterMenu,
  setShowFilterMenu,
  peerQuery,
  getTagGroupByName,
  handleFilterChange,
  user,
  fomHierarchy,
}) => {
  const navigate = useHistory();

  const [scheduleModal, setScheduleModal] = useState<number>();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <div className="bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      <FilterBar
        className="df:md:mb-0 df:md:border-t-0 df:md:mt-0"
        setShowFilterMenu={setShowFilterMenu}
        getTagGroupByName={getTagGroupByName}
        handleFilterChange={handleFilterChange}
        user={user}
      />
      <div className="w-full df:md:max-w-7xl df:md:mx-auto df:md:px-6">
        <SubHeader
          title="Say hello to a new Peer"
          subtitle="Peers we think you'll connect with"
          className="px-4 pt-5 pb-7 df:md:px-0 df:md:pt-6 df:md:pb-8"
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <Body peerQuery={peerQuery} setScheduleModal={setScheduleModal} />
      </div>
      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={true}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </div>
  );
};

const Body: React.FC<{
  peerQuery: UseInfiniteQueryResult<InfinitePeersQueryResults, unknown>;
  setScheduleModal: (listenerId: number) => void;
}> = ({ peerQuery, setScheduleModal }) => {
  const navigate = useHistory();
  const addFavPeerMutation = useAddFavoritePeer(peersQueryKeys.infiniteRecommended);
  const removeFavPeerMutation = useRemoveFavoritePeer(peersQueryKeys.infiniteRecommended);
  const { getParentByParentId } = useTagGroups();

  const peers = peerQuery?.data?.pages.map((page) => page.data).flat();

  return (
    <>
      <div className="grid gap-3 df:md:grid-cols-2 df:md:gap-6">
        {peers?.map((peer) => (
          <PeerCard
            key={peer.listener_role_id}
            className="overflow-hidden rounded-lg df:md:border df:md:border-neutral-200 df:md:p-5"
            // @ts-ignore
            areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
            // @ts-ignore
            subjectChips={peer?.subject_areas?.map((subject) => {
              return {
                name: subject.name,
                variant: getParentByParentId(subject.parent_id)?.key,
              };
            })}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            description={peer.about_me}
            img={peer.profile_photo_url_square}
            name={peer.display_name}
            traits={peer.tags.map((tag) => tag.name)}
            onClick={() =>
              navigate.push({
                pathname: ROUTE_PATH.PEER_DETAILS,
                search: `?listenerId=${peer.listener_role_id}`,
              })
            }
            followed={peer.is_member_following}
            onFollow={
              peer.is_member_following
                ? () => removeFavPeerMutation.mutate(peer.listener_role_id)
                : () => addFavPeerMutation.mutate(peer.listener_role_id)
            }
            onConnect={
              peer.is_available
                ? () => {
                    setScheduleModal(peer.listener_role_id);
                  }
                : undefined
            }
            variant="wide"
          />
        ))}
        {peerQuery.isLoading &&
          [...Array(10).keys()].map((index) => <ExperienceSkeletonWide key={index} />)}
      </div>
      {peerQuery.hasNextPage ? (
        <button
          disabled={peerQuery.isFetchingNextPage}
          onClick={() => peerQuery.fetchNextPage()}
          className="pb-10 pt-6 flex justify-center gap-1 cursor-pointer mx-auto"
        >
          <div className="text-center text-violet-950 text-base font-bold leading-normal">
            See More
          </div>
          <ChevronDownIcon width={20} color="#24006B" />
        </button>
      ) : (
        <div className="pt-8" />
      )}
    </>
  );
};

export const ExperienceSkeletonWide: React.FC = () => (
  <div className="flex flex-col px-4 py-2 bg-white">
    <div className="flex items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        <AvatarSkeleton
          variant="normal"
          isPeerListener
        />
        <Skeleton className="h-8 w-16" />
        <Skeleton variant="circular" className="h-5 w-5" />
      </div>
      <Skeleton className="w-16 h-10 rounded-2xl" />
    </div>
    <Skeleton className="h-12 w-full" />
    <div className="flex gap-2 flex-wrap">
      <Skeleton className="h-12 w-24 rounded-2xl" />
      <Skeleton className="h-12 w-32 rounded-2xl" />
      <Skeleton className="h-12 w-32 rounded-2xl" />
      <Skeleton className="h-12 w-24 rounded-2xl" />
      <Skeleton className="h-12 w-16 rounded-2xl" />
    </div>
    <div className="flex flex-row items-center">
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
    </div>
  </div>
);
