import { useFavoritedPeers } from '../../hooks/usePeers';

import { MyPeersMobile } from './mobile';

const MyPeersPage = () => {
  const favoritedPeers = useFavoritedPeers();

  return (
    <MyPeersMobile favoritedPeers={favoritedPeers} />
  );
};

export default MyPeersPage;
