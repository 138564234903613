import { ExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { User } from '../../hooks/useUser';
import moment from 'moment';

export interface PartnerResourceConfig {
    needsAddress: boolean;
    processingDate: moment.Moment;
    processingDateString: string;
    waitForProcessingDate: boolean;
}

export interface TeleHealthResourceProps {
    providerPlan: ExternalProviderPlan | null;
    partnerResourceConfig: PartnerResourceConfig;
    ssoLinkUrl?: string;
    ssoLinkUrlPending?: boolean;
}

const getNeedsAddress = (user?: User, provider?: string): boolean => {
    if (provider !== "dialcare") {
        return false;
    }
    return user?.dialcare_status.has_address === false;
}

const getProcessingDate = (user?: User): moment.Moment => {
    return moment(user?.dialcare_status.processing_date);
}

const getPartnerResourceConfig = (user: User | undefined, providerPlan: ExternalProviderPlan | null): PartnerResourceConfig => {
    const provider = providerPlan?.provider;
    const processingDate = getProcessingDate(user);
    return {
        processingDate,
        needsAddress: getNeedsAddress(user, provider),
        processingDateString: processingDate.clone().local().format('MM-DD-YYYY'),
        waitForProcessingDate: provider === 'dialcare' && processingDate.isAfter(moment()),
    }
}

export default getPartnerResourceConfig;