import { useMediaQuery } from '../../hooks/useMediaQuery';
import AboutUsMobile from './mobile';

const AboutUs = () => {
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');
  return sm || md ? <AboutUsMobile /> : <AboutUsMobile />;
};

export default AboutUs;
