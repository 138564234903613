import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, SelectionChip, AreaChip, ExitIcon, ExitIconWithBorder } from '@kindlyhuman/component-library';

import { Tag, TagGroup, useTagGroups } from '../../hooks/useTagGroups';
import { useLockBody } from '../../hooks/useLockBody';

export type TagFilters = {
  tag_group_ids: number[];
  tag_ids: number[];
};

export const FilterMenuModal: React.FC<{
  open: boolean;
  selected: TagFilters;
  onChange: (selectionOptions: TagFilters) => void;
  onExit: () => void;
  fomOptions?: TagGroup[];
}> = ({ onChange, onExit, fomOptions, selected, open }) => {
  useLockBody(open);
  const [currentlySelected, setCurrentlySelected] = useState(selected);
  const { getParentByParentId } = useTagGroups();

  const toggleSelectedTagGroup = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_group_ids = tag_group_ids.includes(id)
        ? tag_group_ids
            // remove selected if
            .filter((currentId) => currentId !== id)
            // remove selected id's children
            .filter(
              (currentId) =>
                !getParentByParentId(id)
                  ?.children?.map((subject) => subject.id)
                  .includes(currentId)
            )
        : [...tag_group_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const toggleSelectedTag = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_ids = tag_ids.includes(id)
        ? tag_ids.filter((currentId) => currentId !== id)
        : [...tag_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const removeSubjects = () => {
    const challengeAreaIds = fomOptions?.map((challengeArea) => challengeArea.id);
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      return {
        tag_ids,
        tag_group_ids: tag_group_ids.filter((id) => challengeAreaIds?.includes(id)),
      };
    });
  };

  const removeTags = () => {
    setCurrentlySelected(({ tag_group_ids }) => {
      return {
        tag_ids: [],
        tag_group_ids,
      };
    });
  };

  const removeAll = () => {
    setCurrentlySelected({
      tag_group_ids: [],
      tag_ids: [],
    });
  };

  const handleFilterSave = () => {
    onChange(currentlySelected);
  };

  return fomOptions ? (
    <>
      <div
        className="fixed inset-0 bg-modalBackdropColor z-30 h-screen w-screen"
        onClick={onExit}
      />
      <div
        className="
          flex flex-col fixed bottom-0 left-0 right-0 rounded-t-r-15 overflow-y-auto  bg-[#E6E6E6] h-5/6 z-40
          df:md:right-auto df:md:top-0 df:md:h-auto df:md:max-w-[540px] df:md:rounded-none df:md:bg-white
        "
      >
        <FilterMenuHeader
          className="mb-2 df:md:mb-0"
          onExit={onExit}
        />
        <FilterMenu
          className="h-full overflow-y-auto"
          selected={currentlySelected}
          fomOptions={fomOptions}
          toggleSelectedTagGroup={toggleSelectedTagGroup}
          toggleSelectedTag={toggleSelectedTag}
          removeSubjects={removeSubjects}
          removeTags={removeTags}
          currentlySelected={currentlySelected}
        />
        <FilterMenuFooter
          onRemoveAll={removeAll}
          onChange={handleFilterSave}
        />
      </div>
    </>
  ) : null;
};

interface FilterMenuProps {
  selected: TagFilters;
  fomOptions?: TagGroup[];
  toggleSelectedTagGroup: (id: number) => void;
  toggleSelectedTag: (id: number) => void;
  removeSubjects: () => void;
  removeTags: () => void;
  currentlySelected: TagFilters;
  className?: string;
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
  fomOptions,
  toggleSelectedTagGroup,
  toggleSelectedTag,
  removeSubjects,
  removeTags,
  currentlySelected,
  className,
}) => {
  const { subjectsList, tagsList } = useMemo(
    () => ({
      subjectsList: (fomOptions?.filter(challengeArea =>
        currentlySelected.tag_group_ids.includes(challengeArea.id)
      ) ?? [])
        .map(challengeArea => challengeArea?.children.map(subject => ({
          color: currentlySelected.tag_group_ids.includes(subject.id)
            ? challengeArea.key
            : null,
          item: subject,
        })))
        .flat(),
      tagsList: (fomOptions?.filter(challengeArea =>
        currentlySelected.tag_group_ids.includes(challengeArea.id)
      ) ?? [])
        .map(challengeArea =>
          challengeArea?.children
            .filter(subject => currentlySelected.tag_group_ids.includes(subject.id))
            .map(subject => subject?.tags?.map(tag => ({
              color: currentlySelected.tag_ids.includes(tag.id)
                ? challengeArea.key
                : null,
              item: tag,
            })))
            .flat()
        )
        .flat(),
    }),
    [fomOptions, currentlySelected]
  );

  return fomOptions ? (
    <div className={twMerge('space-y-4', className)}>
      <div
        className="
          bg-white px-4 py-5 flex gap-x-2 gap-y-3 flex-wrap
          df:md:p-6 df:md:gap-2
        "
      >
        {fomOptions.map(challengeArea => (
          <AreaChip
            key={challengeArea.name}
            // @ts-ignore
            variant={challengeArea.name}
            selected={currentlySelected.tag_group_ids.includes(challengeArea.id)}
            onClick={() => toggleSelectedTagGroup(challengeArea.id)}
          />
        ))}
      </div>
      {/* Subjects */}
      {!!subjectsList.length && (
        <FilterSelectionArea
          title="Subjects"
          items={subjectsList}
          onClearSelections={removeSubjects}
          onSelectItem={toggleSelectedTagGroup}
        />
      )}

      {/* Tags */}
      {!!tagsList.length && (
        <FilterSelectionArea
          title="Topics"
          items={tagsList}
          onClearSelections={removeTags}
          onSelectItem={toggleSelectedTag}
        />
      )}
    </div>
  ) : null;
};

interface FilterMenuHeaderProps {
  onExit: () => void;
  className?: string;
}

const FilterMenuHeader: React.FC<FilterMenuHeaderProps> = ({ onExit, className }) => (
  <div
    className={twMerge(
      'bg-white p-4 text-gray-800 text-xl font-bold relative min-h-[72px] flex items-center justify-center',
      'df:md:min-h-0 df:md:p-6 df:md:border-b df:md:border-[#DFE1E5] df:md:justify-start',
      className
    )}
  >
    <button
      className="absolute left-4 df:md:hidden"
      onClick={() => onExit()}
    >
      <ExitIconWithBorder />
    </button>
    My focus
    <button
      className="hidden absolute right-4 df:md:block"
      onClick={() => onExit()}
    >
      <ExitIcon color="#969BA5" />
    </button>
  </div>
);

const FilterMenuFooter: React.FC<{
  onChange: () => void;
  onRemoveAll: () => void;
}> = ({ onChange, onRemoveAll }) => (
  <div
    className="
      py-4 px-6 bg-white border-t border-neutral-200 flex justify-between items-center
      df:md:shadow-[8px_0px_16px_0px_#191e270f] df:md:py-6
    "
  >
    <button
      onClick={onRemoveAll}
      className="text-center text-violet-950 text-base font-semibold leading-normal df:md:hidden"
    >
      Clear all
    </button>
    <Button
      onClick={onChange}
      variant="primary"
      className="df:md:hidden"
    >
      Save Changes
    </Button>
    <Button
      onClick={onRemoveAll}
      variant="secondary"
      className="hidden df:md:block"
    >
      Reset
    </Button>
    <Button
      onClick={onChange}
      variant="primary"
      className="hidden df:md:block"
    >
      Apply
    </Button>
  </div>
);

interface FilterSelectionAreaProps {
  title: string;
  items: {
    color: string | null;
    item: TagGroup | Tag;
  }[];
  onClearSelections?: () => void;
  onSelectItem: (id: number) => void;
  className?: string;
}

const FilterSelectionArea: React.FC<FilterSelectionAreaProps> = ({
  title,
  items,
  onClearSelections,
  onSelectItem,
  className
}) => (
  <div className={twMerge('bg-white mt-2 p-4 mb-2 space-y-4 df:md:space-y-5 df:md:p-6', className)}>
    <div className="w-full flex justify-between items-center">
      <div className="text-sm leading-tight">
        <span className="text-gray-800 font-bold">{title}</span>
        <span className="text-stone-500 pl-2 font-normal">(optional)</span>
      </div>
      {
        onClearSelections && (
          <button
            onClick={() => onClearSelections()}
            className="text-sm text-violet-950 font-semibold pr-6 df:md:pr-0"
          >
            Clear
          </button>
        )
      }
    </div>
    <div className="flex flex-wrap gap-y-4 gap-x-3 df:md:gap-x-2 df:md:gap-y-3">
      {items.map(({ color, item }) => (
        <SelectionChip
          key={item.id}
          onSelect={() => onSelectItem(item.id)}
          // @ts-ignore
          variant={color}
        >
          {item.name}
        </SelectionChip>
      ))}
    </div>
  </div>
)
