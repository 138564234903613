import React from 'react';
import { FormError, Input } from '@kindlyhuman/component-library';
import { useFormContext } from 'react-hook-form';

const Name: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="space-y-6">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-center df:md:text-3xl
        "
      >
        First things first,
        {' '}
        <br className="df:md:hidden" />
        what's your
        {' '}
        <span className="font-bold">name</span>
        ?
      </p>
      <div className="flex flex-col gap-4">
        <Input
          required={true}
          {...register('first_name')}
          id="first_name"
          label="FIRST NAME"
          onIconClick={() => {}}
          placeholder="Enter your first name"
          error={Boolean(errors?.first_name?.message)}
        />
        {errors?.first_name?.message && (
          <FormError text={errors?.first_name?.message as string} />
        )}
        <Input
          required
          {...register('last_name')}
          id="last_name"
          label="LAST NAME"
          onIconClick={() => {}}
          placeholder="Enter your last name"
          error={Boolean(errors?.last_name?.message)}
        />
        {errors?.last_name?.message && (
          <FormError text={errors?.last_name?.message as string} />
        )}
      </div>
    </div>
  );
};

export default Name;
