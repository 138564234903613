import { LogoShortIcon } from '@kindlyhuman/component-library';

import { AboutUsText } from '../../../common/AboutUsText';

import aboutUsImage from '../../../../assets/images/about-us.jpg';

import { TabHeader } from '../tab_header';

export const AboutUsTab = () => (
  <>
    <TabHeader
      header="About us"
    />
    <img
      className="max-w-[75%] mx-auto"
      src={aboutUsImage}
      alt="about us"
    />
    <AboutUsText className="pt-6 pb-12" />
    <LogoShortIcon className="mx-auto" />
  </>
);
