import { ReactNode } from 'react';
import { Skeleton } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import { CircledArrowRightIcon } from '@kindlyhuman/component-library';

import { HorizontalScrollSection } from './horizontal-scroll';

export const BlockHorizontalScroll: React.FC<{
  dataTestId?: string;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  center?: boolean;
  className?: string;
  contentClassName?: string;
  onViewAll?: () => void;
  showNavigationButton?: boolean;
  showViewAll?: boolean;
}> = ({
  dataTestId,
  title,
  subtitle,
  children,
  className,
  contentClassName,
  onViewAll,
  isLoading = false,
  center = false,
  showNavigationButton,
  showViewAll = true,
}) => {
  return (
    <div data-testid={dataTestId} className={twMerge('bg-white border-t border-b', className)}>
      {title && (
        <div className="flex items-start justify-between px-4 py-3 df:md:px-5 df:md:pt-5">
          <div>
            <p className="font-manrope font-bold text-xl df:md:text-2xl text-gray-800 flex-grow">
              {title}
            </p>
            {isLoading && <Skeleton variant="text" className="w-40 h-5 df:md:h-6 df:md:mt-1" />}
            {subtitle && !isLoading && (
              <div className="font-manrope text-stone-500 text-sm df:md:text-base df:md:mt-1 font-medium">
                {subtitle}
              </div>
            )}
          </div>
          {showViewAll && (
            <TextButton
              className="df:md:mt-3"
              onClick={onViewAll}
              title={
                <>
                  <span className="hidden df:md:inline-block">See all</span>
                  <span className="df:md:hidden">View all</span>
                </>
              }
            />
          )}
        </div>
      )}

      <HorizontalScrollSection
        center={center}
        className={twMerge('df:md:px-5 df:md:pb-5', contentClassName)}
        showNavigationButton={showNavigationButton}
      >
        {children}
      </HorizontalScrollSection>
    </div>
  );
};

const TextButton: React.FC<{
  title: ReactNode;
  onClick?: () => void;
  className?: string;
}> = ({ title, onClick, className }) => (
  <button
    onClick={onClick && onClick}
    className={twMerge(
      'flex font-manrope text-violet-950 text-base font-semibold leading-normal items-center cursor-pointer',
      className
    )}
  >
    {title} &nbsp;
    <CircledArrowRightIcon />
  </button>
);
