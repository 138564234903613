import { useMediaQuery } from '../../hooks/useMediaQuery';
import { SupportMobileView } from './mobile';

const Support = () => {
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');

  return sm || md ? (
    <SupportMobileView />
  ) : (
    // this will become desktop view (is there even one?)
    <SupportMobileView />
  );
};

export default Support;
