import { MorphiiWidget } from '../../../../components/morphii/morphii-widget';
import { useState } from 'react';
import { PostCall } from '../../../../hooks/usePostCall';
import { twMerge } from 'tailwind-merge';
import { Subheader } from '..';

export interface MorphiiStepProps {
  postCall?: PostCall;
  className?: string;
}

export const MorphiiStep: React.FC<MorphiiStepProps> = ({
  postCall,
  className,
}) => {
  const [hasMorphii, setHasMorphii] = useState(true);

  return (
    <div className={twMerge('space-y-5', className)}>
      <Subheader
        className="px-4 df:md:px-0"
        question={(
          <>
            How are you feeling after your call with
            {' '}
            <span className="font-bold">
              {postCall?.call.listener_role.user.display_name}
            </span>
            ?
          </>
        )}
        postCall={postCall}
      />
      <div className="pt-16 flex flex-col bg-white">
        <MorphiiWidget hasMorphii={hasMorphii} setHasMorphii={setHasMorphii} />
      </div>
    </div>
  );
};
