import { useState, useMemo } from 'react';
import { twMerge } from 'tailwind-merge'

import {
  CreditCardIcon,
  DesktopHeader,
  DesktopHeaderProps,
  LogoutIcon,
  UserWithBorderIcon,
  SupportWithQuestionMarkIcon,
} from '@kindlyhuman/component-library';

import { useUser } from '../../hooks/useUser';

import { ROUTE_PATH } from '../../routes/route-paths';

import Toast from '../common/PopUpMessage';

import { LogoutModal } from '../LogoutModal';
import { SettingsModal, settingsModalAccordionItems } from '../settings_modal';
import { SupportModal } from '../SupportModal';

export interface DesktopMenuHeaderProps
  extends Omit<DesktopHeaderProps, 'menuItems' | "avatarDropdownMenuItems"> {
  withoutMenuItems?: boolean
}

export const DesktopMenuHeader = ({ withoutMenuItems, className, ...props }: DesktopMenuHeaderProps) => {
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState<boolean>(false);
  const [initialSettingModalItem, setInitialSettingModalItem] = useState<settingsModalAccordionItems | undefined>(
    undefined
  );
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);

  const { data: user } = useUser();

  const isUnlimited = user?.onUnlimitedPlan;
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = isUnlimited
    ? undefined
    : user?.caller_role.payment_data.available_minutes;

  const desktopMenuItems = useMemo(() => [
    {
      text: 'Home',
      href: ROUTE_PATH.HOME,
    },
    {
      text: 'Resources',
      href: ROUTE_PATH.RESOURCES,
    },
    {
      text: 'Saved Experiences',
      href: ROUTE_PATH.FAVORITE_EXPERIENCES,
    },
  ], []);
  const desktopAvatarDropdownMenuItems = useMemo(() => [
    {
      text: 'My profile',
      icon: <UserWithBorderIcon />,
      onClick: () => openSettingModal(settingsModalAccordionItems.myProfile),
    },
    {
      text: 'Membership',
      icon: <CreditCardIcon />,
      onClick: () => openSettingModal(settingsModalAccordionItems.myPlan),
    },
    {
      text: 'Support',
      icon: <SupportWithQuestionMarkIcon className="max-md:hidden" />,
      onClick: () => setIsSupportModalOpen(true),
    },
    {
      text: 'Log Out',
      icon: <LogoutIcon />,
      onClick: () => {
        setLogoutOpen(true);
      },
    },
  ], []);

  return (
    <>
      <DesktopHeader
        className={twMerge(
          'bg-white hidden df:md:block px-0 [&>div]:px-6',
          className
        )}
        menuItems={withoutMenuItems ? [] : desktopMenuItems}
        avatarDropdownMenuItems={withoutMenuItems ? [] : desktopAvatarDropdownMenuItems}
        userFirstName={user?.first_name}
        userLastName={user?.last_name}
        homeLink={ROUTE_PATH.HOME}
        connectLink={ROUTE_PATH.AVAILABLE_LISTENERS}
        availableMinutes={availableMinutes}
        {...props}
      />
      <LogoutModal
        dataTestId='desktop-logout-dialog'
        open={isLogoutOpen}
        onClose={() => setLogoutOpen(false)}
      />
      <SettingsModal
        open={isSettingModalOpen}
        onClose={closeSettingModal}
        initialItem={initialSettingModalItem}
      />
      <SupportModal
        open={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        onSuccessSubmitted={() => {
          Toast.success(
            'Your support request has been successfully submitted',
            undefined,
          );
        }}
      />
    </>
  );

  function openSettingModal(newDefaultSettingModalItem: settingsModalAccordionItems) {
    setIsSettingModalOpen(true);
    setInitialSettingModalItem(newDefaultSettingModalItem);
  }

  function closeSettingModal() {
    setIsSettingModalOpen(false);
    setInitialSettingModalItem(undefined);
  }
};
