import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios-handler';

export interface Subscription {
  details?: string;
  discount: number;
  id: number;
  minutes: number;
  code: string;
  price: number;
  banner_text: string;
  cents_per_minute: number;
  name: string;
  description?: string;
}

const getSubscriptions = async (packageCode?: string | null): Promise<Subscription[]> => {
  // there is no legitimate reason for the MWA to get at all subscriptions
  // it must have at least the packageCode
  if (!packageCode) {
    return [];
  }
  const params = {
    "package_code": packageCode
  };

  return await axiosGet('/subscriptions/', params).then(
    (subscriptionsResponse) => subscriptionsResponse.data
  );
};

export const useSubscriptions = (packageCode?: string | null) => {
  return useQuery<Subscription[]>(
    ['subscriptions', packageCode],
    () => getSubscriptions(packageCode),
    {
      staleTime: Infinity,
    }
  );
};
