import React, { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';

import { PlusIcon, PeerCard, SpinnerIcon } from '@kindlyhuman/component-library';

import { PeerResponse } from '../../hooks/usePeers';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';

import { ROUTE_PATH } from '../../routes/route-paths';
import { LinkProps } from '@mui/material';
import { twMerge } from 'tailwind-merge';

interface MyPeersProps {
  favoritedPeers: UseQueryResult<PeerResponse, unknown>;
}

export const MyPeersMobile: React.FC<MyPeersProps> = ({ favoritedPeers }) => {
  const navigate = useHistory();

  const [scheduleModal, setScheduleModal] = useState<number>();

  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <div className="bg-neutral-700 bg-opacity-5 min-h-screen">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      <div className="w-full df:md:max-w-7xl df:md:mx-auto df:md:px-6">
        <SubHeader
          title="My Peers"
          subtitle="Your Peers are here for you"
          className="px-4 pt-5 pb-7 df:md:px-0 df:md:pt-6 df:md:pb-8"
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        {
          favoritedPeers.isLoading && (
            <div className="w-full h-100 flex items-center justify-center">
              <SpinnerIcon className="w-14 h-14" />
            </div>
          )
        }
        {
          favoritedPeers.data?.count === 0 && !favoritedPeers.isLoading && (
            <p className="text-center px-4 py-8">
              You are not following any peers.
              <br />
              Click find more peers below and follow your favorites to save them here.
            </p>
          )
        }
        <div className="grid gap-3 df:md:grid-cols-2 df:md:gap-6">
          {favoritedPeers.data?.data.map((peer) => (
            <PeerCard
              key={peer.listener_role_id}
              className="overflow-hidden rounded-lg df:md:border df:md:border-neutral-200 df:md:p-5"
              // @ts-ignore
              areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
              // @ts-ignore
              subjectChips={peer?.subject_areas?.map((subject) => ({
                name: subject.name,
                variant: getParentByParentId(subject.parent_id)?.key,
              }))}
              available={peer.is_available}
              isPeerListener={peer.is_listener}
              description={peer.about_me}
              img={peer.profile_photo_url_square}
              name={peer.display_name}
              traits={peer.tags.map((tag) => tag.name)}
              onClick={() =>
                navigate.push({
                  pathname: ROUTE_PATH.PEER_DETAILS,
                  search: `?listenerId=${peer.listener_role_id}`,
                })
              }
              onConnect={
                peer.is_available
                  ? () => {
                      setScheduleModal(peer.listener_role_id);
                    }
                  : undefined
              }
              variant="wide"
            />
          ))}
        </div>
        {
          !favoritedPeers.isLoading && (
            <div className="text-center">
              <FindMorePeersButton className="pt-10 pb-12" />
            </div>
          )
        }
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          isNow={true}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </div>
  );
};

const FindMorePeersButton = ({ className }: Partial<LinkProps>) => (
  <Link
    className={twMerge('inline-flex gap-1 items-center', className)}
    to={ROUTE_PATH.RECOMMENDED_PEERS}
  >
    <PlusIcon />
    <div className="text-violet-950 text-base font-bold leading-normal">Find more peers</div>
  </Link>
);
