import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useUser } from '../../hooks/useUser';
import { ChangePasswordMobile } from './mobile';


const ChangePasswordPage = () => {
    const {data: user} = useUser()
  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');
  return sm || md ? <ChangePasswordMobile user={user} /> : <ChangePasswordMobile user={user} />;
};

export default ChangePasswordPage;