import { useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useHistory } from 'react-router-dom';

import {
  BookIcon,
  BookmarkIcon,
  CommentIcon,
  CreditCardIcon,
  EmailIcon,
  MobileHeader,
  MobileHeaderProps,
  InfoIcon,
  LogoutIcon,
  UserIcon,
} from '@kindlyhuman/component-library';

import { ROUTE_PATH } from '../../routes/route-paths';

import { LogoutModal } from '../LogoutModal';
import { useLockBody } from '../../hooks/useLockBody';

export interface MobileMenuHeaderProps extends Omit<MobileHeaderProps, 'menuItems'> {}

export const MobileMenuHeader = ({ className, ...props }: MobileMenuHeaderProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  useLockBody(isMenuOpen);

  const headerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const navigate = useHistory();

  const handleOpenStateStatus = (open: boolean) => {
    setMenuOpen(open);
  };

  const mobileMenuItems = useMemo(
    () => [
      {
        text: 'My Peers',
        icon: <CommentIcon />,
        href: ROUTE_PATH.MY_PEERS,
      },
      {
        text: 'Resources',
        icon: <BookIcon />,
        href: ROUTE_PATH.RESOURCES,
      },
      {
        text: 'Saved Experiences',
        icon: <BookmarkIcon />,
        href: ROUTE_PATH.FAVORITE_EXPERIENCES,
      },
      {
        text: 'Account Settings',
        icon: <UserIcon />,
        href: ROUTE_PATH.ACCOUNT_SETTINGS,
      },
      {
        text: 'Membership Management',
        icon: <CreditCardIcon />,
        href: ROUTE_PATH.MEMBERSHIP_MANAGEMENT,
      },
      {
        text: 'About Us',
        icon: <InfoIcon />,
        href: ROUTE_PATH.ABOUT,
      },
      {
        text: 'Support',
        icon: <EmailIcon />,
        href: ROUTE_PATH.SUPPORT,
      },
      {
        text: 'Log Out',
        icon: <LogoutIcon />,
        onClick: () => {
          setLogoutOpen(true);
        },
      },
    ],
    []
  );

  return (
    <>
      {
        isMenuOpen && (
          <div style={{ height: headerRef?.current?.offsetHeight }} />
        )
      }
      <div
        className={
          isMenuOpen ? 'fixed top-0 inset-0 bg-gray-600  z-100 bg-opacity-50 h-full w-screen overflow-y-scroll' : ''
        }
        onClick={isMenuOpen ? () => handleOpenStateStatus(false) : undefined}
      >
        <div className={isMenuOpen ? 'absolute top-0 w-full' : ''}>
          <MobileHeader
            headerRef={headerRef}
            open={isMenuOpen}
            className={twMerge('bg-white', 'df:md:hidden', className)}
            getOpenStatus={handleOpenStateStatus}
            menuItems={mobileMenuItems}
            goHome={() =>
              navigate.push({
                pathname: ROUTE_PATH.HOME,
              })
            }
            {...props}
          />
        </div>
        <LogoutModal dataTestId='mobile-logout-dialog' open={isLogoutOpen} onClose={() => setLogoutOpen(false)} />
      </div>
    </>
  );
};
