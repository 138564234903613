import { Button } from '@kindlyhuman/component-library';
import { WelcomeSlogan } from '../../../components/common/WelcomeSlogan';

type introProps = {
  introImg?: string;
  introMessage?: string;
  goProfileStep: () => void;
};

const Intro: React.FunctionComponent<introProps> = ({
  introImg,
  introMessage,
  goProfileStep,
}): JSX.Element => {
  return (
    <div
      className="
        grid grid-cols-1
        df:md:grid-cols-2 df:md:h-screen df:md:w-screen df:md:fixed df:md:top-0 df:md:left-0 df:md:z-20
      "
    >
      <WelcomeSlogan
        className="hidden df:md:block"
      />
      <div className="df:md:bg-whiteSmoke df:md:h-screen">
        <div
          className="
            py-8 gap-y-8 h-full flex flex-col items-center justify-center
            df:xl:px-30 df:lg:px-15 df:md:px-10 overflow-auto
          "
        >
          {introImg && (
            <div className="min-h-[200px] max-h-[400px] df:md:max-h-full">
              <img
                className="rounded-xl mx-auto max-h-full max-w-full"
                src={introImg}
                alt="Intro"
              />
            </div>
          )}
          {introMessage && (
            <div
              className="text-center space-y-2"
              dangerouslySetInnerHTML={{ __html: introMessage }}
            />
          )}
          <Button
            variant="primary"
            className="mx-auto"
            onClick={goProfileStep}
          >
            Get started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
