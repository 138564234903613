import { useMutation } from '@tanstack/react-query';

import { axiosPost } from '../api/axios-handler';

const postSupportRequest = async (userId: number, input: any) => {
  return await axiosPost(`/users/${userId}/support`, { ...input }).then(
    (response) => response.data
  );
};

export const useSupportRequest = () => {
  return useMutation(
    async ({ userId, input }: { userId: number; input: any }) => postSupportRequest(userId, input)
  );
};
