import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { StripeLogoIcon } from '@kindlyhuman/component-library';

export const PoweredByStripe: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div className={twMerge('flex gap-1', className)}>
    <p className="text-neutral-700 text-sm font-medium">
      Powered by
    </p>
    <StripeLogoIcon />
  </div>
);
