import { HTMLAttributes, useMemo } from "react";

import { CalendarIcon, CallCard, CALL_CARD_VARIANT, TrashIcon } from "@kindlyhuman/component-library";
import moment from "moment";

import {
  CallRequest,
  useAcceptBackupConnectionMutation,
  useRequestCancelMutation,
} from "../../../hooks/useCalls";
import { useTagGroups } from "../../../hooks/useTagGroups";

type CallCardVariant = keyof typeof CALL_CARD_VARIANT

export interface CallCardWithDataProps extends HTMLAttributes<HTMLDivElement> {
  callCardVariant: CallCardVariant;
  callData: CallRequest,
  showRescheduleModal?: (callData: CallRequest) => void,
}

export const CallCardWithData: React.FC<CallCardWithDataProps> = ({
  callCardVariant,
  callData,
  showRescheduleModal,

  className,
}) => {
  const acceptBackupConnection = useAcceptBackupConnectionMutation();
  const cancelMutation = useRequestCancelMutation();
  const { data: tagGroups } = useTagGroups();

  const { menuItems, onButtonClick } = useMemo(
    () => {
      let onButtonClick
      const menuItems = [
        ...(['pending', 'scheduled', 'unavailable'].includes(callCardVariant ?? '') && showRescheduleModal
          ? [{
            onClick: () => showRescheduleModal(callData),
            title: 'Reschedule',
            icon: <CalendarIcon width={20} height={20} />,
          }]
          : []),
        {
          onClick: () => {
            cancelMutation.mutate(callData.id);
          },
          title: 'Cancel',
          icon: <TrashIcon />,
        },
      ]

      switch (callCardVariant) {
        case 'unavailable':
          if (showRescheduleModal) {
            onButtonClick = () => showRescheduleModal(callData);
          }
          break;
        case 'backup':
          onButtonClick = () => acceptBackupConnection.mutate(callData.connections[0].id);
          break;
      }

      return { menuItems, onButtonClick }
    },
    [callData, callCardVariant, acceptBackupConnection, showRescheduleModal, cancelMutation]
  );

  return (
    <CallCard
      variant={callCardVariant as CallCardVariant}
      className="w-full df:md:max-w-full"
      menuItems={menuItems}
      onButtonClick={onButtonClick}
      // @ts-ignore
      areaLabelVariants={
        tagGroups
          ?.filter((tagGroup) => callData.tag_group_ids?.includes(tagGroup.id))
          ?.map((tagGroup) => tagGroup.name) ?? []
      }
      img={callData.connections[0].listener_role.profile_photo_square_file_url}
      title={callData.connections[0].listener_role.user.display_name}
      secondaryText={
        callData.connections[0].scheduled_at !== null
          ? moment(callData.connections[0].scheduled_at).isSame(moment(), 'date')
            ? moment(callData.connections[0].scheduled_at).format(`[Today,] hh:mm a`)
            : moment(callData.connections[0].scheduled_at).format('ddd, hh:mm a')
          : 'Now'
      }
    />
  );
};
