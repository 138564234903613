import React from 'react';
import { RadioList } from './RadioList';

type raceProps = {
  raceList: string[] | undefined;
};

const Race: React.FunctionComponent<raceProps> = ({ raceList }): JSX.Element => (
  <div className="space-y-6">
    <div className="space-y-2 df:md:text-center">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          df:md:text-3xl
        "
      >
        Which
        {' '}
        <span className="font-bold">ethnic group</span>
        {' '}
        do you identify with?
      </p>
      <p className="text-gray-900 text-base">
        Please check all that apply
      </p>
    </div>
    <RadioList
      name="race"
      options={raceList}
    />
  </div>
);

export default Race;
