import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@kindlyhuman/component-library';

import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FormBlock } from '../../components/common/form_block';

import { DialCare, TelemedDetailPng, Walmart } from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { TeleHealthResourceProps } from './partner-resource-config';

export const TelemedPageMobile: React.FC<TeleHealthResourceProps> = ({
    providerPlan,
    partnerResourceConfig,
    ssoLinkUrl,
    ssoLinkUrlPending,
}) => {
  const navigate = useHistory();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  const provider = providerPlan?.provider;
  const providerName = provider === 'dialcare' ? 'DialCare' : 'Walmart Health Virtual Care';

  const resourceData = useMemo(
    () => ({
      name: '+Telemed',
      description: 'Your benefit includes the opportunity to speak with a licensed physician.',
      longDescription: `
        Through our partnership with ${providerName}, you have access to licensed clinical
        physicians to discuss health issues.
      `,
      providerText: (
        provider === 'dialcare'
          ? 'THIS PLAN IS NOT INSURANCE and is not intended to replace health insurance.'
          : (
            provider === 'me_md'
              ? `
                Walmart Health is comprised of independent physician practices. Walmart Health
                Virtual Care (WHVC) offers medical services via telehealth to patients
                nationwide. Telehealth services may vary by state. Telehealth services are
                provided in accordance with state law by licensed health care professionals,
                subject to the licensed professionals’ judgment. When medically necessary, WHVC
                providers may prescribe medication that can be picked up at a local pharmacy of
                the patient’s choice; WHVC does not guarantee that a prescription will be
                written. WHVC providers do not prescribe controlled substances. WHVC is not a
                pharmacy or an insurance product. Virtual Urgent Care visits are not a
                replacement for a primary care physician.
              `
              : ''
          )
      ),
      providerImage: (
        provider === 'dialcare'
          ? <img className="min-w-full" src={DialCare} alt={providerName} />
          : (
            <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
              <img className="min-w-full mb-3" src={Walmart} alt={providerName} />
            </div>
          )
      )
    }),
    [provider, providerName]
  )

  const getCTAContent = () => {
    if (partnerResourceConfig.needsAddress) {
      return (
        <>
          <div className="text-gray-800 text-sm font-medium leading-tight">
            We need your home address in order to activate this service.
          </div>
          <div className="mt-3">
            <Button
              slim
              variant="secondary"
              onClick={() => {
                navigate.push(ROUTE_PATH.PROFILE);
              }}
            >
              Add Address
            </Button>
          </div>
        </>
      );
    }

    if (partnerResourceConfig.waitForProcessingDate) {
      return (
        <div className="text-gray-800 text-sm font-medium leading-tight">
          You will have access to +Telemed beginning on{' '}
          {partnerResourceConfig.processingDateString}
        </div>
      );
    }

    let ctaButtonText = 'Loading...';
    if (!ssoLinkUrlPending) {
      ctaButtonText =
        provider === 'dialcare' ? 'Connect with a Physician' : 'Connect with a Licensed Provider';
    }
    return (
      <a href={ssoLinkUrl} target="_blank" rel="noopener noreferrer">
        <Button className="w-80" variant="primary" disabled={ssoLinkUrlPending}>
          {ctaButtonText}
        </Button>
      </a>
    );
  };

  return (
    <div className="w-full min-h-screen bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt={true} onBack={() => navigate.goBack()} />
      <div className="hidden df:md:block max-w-7xl px-6 mx-auto">
        <SubHeader
          className="hidden df:md:block pt-6 pb-8"
          title={(
            <>
              <span className="font-normal">
                Need to talk with
              </span>
              {' '}{resourceData.name}?
            </>
          )}
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <div className="flex gap-6">
          <FormBlock className="flex justify-between gap-12 space-y-0">
            <div className="font-manrope text-dark">
              <p className="text-xl font-bold">
                {resourceData.name}
              </p>
              <p className="font-medium mt-2">
                {resourceData.description}
              </p>
              <p className="font-medium mt-2">
                {resourceData.longDescription}
              </p>
              <div className="mt-8">
                {getCTAContent()}
              </div>
            </div>
            <img
              className="rounded-lg max-w-72 self-start"
              src={TelemedDetailPng}
              alt="physician-img"
            />
          </FormBlock>
          <FormBlock className="max-w-100">
            <p className="text-xl font-bold">
              Resource sponsor
            </p>
            <div className="py-2 px-8 mt-3">{resourceData.providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">
              {resourceData.providerText}
            </div>
          </FormBlock>
        </div>
      </div>
      <div className="w-full h-full overflow-y-auto flex-col gap-y-4 grid place-items-center df:md:hidden">
        <div className="flex-col gap-y-4 grid place-items-center max-w-lg">
          <div className="bg-white min-w-full px-5 py-5">
            <div className="justify-start items-center inline-flex">
              <img src={TelemedDetailPng} className="w-full rounded-lg" alt="physician-img"></img>
            </div>
            <div className="self-stretch text-gray-800 text-4xl font-bold leading-10 mt-3">
              {resourceData.name}
            </div>
            <div className="self-stretch text-gray-800 text-xl font-bold leading-normal mt-3">
              {resourceData.description}
            </div>
            <div className="self-stretch text-gray-800 text-base font-medium leading-normal mt-3">
              {resourceData.longDescription}
            </div>
          </div>
          <div className="bg-white min-w-full px-5 py-5 grid place-items-center">
            {getCTAContent()}
          </div>
          <div className="bg-white px-5 py-5 grid place-items-center pb-20">
            <div className="min-w-full h-7 text-gray-800 text-xl font-bold leading-loose">
              Powered by
            </div>
            <div className="py-2 px-8 mt-3">{resourceData.providerImage}</div>
            <div className="mt-3 text-gray-800 text-sm font-medium leading-tight">
              {resourceData.providerText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
