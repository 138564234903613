import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { ROUTE_PATH } from './route-paths';

type props = {
  exact: boolean;
  path: string;
  component: any;
};

const PrivateRoute: React.FunctionComponent<props> = ({
  exact,
  path,
  component: Component,
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(true);

  const getToken = async () => {
    const authorizationToken = (await localStorage.getItem('authorization_token')) || null;
    return authorizationToken;
  };

  const checkAuthentication = useCallback(async () => {
    try {
      const token = (await getToken()) || '';
      const clientCode = localStorage.getItem('clientCode');

      if (path === ROUTE_PATH.HELP && !token) {
        setIsAuthenticated(false);
      } else if (token || clientCode) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [path]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return isAuthenticated ? (
    <Route exact={exact} path={path} component={Component} />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
