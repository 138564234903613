import { Link, useHistory } from 'react-router-dom';

import { Content } from '@kindlyhuman/component-library';

import { ResourcesResponse } from '../../hooks/useResources';
import { ExternalProviderPlan } from '../../hooks/useExternalProviderPlans';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useMediaQueryWithDesktopFeature } from '../../hooks/useMediaQueryWithDesktopFeature';

import { TelemedDetailPng, CounselingDetailPng } from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';

interface ResourcePageMobileProps {
  providerPlan?: ExternalProviderPlan | null;
  clientResourcesData?: ResourcesResponse;
}

export const ResourcePageMobile: React.FC<ResourcePageMobileProps> = ({
  providerPlan,
  clientResourcesData,
}) => {
  const navigate = useHistory();

  const { getParentByParentId } = useTagGroups();

  const dfMdMedia = useMediaQueryWithDesktopFeature('md');

  return (
    <div className="w-full h-screen flex flex-col overflow-hidden bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt={true} onBack={() => navigate.goBack()} />
      <div className="w-full overflow-y-auto">
        <div className="w-100 mx-auto py-5 df:md:w-auto df:md:max-w-7xl df:md:px-6">
          <div className="px-4 pb-7 space-y-2 df:md:px-0 df:md:pb-8">
            <p className="text-gray-800 text-2xl font-bold leading-loose">
              {
                !dfMdMedia
                  ? 'Available Resources'
                  : 'Resources list'
              }
            </p>
            <p className="text-stone-500 text-sm font-medium leading-tight df:md:text-base">
              Resources based on your areas of interest
            </p>
          </div>
          <div className="grid gap-4 df:md:grid-cols-2 df:lg:grid-cols-3 df:md:gap-6">
            {['telemed', 'both'].includes(providerPlan?.provider_type ?? '') && (
              <Link
                to={ROUTE_PATH.TELEMED}
                className="bg-white border-neutral-200 df:md:p-3 df:md:rounded-lg df:md:border"
              >
                <Content
                  caption="Find out more about +Telemed today."
                  displayName="+Telemed"
                  imageUrl={TelemedDetailPng}
                  variant={dfMdMedia ? 'desktopWide' : 'wide'}
                />
              </Link>
            )}
            {['counseling', 'both'].includes(providerPlan?.provider_type ?? '') && (
              <Link
                to={ROUTE_PATH.COUNSELING}
                className="bg-white border-neutral-200 df:md:p-3 df:md:rounded-lg df:md:border"
              >
                <Content
                  caption="Find out more about +Counseling now"
                  displayName="+Counseling"
                  imageUrl={CounselingDetailPng}
                  variant={dfMdMedia ? 'desktopWide' : 'wide'}
                />
              </Link>
            )}
            {clientResourcesData?.data?.map((resource, index: number) => (
              <Link
                key={`${index} - ${resource.url}`}
                to={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                className="bg-white border-neutral-200 df:md:p-3 df:md:rounded-lg df:md:border"
              >
                <Content
                  caption={resource.description.substring(0, 50) + '...'}
                  displayName={resource.name}
                  imageUrl={resource.image_url}
                  variant={dfMdMedia ? 'desktopWide' : 'wide'}
                  // @ts-ignore
                  areas={resource.challenge_areas?.map((id) => getParentByParentId(id)?.name)}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
