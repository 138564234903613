import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, SpinnerIcon } from '@kindlyhuman/component-library';

import { useUser } from '../../hooks/useUser';
import { usePaymentMethod } from '../../hooks/useStripe';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import Toast from '../../components/common/PopUpMessage';
import { PoweredByStripe } from '../../components/common/payment/powered_by_stripe';
import { AddPaymentForm } from '../../components/common/payment/add_payment_form';
import { PaymentMethod } from '../../components/common/payment/payment_method';

export const PaymentMethodPage = () => {
  const navigate = useHistory();

  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: { payment_method } = {}, isLoading: isPaymentMethodLoading } = usePaymentMethod(
    user?.id
  );

  const [showUpdateCardForm, setShowUpdateCardForm] = useState<boolean>(false);
  const [ratePerMinute, setRatePerMinute] = useState(0);

  useEffect(() => {
    if (user?.onUnlimitedPlan) {
      navigate.push(ROUTE_PATH.HOME);
    }
    setRatePerMinute(user?.caller_role?.payment_data.rate_per_minute!);
  }, [user, navigate]);

  if (isUserLoading || isPaymentMethodLoading) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <div className="bg-neutral-200 bg-opacity-30 min-h-screen">
        <div className="py-5 px-5 border-b-2">
          <div className="text-gray-800 text-2xl leading-loose font-bold">Payment method</div>
          <div className="text-gray-800 text-base font-normal leading-normal">
            Add a payment method to cover charges from paid minutes with a Listener
          </div>
        </div>
        <div className="bg-white mb-5">
          <div className="py-5 px-5 border-b-2">
            <div className="text-gray-800 text-xl leading-loose font-bold">Minutes</div>
            <div className="text-gray-800 text-base font-normal leading-normal">
              Paid calls with listeners are charged at the following rate
            </div>
            <div className="py-3 h-6 justify-between items-start gap-4 flex">
              <div className="text-gray-800 text-base font-medium leading-normal">
                Rate per minute
              </div>
              <div className="ml-auto text-center text-gray-800 text-base font-bold leading-normal">
                {`$${(ratePerMinute / 100).toFixed(2)}`}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4 border-t-2">
          <div className="bg-white text-gray-800 text-xl leading-loose font-bold p-5">
            Card details
          </div>

          {!payment_method?.card || showUpdateCardForm ? (
            <AddPaymentForm
              className="bg-white px-5 pb-4"
              bottomActionButton={({ isSubmitting }) => (
                <div className="flex flex-col items-center justify-center mt-4 gap-7">
                  <PoweredByStripe />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Update details
                  </Button>
                </div>
              )}
              onSuccessAddPayment={() => {
                setShowUpdateCardForm(false);
                Toast.success(
                  'You have successfully updated your payment details',
                  'Payment details updated!'
                );
              }}
              onErrorAddPayment={() => {
                Toast.error(
                  'Your payment method has failed to process.  Confirm your payment info is correct and try again.',
                  'Payment Failed'
                );
              }}
            />
          ) : (
            <>
              <div className="px-5 bg-white pb-4">
                <PaymentMethod
                  className="p-3 rounded-lg border border-neutral-200"
                  onUpdateButtonClick={() => setShowUpdateCardForm(true)}
                  onSuccessRemovePayment={() => {
                    Toast.success(
                      'You have successfully removed your payment details',
                      'Payment details updated!'
                    );
                  }}
                />
              </div>
              <PoweredByStripe className="justify-center mt-7" />
            </>
          )}
        </div>
      </div>
    </>
  );
};
