import {
  Button,
  CircledArrowRightIcon,
  Dialog,
  EmailIcon,
  EyeIcon,
  MobileHeader,
  Input,
  LockIcon,
  Toast,
  Checkbox,
} from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useState } from 'react';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Spinner } from '../../components/common/spinner';

interface LoginMobileProps {
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void;
  handlePasswordReset: (e: React.FormEvent<HTMLFormElement>) => void;
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  trustRef: React.RefObject<HTMLInputElement>;
  forgotPasswordEmailRef: React.RefObject<HTMLInputElement>;
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showLoginError: boolean;
  setShowLoginError: React.Dispatch<React.SetStateAction<boolean>>;
  showResetError: boolean;
  setShowResetError: React.Dispatch<React.SetStateAction<boolean>>;
  showResetSuccess: boolean;
  setShowResetSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

export const LoginMobile = ({
  handleLogin,
  handlePasswordReset,
  emailRef,
  passwordRef,
  forgotPasswordEmailRef,
  showPassword,
  setShowPassword,
  showDialog,
  setShowDialog,
  showResetError,
  setShowResetError,
  showResetSuccess,
  setShowResetSuccess,
  disabled,
  trustRef,
}: LoginMobileProps): JSX.Element => {
  const [isTrusted, setIsTrusted] = useState(false);
  const { isLoading } = useFeatureFlag("MWA_RESPONSIVE_DESIGN");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-[#F6F6F6]">
      <MobileHeader
        className="df:md:hidden"
      />
      <div className="grid w-screen grid-cols-1 df:md:grid-cols-2 df:md:min-h-screen">
        <WelcomeSlogan
          className="hidden df:md:block"
        />
        <div
          className="
            p-5 flex-col items-center justify-center w-full
            df:md:mx-auto df:md:max-w-200 df:md:px-10 df:lg:px-24 df:md:flex
          "
        >
          <div className="flex mt-8 mb-8 flex-col self-stretch gap-1">
            <h2 className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
              Welcome back
            </h2>
            <p className="text-gray-900 text-base not-italic font-normal leading-6">
              Enter your details below
            </p>
          </div>

          <form className="w-full" onSubmit={handleLogin}>
            <Input
              id="email_address"
              ref={emailRef}
              required={true}
              label="Email Address"
              placeholder="Enter your email"
              type="email"
              startIcon={<EmailIcon />}
              onIconClick={() => {}}
            />
            <Input
              id="password"
              ref={passwordRef}
              className="mt-4"
              required={true}
              label="Password"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowPassword(!showPassword)}
            />
            <div className="flex items-center justify-between mb-8 my-4">
              <label className='flex gap-x-2 cursor-pointer'>
                <Checkbox
                  ref={trustRef}
                  variant="DEFAULT"
                  className="mr-0"
                  defaultChecked={isTrusted}
                  checked={isTrusted}
                  onChange={() => setIsTrusted((prev) => !prev)}
                />
                <div className='text-gray-900 text-base'>Trust this device</div>
              </label>
              <div className="flex justify-end gap-1">
                <p
                  className="text-right text-sm not-italic font-bold leading-5 text-purple-900 cursor-pointer"
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  Forgot password?
                  <CircledArrowRightIcon className="inline-block ml-1 align-top" />
                </p>
              </div>
            </div>
            <div className="flex">
              <Button variant="primary" className="w-full" disabled={disabled}>
                Sign In
              </Button>
            </div>
          </form>

          <div className="flex justify-center py-3.5 gap-1 mt-6" onClick={() => {}}>
            <p className="text-sm not-italic font-normal leading-5 font-manrope">
              Don&rsquo;t have an account?
            </p>
            <Link
              to={ROUTE_PATH.WELCOME_PAGE}
              className="text-center text-sm not-italic font-bold leading-5 text-purple-900"
            >
              Create now
              <CircledArrowRightIcon className="ml-1 inline-block align-top" />
            </Link>
          </div>

          <Dialog
            className="max-w-screen-xsm rounded-lg w-full shadow-modal bg-white backdrop:bg-modalBackdropColor"
            onClose={() => setShowDialog(false)}
            open={showDialog}
            closeOnOutsideClick={true}
          >
            <div className="m-4 space-y-1 df:md:space-y-4">
              <div>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  Forgot your password?
                </p>
                <p className="text-gray-900 text-base not-italic font-normal leading-6">
                  Please provide your email so we can send you a secure link in order to reset your
                  password.
                </p>
              </div>
              <form className="space-y-4" onSubmit={handlePasswordReset}>
                <Input
                  ref={forgotPasswordEmailRef}
                  required={true}
                  label="Email Address"
                  placeholder="Enter your email"
                  type="email"
                  startIcon={<EmailIcon />}
                  onIconClick={() => {}}
                />

                {showResetError && (
                  <Toast
                    className="df:md:rounded-lg"
                    variant="error"
                    onExit={() => {
                      setShowResetError(false);
                    }}
                    title="Error"
                    description="Email not found, check your spelling."
                  />
                )}
                {showResetSuccess && (
                  <Toast
                    className="df:md:rounded-lg"
                    variant="success"
                    onExit={() => {
                      setShowResetSuccess(false);
                    }}
                    title="Success"
                    description="Check your email to reset your password."
                  />
                )}
                <div className="flex flex-col gap-2 df:md:mt-8 df:md:flex-row-reverse df:md:justify-around">
                  <Button
                    variant="primary"
                    className="w-full df:md:w-auto"
                    disabled={disabled}
                  >
                    Send
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-full df:md:w-auto"
                    onClick={() => setShowDialog(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
